import React from "react";
import { useUserContext } from "../../contexts/userContext";
import { getLogo } from "../../utlis/themeHelpers";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "../../contexts/TranslationContext";

const PaymentSuccess = ({setActiveScreen, setError, setMessage, message, history, onboardingBaseUrl, onboardingStepsCompleted}) => {
    const {isSalonApp} = useUserContext()
    const { translate } = useTranslation();

  return (
    <div className="my-auto flex flex-col" style={{ flexGrow: 1 }}>
      <nav className="flex items-center border-b-2 border-gray-700 py-10 px-auto">
          <img className="h-20 mx-auto" src={getLogo(isSalonApp)} alt="GRO Track" />
      </nav>
      {message && <p className="text-center text-red-600">{message}</p>}

      <div
        className="text-center flex flex-col justify-center"
        style={{ flexGrow: 1 }}
      >
        <h1 className="text-3xl text-text-2 tracking-wider">
          {translate('thanks_payment')}
        </h1>
        <h1 className="text-3xl text-text-2 mt-12 mb-16 tracking-wider">
            {isSalonApp ? "Let’s set up your Gro Salon account" : "Let's set up your GroTrack account"}
        </h1>
        <div className="flex justify-center">
          <button
            className="bg-button-5 tracking-widest font-bold text-white focus:outline-none px-16 py-2 rounded-md"
            onClick={() => {
                if(setActiveScreen && setError && setMessage){
                  onboardingStepsCompleted.current = 5
                  history.push(`${onboardingBaseUrl}?onboarding_step=password_setup`)
                    // setActiveScreen("password_setup");
                    setError("");
                    setMessage("");
                }
            }}
          >
            {translate('start')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
