import React, { useState } from "react";
import styles from "./Subscription.module.css";
import Logo from "../../assets/images/GROTrackTurquoise.png";
import { useUserContext } from "../../contexts/userContext";
import { getLogo } from "../../utlis/themeHelpers";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "../../contexts/TranslationContext";

const Subscription = ({ initCheckout }) => {
  const [subscriptionType, setSubscriptionType] = useState("annually");
  const { isSalonApp } = useUserContext();
  const isOnboardingFlowOne = useRouteMatch("/onboarding/:email_url?/:type?/:status?");
  const { translate } = useTranslation()

  const submitForm = (e) => {
    e.preventDefault();
    initCheckout(subscriptionType);
  };

  return (
    <div style={{flexGrow: 1}} className="flex flex-col">
      <div className={styles.topContainer}>
        <img src={getLogo(isSalonApp)} alt="logo" className="h-20 mx-auto" />
      </div>

      {!isSalonApp ? (
        <form
          onSubmit={submitForm}
          className={`${styles.form} text-text-2 text-base font-bold capitalize tracking-wider flex flex-col justify-around items-center`}
        >
          <h1 className="text-text-2 tracking-widest text-lg">
            {isOnboardingFlowOne ? <>{translate('monthly_service_fee_payment_authorization')} <br />{translate('after_initial_12_months')}</> : translate('monthly_service_fee_payment_authorization')}
          </h1>

          <div className={styles.optionContainer} >
            <div className={styles.option}>
              <input
                style={{ marginTop: "6px" }}
                type="radio"
                id="pay annually"
                name="subscription"
                checked={subscriptionType === "annually" ? true : false}
                onChange={(e) => {
                  setSubscriptionType("annually");
                }}
              />
              <div className={`${styles.optionContent} lowercase text-lg leading-6`}>
                {translate('pay_annually')} <br />
                {translate('save_25_percent')} <br />
                {translate('total_payment_yearly')}
              </div>
            </div>
            <div className={`${styles.option} mt-10`}>
              <input
                style={{ marginTop: "6px" }}
                type="radio"
                id="pay monthly"
                name="subscription"
                checked={subscriptionType === "monthly" ? true : false}
                onChange={(e) => {
                  setSubscriptionType("monthly");
                }}
              />
              <div className={`${styles.optionContent} lowercase text-lg leading-6`}>
                {translate('pay_monthly')} <br />
                $199 {translate('pay_per_month')}
                <div className="text-text-2 text-sm font-light lowercase">
                  {translate('minimum_6_months_service')}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`text-text-2 text-sm tracking-wider leading-6 font-bold`}
          >
           {translate('by_clicking_confirm_authorize')}
            <br />
            {translate('monthly_service_fee_card_debit')}
          </div>

          <button
            type="submit"
            className="bg-button-5 text-text-3 px-16 py-2 uppercase font-bold tracking-widest mt-8 rounded-md w-max"
          >
            {translate('confirm')}
          </button>
        </form>
      ) : (
        <form
          onSubmit={submitForm}
          className={`${styles.form} text-text-2 text-base font-bold capitalize tracking-wider flex flex-col justify-around items-center`}
        >
          <h1 className="text-text-2 tracking-widest text-lg">
            {translate('monthly_service_fee_payment_authorization')}
          </h1>

          <div className={styles.optionContainer}>
            <div className={styles.option}>
              <input
                style={{ marginTop: "6px" }}
                type="radio"
                id="pay annually"
                name="subscription"
                checked={subscriptionType === "annually" ? true : false}
                onChange={(e) => {
                  setSubscriptionType("annually");
                }}
              />
              <div className={`${styles.optionContent} lowercase text-lg leading-6`}>
                {translate('pay_annually')} <br />
                $79 {translate('pay_per_month')} ({translate('save_money')} 20%) <br />
                {translate('total_payment')}: $948 {translate('per_year')}
              </div>
            </div>
            <div className={styles.option}>
              <input
                style={{ marginTop: "6px" }}
                type="radio"
                id="pay monthly"
                name="subscription"
                checked={subscriptionType === "monthly" ? true : false}
                onChange={(e) => {
                  setSubscriptionType("monthly");
                }}
              />
              <div className={`${styles.optionContent} lowercase text-lg leading-6`}>
                {translate('pay_monthly')} <br />
                $99 {translate('pay_per_month')}
              </div>
            </div>
          </div>

          <div
             className={`text-text-2 text-sm tracking-wider leading-6 font-bold`}
          >
            {translate('by_clicking_confirm_authorize')}{" "}
            <br />
            {translate('monthly_service_fee_card_debit')}
          </div>

          <button
            type="submit"
            className="bg-button-5 text-text-3 px-16 py-2 uppercase font-bold tracking-widest mt-8 rounded-md w-max"
          >
            {translate('confirm')}
          </button>
        </form>
      )}
    </div>
  );
};

export default Subscription;
