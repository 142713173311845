import React, { useEffect, useRef, useState } from 'react'
import styles from './Agreement.module.css'
import Logo from '../../assets/images/GROTrackTurquoise.png'
import SignatureCanvas from 'react-signature-canvas'
import Loading from '../../views/Loading'
import { useHistory } from 'react-router-dom'
import AdminHeader from '../../components/AdminHeader'
import { useUserContext } from '../../contexts/userContext'
import { useTranslation } from '../../contexts/TranslationContext'

function HipaaAgreement() {
    const history = useHistory();
    const {user, handletSetUserOnly} = useUserContext();
    const { translate } = useTranslation();


    const [successMessage, setSuccessMessage] = useState("")
    const [ready, setReady] = useState(true);
    const [message, setMessage] = useState("");
    const signature = useRef(null);
    const [agreeTandC, setAgreeTandC] = useState(false);
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [printName, setPrintName] = useState("");
    const [signError, setSignError] = useState("");
    const [username, setUsername] = useState(null);

    const submitForm = (e) => {
        e.preventDefault()
        if(agreeTandC && !signature.current.isEmpty()){
            setReady(false);
            fetch(`${process.env.REACT_APP_API}/clinic/save_hipaa_agreement`, {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    sign: signature.current.getTrimmedCanvas().toDataURL('image/png').substring(22),
                    print_name: printName,
                    title: title,
                    date: date
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response);
                if (response.success) {
                    setSuccessMessage("Hipaa agreement Updated Successfully");
                    setTimeout(() => {
                        handletSetUserOnly({...user, hipaa_agreement_pdf: response.user_agreement})
                        history.push("/onboarding/md_details");
                    }, 1000);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
                })
                .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
                setReady(true);
            });
        }else{
            if(signature.current.isEmpty()){
                setSignError('Please sign the document');
            }else{
                setSignError('');
            }
        }
    }
  return (
    <>
        {ready && (
            <div className='px-10 p-5'>
          <AdminHeader hideChatGPT hideHomeLink/>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {successMessage && <p className="mt-8 text-center text-green-600">{successMessage}</p>}
                <form onSubmit={submitForm} className={styles.form}>
                    <h1 className='text-text-2 tracking-wider text-lg font-semibold uppercase'>HIPAA BUSINESS ASSOCIATE CONTRACT</h1>
                    <div className={`${styles.agreement} text-justify bg-white text-black`}>
                        <div className='px-12 py-6'>
                            <h1 className='text-center mb-10 font-bold text-xl underline'>HIPAA Business Associate Agreement</h1>
                            <p className={` my-4 ${styles.indent}`}>
                                This HIPAA Business Associate Agreement (the “Agreement”) is between you,
                                GRO platform user (covered entity) and Smart Practice Systems, Inc/GRO technologies (Business
                                associate). This agreement takes effect on the date when you sign and click on “Accept GRO tech
                                Business Associate Agreement” button. You represent to GRO tech that you are lawfully able to
                                enter into agreement. If you are entering into this Agreement for an entity, such as the Clinic,
                                Company you work for, you represent to GRO tech that you have the legal authority to bind that
                                entity. 
                            </p>
                            
                            <p className={` my-4 ${styles.indent}`}>
                                WHEREAS, Business Associate may maintain, transmit create or receive data for or from
                                Covered Entity that constitutes Protected Health Information (as defined at 45 CFR §160.103) to
                                perform tasks on behalf of Covered Entity;
                            </p>

                            <p className={` my-4 ${styles.indent}`}>
                                WHEREAS, Covered Entity is or may be subject to the requirements of 42 U.S.C. 1320d et
                                seq. enacted by the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), the
                                Health Information Technology for Economic and Clinical Health Act (“HITECH”) and the
                                implementing regulations set forth at 45 CFR Parts 160, 162 and 164 (“HIPAA Regulations”). As
                                used herein, “PHI” refers to Protected Health Information maintained, transmitted, created or
                                received by Business Associate for or from Covered Entity.
                            </p>

                            <p className={` my-4 ${styles.indent}`}>
                                WHEREAS, to the extent required by the HIPAA Regulations and applicable state law,
                                Business Associate is or may be directly subject to certain privacy and security obligations and
                                penalty provisions of HIPAA, HITECH, the HIPAA Regulations and state law.
                            </p>

                            <p className={` my-4 ${styles.indent}`}>
                                NOW, THEREFORE, the parties agree as follows:
                            </p>

                            <ol class="list-decimal ml-12" type='1'>
                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        Business Associate may use and disclose PHI only as expressly permitted or
                                        required by this Agreement or as required by law. Business Associate may use or disclose PHI as
                                        required to perform its obligations under any underlying service agreements (collectively,
                                        “Service Agreement”) between the parties to perform certain services as described in the Service
                                        Agreement (“Services), provided that Business Associate shall not use or disclose PHI in any
                                        manner that would constitute a violation of the HIPAA Regulations if done by Covered Entity.
                                        Without limiting the generality of the foregoing, Business Associate shall not sell PHI or use or
                                        disclose PHI for purposes of marketing or fundraising, as defined and proscribed in the
                                        HIPAA Regulations, HITECH and applicable state law. Business Associate shall limit its uses
                                        and disclosures of, and requests for, PHI (i) when practical, to the information making up a limited
                                        data set (as set forth at 45 CFR § 164.514); and (ii) in all other cases subject to the requirements
                                        of 45 CFR §164.502(b), to the minimum amount of PHI necessary to accomplish the intended
                                        purpose of the use, disclosure or request. To the extent Covered Entity notifies Business
                                        Associate of a restriction request granted by Covered Entity that would limit Business Associate’s
                                        use or disclosure of PHI, Business Associate will comply with the restriction. To the extent
                                        Business Associate is to carry out an obligation of Covered Entity under the HIPAA Regulations,
                                        Business Associate shall comply with the requirements of the HIPAA Regulations that apply to
                                        Covered Entity in the performance of such obligation.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        Business Associate agrees to use and maintain reasonable and appropriate
                                        administrative, technical and physical safeguards to protect PHI from uses or disclosures not
                                        permitted by this Agreement, including, but not limited to, maintaining policies and procedures to
                                        detect, prevent or mitigate identity theft based on PHI or information derived from PHI. In addition,
                                        Business Associate agrees to comply with the applicable requirements of 45 CFR Part 164, subpart
                                        C of the HIPAA Regulations with respect to electronic PHI and any guidance issued by the
                                        Secretary of the Department of Health and Human Services (“HHS”). Business Associate
                                        specifically agrees to employ multiple security mechanisms to ensure the confidentiality, integrity
                                        and availability of all electronic PHI, including, but not limited to, authentication controls,
                                        authorization controls, audit controls and encryption.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        To the extent Business Associate becomes aware of or discovers any use or
                                        disclosure of PHI in violation of this Agreement, any Security Incident (as defined at 45 CFR
                                        §164.304) any Red Flag (as defined at 16 CFR §681.2(b)) related to any individual who is the
                                        subject of PHI, and any Breach of Unsecured Protected Health Information (both as defined at 45
                                        CFR §164.402), Business Associate shall promptly report such use, disclosure, incident, Red Flag
                                        or breach to Covered Entity. All reports of Breaches shall be made within ten (10) business days of
                                        Business Associate discovering the Breach and shall include the information specified at 45 CFR §
                                        164.410. Business Associate shall mitigate, to the extent practicable, any harmful effect known to it
                                        of a use or disclosure of PHI by Business Associate not permitted by this Agreement. Business
                                        Associate shall promptly reimburse Covered Entity all reasonable costsincurred by Covered Entity
                                        with respect to providing notification of and mitigating a Breach involving Business Associate,
                                        including but not limited to printing, postage costs and toll-free hotline costs.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        In accordance with 45 CFR §§ 164.308(b)(2) and 164.502(e)(1)(i), Business
                                        Associate shall ensure that each subcontractor or agent that creates, receives, maintains, or transmits
                                        PHI on behalf of Business Associate agrees in writing to be bound by the same restrictions, terms
                                        and conditions that apply to Business Associate pursuant to this Agreement.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        In accordance with 45 CFR §164.524 and within fifteen (15) days of a request by
                                        Covered Entity for access to PHI about an individual contained in a Designated Record Set (as
                                        defined at 45 CFR §164.501), Business Associate shall make available to Covered Entity such PHI
                                        in the form requested by Covered Entity. If the requested PHI is maintained electronically, Business
                                        Associate shall provide a copy of the PHI in the electronic form and format requested by the
                                        individual, if it isreadily producible, or, if not, in a readable electronic form and format as agreed to
                                        by Covered Entity and the individual. In the event that any individual requests access to PHI
                                        directly from Business Associate, Business Associate shall within ten (10) days forward such
                                        request to Covered Entity. Any denials of access to the PHI requested shall be the responsibility of
                                        Covered Entity.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        In accordance with 45 CFR §164.526 and within fifteen (15) days of receipt of a
                                        request from Covered Entity for the amendment of an individual’s PHI contained in a Designated
                                        Record Set (for so long as the PHI is maintained in the Designated Record Set), Business Associate
                                        shall provide such information to Covered Entity for amendment and incorporate any such
                                        amendments in the PHI as required by 45 CFR §164.526. In the event a request for an amendment
                                        is delivered directly to Business Associate, Business Associate shall within ten (10) days of
                                        receiving such request forward the request to Covered Entity.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        Except for disclosures of PHI by Business Associate that are excluded from the
                                        accounting obligation as set forth at 45 CFR §164.528 or regulations issued pursuant to HITECH,
                                        Business Associate shall record for each disclosure the information required to be recorded by
                                        covered entities pursuant to 45 CFR §164.528. Within twenty (20) days of notice by Covered Entity
                                        to Business Associate that it has received a request for an accounting of disclosures of PHI,
                                        Business Associate shall make available to Covered Entity, or if requested by Covered Entity, to the
                                        individual, the information required to be maintained pursuant to this Section 7. In the event the
                                        request for an accounting is delivered directly to Business Associate, Business Associate shall
                                        within ten (10) days forward such request to Covered Entity.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        At Covered Entity’s or HHS’ request, Business Associate shall make its internal
                                        practices, books and records relating to the use and disclosure of PHI available to HHS for purposes
                                        of determining compliance with the HIPAA Regulations.
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        Business Associate is not authorized to use or disclose PHI in a manner that would
                                        violate the HIPAA Regulations if done by Covered Entity, provided that Business Associate may:
                                    </p>
                                    <ol class={`ml-12 ${styles.orderListAlpha} relative`} >
                                        <li className='my-4 '>
                                            <p className={`${styles.indent}`}>
                                                use the PHI for its proper management and administration and to carry out its legal responsibilities.
                                            </p>
                                        </li>
                                        <li className='my-4 '>
                                            <p className={`${styles.indent}`}>
                                                disclose PHI for its proper management and administration and to carry out
                                                its legal responsibilities, provided that disclosures are required by law, or Business
                                                Associate obtains reasonable assurances from the recipient that the PHI will remain
                                                confidential and used or further disclosed only as required by law or for the purpose for
                                                which it was disclosed to the recipient, and the recipient notifies Business Associate of any
                                                instances of which it is aware in which the confidentiality of the information has been
                                                breached.
                                            </p>
                                        </li>
                                        <li className='my-4 '>
                                            <p className={`${styles.indent}`}>
                                                use and disclose PHI to report violations of law to appropriate Federal and
                                                State authorities, consistent with 45 CFR § 164.502(j)(1).  
                                            </p>
                                        </li>
                                        <li className='my-4 '>
                                            <p className={`${styles.indent}`}>
                                                aggregate the PHI in its possession with the Protected Health Information of
                                                other covered entities that Business Associate has in its possession through its capacity as a
                                                business associate to other covered entities, provided that the purpose of such aggregation is
                                                to provide Covered Entity with data analysis relating to the health care operations of
                                                Covered Entity.
                                            </p>
                                        </li>
                                        <li className='my-4 '>
                                            <p className={`${styles.indent}`}>
                                                use PHI to create de-identified information, provided that the deidentification conforms to the requirements of 45 CFR § 164.514(b).
                                            </p>
                                        </li>
                                    </ol>
                                </li>

                                <li className=''>
                                    <p className={`${styles.indent} my-4 `}>
                                        If Business Associate conducts standard transactions (as defined in 45 CFR
                                        Part 160) for or on behalf of Covered Entity, Business Associate will comply and will require by
                                        written contract each agent or contractor (including any subcontractor) involved with the conduct of
                                        such standard transactions to comply, with each applicable requirement of the HIPAA Regulations
                                        (as set forth at 45 CFR Parts 160 and 162). Business Associate will not enter into, or permit its
                                        agents or contractors (including subcontractors) to enter into, any trading partner agreement in
                                        connection with the conduct of standard transactions for or on behalf of Covered Entity that: (i)
                                        changesthe definition, data condition, or use of a data element orsegment in a standard transaction;
                                        (ii) adds any data elements or segments to the maximum defined data set; (iii) uses any code or data
                                        element that is marked “not used” in the standard transaction’s implementation specification or is
                                        not in the standard transaction’s implementation specification; or (iv) changes the meaning or intent
                                        of the standard transaction’s implementation specification. Business Associate agrees to participate
                                        in any test modification conducted by Covered Entity in accordance with the HIPAA Regulations.
                                    </p>
                                </li>

                                <li className=''>
                                    <p className={`${styles.indent} my-4 `}>
                                        <span className='font-bold'>[<span className="italic">use the following if a written service agreement exists:</span></span> This Agreement shall be
                                        effective as the Effective Date and shall remain in effect until the Service Agreement is terminated
                                        or expires. Either party may terminate this Agreement and the Service Agreement effective
                                        immediately if it determines that the other party has breached a material provision of this
                                        Agreement and failed to cure such breach within thirty (30) days of being notified by the other party
                                        of the breach. If the non-breaching party determines that cure is not possible, such party may
                                        terminate this Agreement and the Service Agreement effective immediately upon written notice to
                                        other party. If termination is not feasible, the non-breaching party shall report the breach to HHS.
                                        The parties understand and agree that termination of this Agreement shall constitute a default by
                                        Business Associate under the Service Agreement.<span className='font-bold'>]</span>
                                    </p>
                                    <p className={`${styles.indent} my-4 `}>
                                        <span className='font-bold'>[<span className="italic">use the following instead if there is NOT a written service agreement in place:</span></span> This
                                        Agreement shall be effective as of the Effective Date and shall remain in effect until Business
                                        Associate ceases to provide the Services to Covered Entity. Either party may terminate this
                                        Agreement effective immediately if it determines that the other party has breached a material
                                        provision of this Agreement and failed to cure such breach within thirty (30) days of being notified
                                        by the other party of the breach. If the non-breaching party determines that cure is not possible, such
                                        party may terminate this Agreement effective immediately upon written notice to other party. If
                                        termination is not feasible, the non-breaching party shall report the breach to HHS. The parties
                                        understand and agree that termination of this Agreement shall automatically terminate the
                                        relationship whereby Business Associate performs the Services on behalf of the Covered Entity.<span className='font-bold'>]</span>
                                    </p>
                                </li>

                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        Upon termination of this Agreement, Business Associate shall either return or
                                        destroy, at no cost to Covered Entity, all PHI that Business Associate still maintains in any form.
                                        Business Associate shall not retain any copies of such PHI. Notwithstanding the foregoing, to the
                                        extent that it is not feasible to return or destroy such PHI, the terms and provisions of this
                                        Agreement shall survive termination of this Agreement, and Business Associate shall only use or
                                        disclose such PHI solely for such purpose or purposes which prevented the return or destruction of
                                        such PHI.
                                    </p>
                                </li>
                                
                                <li className='my-4 '>
                                    <p className={`${styles.indent}`}>
                                        Nothing in this Agreement shall be construed to create any rights or remedies in any
                                        third parties or any agency relationship between the parties. To the extent Business Associate is
                                        acting as a business associate under the HIPAA Regulations, Business Associate shall be subject to
                                        the penalty provisions specified in HITECH. Upon the effective date of any final regulation or
                                        amendment to final regulations promulgated by HHS with respect to PHI, this Agreement will be
                                        deemed to be automatically amended such that the obligations imposed on the parties remain in
                                        compliance with such regulations. The terms and conditions of this Agreement shall override and
                                        control any conflicting term or condition of any agreement between the parties with respect to the
                                        Services  <span className='font-bold'>[<span className="italic">include the following if a written service agreement exists:</span></span> including the Service
                                        Agreement<span className='font-bold'>]</span>, and all non-conflicting terms and conditions shall remain in full force and effect.
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='flex gap-16 my-4'>
                        <div className='w-7/12 h-full'>
                            <div className="bg-background-3">
                                <SignatureCanvas  ref={signature} penColor='green' canvasProps={{ style: { width: "100%", height: 90 }, className: 'sigCanvas' }} />
                            </div>
                            {signError && <p className='text-red-500 font-bold text-left'>{signError}</p>}
                        </div>
                        <div className='w-5/12 h-full '>
                            <div>
                                <input type="text" required value={printName} onChange={(e) => { setPrintName(e.target.value)}} className='pl-3 bg-background-22 placeholder-white text-white py-2 px-3 w-full' style={{paddingTop: '6px', paddingBottom: '6px'}} placeholder='PRINT NAME' />
                            </div>
                            <div className='flex mt-4 gap-8'>
                                <div className='w-5/12'>
                                    <input type="date" required value={date} onChange={(e) => { setDate(e.target.value)}} className='pl-3 bg-background-22 placeholder-white text-white py-2 px-3 w-full' style={{paddingTop: '6px', paddingBottom: '6px'}} />
                                </div>
                                <div className='w-7/12'>
                                    <input type="text" required value={title} onChange={(e) => { setTitle(e.target.value)}} className='pl-3 bg-background-22 placeholder-white text-white py-2 px-3 w-full' style={{paddingTop: '6px', paddingBottom: '6px'}} placeholder='TITLE' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex my-8'>
                        <input className={`form-checkbox h-5 w-5 border-accent-1 bg-backgound-22 mr-8`} type="checkbox" required value={agreeTandC} onChange={(e) => (setAgreeTandC(prev => !prev))} />
                        <h1 className='text-sm text-start font-bold text-text-3 uppercase tracking-wider'>{translate('confirm_acceptance')}</h1>
                    </div>
                    <button type="submit" className="bg-button-5 font-xl tracking-widest text-white font-semibold px-12 py-2 rounded-md">{translate('confirm')}</button>
                </form>
            </div>
            )}
            <Loading message={"Loading"} ready={ready} />
        </>
  )
}

export default HipaaAgreement