import { React, useState, useEffect, useContext, createContext, useMemo } from "react";
import { NavLink } from "react-router-dom";

import Loading from "../views/Loading";
import SettingsIcon from "../assets/images/GearIcon.png";
import { useUserContext } from "../contexts/userContext";
import AdminHeader from "../components/AdminHeader";
import { Route, Switch, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { AdminMyGroSolutions } from "../components/AdminMarketPlaceComponents/AdminMyGroSolutions";
import { AdminMarketPlace } from "../components/AdminMarketPlaceComponents/AdminMarketPlace";
import { useTranslation } from "../contexts/TranslationContext";

const CartContext = createContext({})

export const useCartContext = () => {
    return useContext(CartContext)
}

export const AdminMarketPlaceWrapper = () => {
    const { isSalonApp } = useUserContext();
    const { translate } = useTranslation();
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [templates, setTemplates] = useState({});
    const [cartItems, setCartItems] = useState([]);



    const handleAddItemToCart = item => {
        setCartItems(prev => [...prev, item])
    }

    const handleRemoveItemFromCart = id => {
        setCartItems(prev => {
            return prev.filter(item => item?.id !== id);
        })
    }

    const value = useMemo(() => {
        return {
            cartItems,
            handleAddItemToCart,
            handleRemoveItemFromCart,
            templates,
        }
    }, [cartItems, templates])


    const getAllProducts = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/templates`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response, "response")
                if (response.success) {
                    setTemplates(response);
                } else {
                    setMessage(response.message);
                }
                setReady(true);
            })
            .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
                setReady(true);
            });
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    return (
        <>
            <main
                className={`h-full mx-auto container flex flex-col p-5 pb-32 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
            >
                <AdminHeader physician={{}} />

                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}
                <section className="flex relative mx-10 tracking-widest">
                    <NavLink to="/admin/profile">
                        <img
                            src={SettingsIcon}
                            className="absolute h-8 w-8 mt-6 transition cursor-pointer"
                            alt="settings-icon"
                        />
                    </NavLink>
                    <div className="w-full px-16 mt-12">
                        <NavigationHeader />
                        <CartContext.Provider value={value}>
                            <Switch>
                                <Route
                                    path="/admin/market"
                                    exact
                                >
                                    <AdminMarketPlace />
                                </Route>
                                <Route
                                    path="/admin/market/my_gro_solutions"
                                    exact
                                >
                                    <AdminMyGroSolutions />
                                </Route>
                            </Switch>
                        </CartContext.Provider>
                    </div>
                </section>
            </main>
            <Loading message={"Loading Payment Details"} ready={ready} />
        </>
    );
};

const NavigationHeader = () => {
    const isMarketPage = useRouteMatch("/admin/market");
    const isSolutionsPage = useRouteMatch("/admin/market/my_gro_solutions")

    const { translate } = useTranslation();
    return isSolutionsPage ? (
        <div className="flex relative mb-12 mx-36">
            <NavLink to="/admin/market/my_gro_solutions" className="mx-auto text-3xl text-text-2 font-bold uppercase">
                <h2 className="">
                    {translate('my_gro_solutions')}
                </h2>
            </NavLink>

            <NavLink to="/admin/market">
                <div className="absolute right-0 cursor-pointer text-lg font-medium text-text-2 border-2 py-1 px-4 border-accent-7 rounded-md">
                    {translate('gro_marketplace')}
                </div>
            </NavLink>
        </div>

    ) : (
        <div className="flex relative mb-12 mx-36">
            <NavLink to="/admin/market" className="mx-auto text-3xl text-text-2 font-bold uppercase">
                <h2 className="">
                    {translate('gro_marketplace')}
                </h2>
            </NavLink>

            <NavLink to="/admin/market/my_gro_solutions">
                <div className="absolute right-0 cursor-pointer text-lg font-medium text-text-2 border-2 py-1 px-4 border-accent-7 rounded-md">
                    {translate('my_gro_solutions')}
                </div>
            </NavLink>
        </div>
    )
}
