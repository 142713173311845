import React, { useState } from 'react'
import { getLogo } from '../../utlis/themeHelpers';
import { useUserContext } from '../../contexts/userContext';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from '../../contexts/TranslationContext';

const Register = ({message = "", signUp = () => {}, clinicName, setClinicName, email, setEmail, error = {}, registrationCode, setRegistrationCode}) => {
    const { translate} = useTranslation();
    const {isSalonApp} = useUserContext();

  return (
    <div className="my-auto flex flex-col">
            <NavLink to="/" className="mx-auto mb-20">
              <img className="h-24 xl:h-32" src={getLogo(isSalonApp)} alt="GRO Track" />
            </NavLink>
            {message && <p className="text-center text-red-600">{message}</p>}
            {/* <h1 className='mx-auto text-2xl text-text-2 mt-20'>Get Started</h1> */}
            <form
              className="mt-12 flex flex-col items-center"
              onSubmit={event => {
                event.preventDefault();
                signUp();
              }}
            >
              <input
                type="text"
                className="px-5 py-2 md:w-72 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
                placeholder={isSalonApp ? "SALON NAME" : `PRACTICE NAME`}
                value={clinicName}
                required
                onChange={event => {
                  setClinicName(event.target.value);
                }}
              />
              {error.name && <p className="text-center text-red-600">{error.name}</p>}
              <input
                type="email"
                className="mt-8 px-5 py-2 md:w-72 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
                placeholder={translate('email')}
                value={email}
                required
                onChange={event => {
                  setEmail(event.target.value);
                }}
              />
              {error.email && <p className="text-center text-red-600">{error.email}</p>}
              <input
                type="text"
                className="mt-8 px-5 py-2 md:w-72 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
                placeholder="REGISTRATION CODE"
                value={registrationCode}
                required
                onChange={event => {
                  setRegistrationCode(event.target.value);
                }}
              />
              {error.registration_code && (
                <p className="text-center text-red-600">{error.registration_code}</p>
              )}

              <input
                type="submit"
                className="mt-20 h-24 w-24 rounded-full font-bold text-xl bg-button-5 text-white cursor-pointer focus:outline-none"
                value="NEXT"
              />
            </form>

          </div>
  )
}

export default Register