import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "../contexts/TranslationContext";

function ShowCases({ item }) {
  const ref = useRef();
  const { translate } = useTranslation()


  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const prevSlide = () => {
    ref.current.slickPrev();
  };

  const nextSlide = () => {
    ref.current.slickNext();
  };

  return (
    <div
      className="my-8 w-full col-span-2 gap-2"
      style={{
        backgroundColor: "rgba(246, 247, 250, 0.9)",
        boxSizing: "border-box",
        padding: "2rem 1.5rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {item && item.details.length !== 0 ? (
        <>
          <IoIosArrowBack
            onClick={prevSlide}
            style={{
              fontSize: "40px",
              cursor: "pointer",
              width: "fit-content",
            }}
          />
          <Slider ref={ref} {...settings} style={{ width: "75%" }}>
            {item.details.map((item) => {
              return (
                <div>
                  <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.2rem",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <img
                            src={item.before_image.image_path}
                            alt="cases"
                            style={{
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              padding: "0.5rem 1rem",
                              color: "white",
                              background: "#29A0B1",
                              textAlign: "left",
                            }}
                          >
                            <div>{translate('before')}</div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "50%",
                          }}
                        >
                          <img
                            src={item.after_image.image_path}
                            alt="cases"
                            style={{
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              padding: "0.5rem 1rem",
                              color: "white",
                              background: "#29A0B1",
                              textAlign: "right",
                            }}
                          >
                            <div>{translate('after')}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4rem",
                          marginTop: "2rem",
                        }}
                      >
                        {/* {item.global_hair_mass && <div>Global hair mass: {item.global_hair_mass}%</div>}
                        {item.crown_hair_mass && <div>Crown hair mass: {item.crown_hair_mass}%</div>}
                        {item.vertex_hair_mass && <div>Vertex hair mass: {item.vertex_hair_mass}%</div>}
                        {item.hair_density && <div>Hair density: {item.hair_density}% </div>}
                        {item.hair_thickness && <div>Hair thickness: {item.hair_thickness}%. </div>}
                        <div>{item.duration}</div> */}
                        {Object.keys(item).filter((key) => {
                          if(['before_image', 'after_image'].includes(key)){
                            return false;
                          }
                          return true;
                        }).map((key) => {
                          if (key.startsWith('_')) return null; 

                          const label = key.charAt(0).toUpperCase() + key.slice(1).replaceAll('_', ' ');
                          const value = item[key];
                          if(key === 'duration'){
                            return <div key={key}>{value}</div>;
                          }
                          return <div key={key}>{label}: {value}%</div>;
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          <IoIosArrowForward
            onClick={nextSlide}
            style={{
              fontSize: "40px",
              cursor: "pointer",
              width: "fit-content",
            }}
          />
        </>
      ) : (
        <div style={{ textAlign: "center", width: "100%" }}>
          {translate('no_cases_to_show')}
        </div>
      )}
    </div>
  );
}

export default ShowCases;
