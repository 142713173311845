import { React, useState, useEffect } from "react";
import {MdEdit} from 'react-icons/md'
import { useParams } from "react-router-dom";
import { useTranslation } from "../contexts/TranslationContext";
//import Loading from '../views/Loading';

export default function ViewProduct(props) {
  const product = props.product;
  const procedure=props.product;
  const [disabled, setDisabled] = useState(true);
  const [editHover, setEditHover] = useState(false);
  // const [ready, setReady] = useState(false);
  // const [treatmentPlan, setTreatmentPlan] = useState(null);
  const {translate } = useTranslation()
  const treatment_plan_id = product.id
  const {id} = useParams()

  //const [message,setMessage] = useState(null)

  const {openInfo, setOpenInfo, openProductNotes, consent, setConsent,  setstate,setOpenProductNotes,getTreatmentPlans} = props

  useEffect(() => {
    if (!props.product.id) {
      setDisabled(false);
    }
  }, [props]);
  
  const updateStatus2 = (value) => {
    setstate()
    let body = {
      status: value
    }
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan/${treatment_plan_id}/change-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          console.log(response.treatmentPlan.status)
          setstate()
          getTreatmentPlans();
        } else {
          //setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        //setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        // setReady(true);
      });
  }
  return (
    <>
      
    <div className="mt-4 flex items-center">
      <div className="grid grid-cols-12 text-center text-text-3 font-font-2 font-normal">
        <div className="my-auto flex justify-end">
          <div className="flex flex-col leading-none text-text-1">
            <button
              className="mt-1 -m-1 focus:outline-none"
              onClick={() => {
                props.changePriority("products", props.index, -1);
              }}
            >
              ⬆
            </button>
            <button
              className="focus:outline-none"
              onClick={() => {
                props.changePriority("products", props.index, 1);
              }}
            >
              ⬇
            </button>
          </div>
          <div className="mx-2 flex w-8 bg-background-10 text-white font-bold bg-background-15">
            <p className="m-auto">{props.index + 1}</p>
          </div>
        </div>

        <select
          name="products"
          id="products"
          style={{
            border: 0,
            WebkitAppearance: "none",
            appearance: "none",
          }}
          value={product.Tid}
          disabled={disabled}
          className="px-2 py-1 bg-background-6 font-bold"
          onChange={(event) => {
            var proc = [];
            for(var i = 0; i < props.templateProducts.length; i++){
              if(props.templateProducts[i].id === parseInt(event.target.value)){
                proc = props.templateProducts[i];
                break;
              }
            }
            props.setProduct({
              ...product,
              Tid: proc.id,
              fee: proc.fee,
              brand: proc.brand,
              volume: proc.volume,
            });
          }}
        >
          <option value=""></option>
          {props.templateProducts &&
            props.templateProducts.map((proc, i) => {
              return (
                <option
                  key={i}
                  value={proc.id}
                >{`${proc.name}`}</option>
              );
            })}
        </select>


        <input
          type="text"
          placeholder='Brand'
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.brand}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, brand: event.target.value });
          }}
        />


      <button onClick={()=>{
         if(openProductNotes.open){
          setOpenProductNotes({open:false, id:null})}
          if(consent.open) {
            setConsent({open:false, id:null})
          }
        if(openInfo.open) {
          if(product.id!==openInfo.id)
          setOpenInfo({open:true, id:product.id})
          else
          setOpenInfo({open:false, id:null})
        }else {
          setOpenInfo({open:true, id:product.id})
        }
      }}
        style={openInfo.id===product.id ? {color:'#13C4B3'} : {color:'rgba(253, 248, 248, 0.8)'}}
         className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none">
          Info
        </button>


        <button onClick={()=>{
          if(openInfo.open){
            setOpenInfo({open:false, id:null})
          }
          if(consent.open) {
            setConsent({open:false, id:null})
          }
          if(openProductNotes.open){
            if(product.id!==openProductNotes.id)
            setOpenProductNotes({open:true, id:product.id})
          else
            setOpenProductNotes({open:false, id:null})
          }else {
            setOpenProductNotes({open:true, id:product.id})
          }
        }}
        style={openProductNotes.id===product.id ? {color:'#13C4B3'} : {color:'rgb(190, 196, 202)'}}
        className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
        >
          {translate('notes')}
        </button>


        <input
          type="text"
          placeholder="Fee"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={product.refill}
          disabled={disabled}
          onChange={(event) => {
            props.setProduct({ ...product, refill: event.target.value });
          }}
        />


        
        {(!props?.procedure?.has_completed && (
          <>
            {disabled ? (
              <div
                className="bg-background-7 flex cursor-pointer"
                onMouseOver={() => {
                  setEditHover(true);
                }}
                onMouseOut={() => {
                  setEditHover(false);
                }}
              >
                <svg
                  className="m-auto h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill={editHover ? "#13c3b2" : "#bec4ca"}
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                  />
                </svg>
                <div
                  className={`absolute mt-8 flex flex-col text-sm bg-background-10 text-black ${
                    !editHover && "hidden"
                  }`}
                >
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      setDisabled(false);
                    }}
                  >
                    {translate('edit')}
                  </button>
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      props.duplicateTreatment(
                        props.procedure.id,
                        "procedures"
                      );
                    }}
                  >
                    {translate('duplicate')}
                  </button>
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      props.deleteTreatment(props.procedure.id, "procedures", props.index);
                    }}
                  >
                    {translate('delete')}
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-background-7 flex">
                <button
                  className="m-auto focus:outline-none"
                  onClick={() => {
                    // props.saveTreatment(procedure);
                    setDisabled(true);
                  }}
                >
                  {/* <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#13c3b2"
                      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
                    />
                  </svg> */}
                  <MdEdit size={20} color="rgb(190, 196, 202)"/>
                </button>
              </div>
            )}
          </>
        )) || <div className="bg-background-7 flex"></div>}

        
        <div className="bg-background-7 flex">
          <button
            className="mx-auto focus:outline-none"
            // onClick={() => {
            //   if (!product.id)
            //     return alert("Please save treatment first");
            //   props.setModalConsent({tid: product.id, ...product.consent});
            //   props.setShowConsent(true)
            // }}
            onClick={()=>{
              if(openInfo.open){
                setOpenInfo({open:false, id:null})
              }
              if(openProductNotes.open){
                setOpenProductNotes({open:false, id:null})}
              if(consent.open){
                if(consent.id!==product.id)
                setConsent({open:true, id:product.id})
                else
                setConsent({open:false, id:null})
              }else{
                setConsent({open:true, id:product.id})
              }
            }}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={procedure.consent ? "#13c3b2" : "#bec4ca"}
                d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"
              />
            </svg>
          </button>
        </div>

        <div className="bg-background-7 flex">
          <button className="mx-auto focus:outline-none">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={props.product.paymentCompleted ? "#13c3b2" : "#bec4ca"}
                d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
              />
            </svg>
          </button>
        </div>


        <div className="bg-background-7 flex">
          <button className="mx-auto focus:outline-none">
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill={props.product.schedules ? "#13c3b2" : "#bec4ca"}
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
              />
            </svg>
          </button>
        </div>
            
        <div className="bg-background-7 flex">
          <select
            style={{
              border: 0,
              WebkitAppearance: "none",
              appearance: "none",
              outline:'none',
            }}
            className="px-2 py-1 bg-background-7 font-bold"
            value={product && product.status}
            // onChange={(event) => {
            //   var fee = 0;
            //   for(var i = 0; i < props.templateProcedures.length; i++){
            //     if(props.templateProcedures[i].id === parseInt(event.target.value)){
            //       fee = props.templateProcedures[i].fee;
            //       break;
            //     }
            //   }
            //   props.setProcedure({
            //     ...procedure,
            //     Tid: event.target.value,
            //     fee: fee,
            //   });
            // }}
            onChange={(e)=>updateStatus2(e.target.value)}
          >
            <option disabled value="">{translate("status")}</option>
            <option value="Pending">{translate('pending')}</option>
            <option value="Scheduled">{translate('scheduled')}</option>
            <option value="Completed">{translate('completed')}</option>
          </select>
        </div>
      </div>
    </div>
    
   </>
  );
  
}
