import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import MaleHeadTop from "../../assets/images/MaleHeadTop.png";
import MaleHeadLeft from "../../assets/images/MaleHeadLeft.png";
import MaleHeadRight from "../../assets/images/MaleHeadRight.png";
import FemaleHeadTop from "../../assets/images/FemaleHeadTop.png";
import FemaleHeadLeft from "../../assets/images/FemaleHeadLeft.png";
import FemaleHeadRight from "../../assets/images/FemaleHeadRight.png";
import FemaleHeadOccipital from "../../assets/images/FemaleHeadOccipital.png";
import MaleHeadOccipital from "../../assets/images/MaleHeadOccipital.png";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import DownArrow from "../../assets/images/downArrow.svg";

import DotGrey from "../../assets/images/DotGrey.svg";
import DotTurquoise from "../../assets/images/DotTurquoise.svg";
import { Link, useParams, useHistory } from 'react-router-dom';
import { GenderContext } from '../../views/OnePageConsult';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ClientContext } from '../../views/ClientResults';
import { useTranslation } from '../../contexts/TranslationContext';

const HEADS = {
	LEFT: 'left',
	RIGHT: 'right',
	CROWN: 'crown',
	VERTEX: 'vertex',
	FRONT: 'front',
	OCCIPITAL: 'occipital'
}

const referencePoints = [
	'Hairline',
	'Eye brow'
]

const defaultcloseupAnalysisPresent = {
	crown: true,
	front: true,
	vertex: true,
	left: true,
	right: true
}

const TooltipContext = createContext({});

const ShowHeadAvatar = ({ mainType, analysis, forType, handleTriggerRelaod, clientGender, closeupAnalysisPresent = defaultcloseupAnalysisPresent }) => {
	const location = useLocation()
	const { patient_id, analysis_id, subType, graphType } = useParams();
	const [showTooltip, setShowTooltip] = useState(false);
	const [allowInputChange, setAllowInputChange] = useState(false);
	const [reference, setReference] = useState(analysis?.images?.[mainType]?.raw?.[forType]?.position_reference_point || '');
	const [distance, setDistance] = useState(analysis?.images?.[mainType]?.raw?.[forType]?.position_reference_distance || '');
	const [imageExists, setImageExists] = useState(false)

	// ------------------------- Utility functions for tooltip -------------------------

	const handleDisableInputChange = () => {
		setShowTooltip(false)
		setAllowInputChange(false)
	}

	const handleEnableInputChange = () => {
		setAllowInputChange(true)
	}


	const handleReferenceChange = (val) => {
		setReference(val)
	}

	const handleDistanceChange = (val) => {
		setDistance(val)
	}

	const handleHideTooltip = () => {
		setShowTooltip(false)
	}

	const handleshowTooltip = () => {
		if (isClient) return;
		setShowTooltip(true)
	}

	const handleUpdateRefereneDistance = () => {
		if (!reference || !distance) {
			return Swal.fire(
				'Alert',
				'Please fill in both reference point and distance',
				'warning'
			)
		}

		const body = {
			reference_point: reference,
			distance: distance
		}

		fetch(`${process.env.REACT_APP_API}/hair_analysis/image/${analysis?.images?.[mainType]?.raw?.[forType]?.id}/position-reference`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			body: JSON.stringify(body)
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					Swal.fire(
						'Success',
						'Reference point data updated successfully!',
						'success'
					).then(() => {
						handleTriggerRelaod()
					})
					handleHideTooltip()
					handleDisableInputChange()
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
			});
	}

	// ------------------------ End of utility functions -----------------------------

	useEffect(() => {
		handleDisableInputChange()
		setReference(analysis?.images?.[mainType]?.raw?.[forType]?.position_reference_point);
		setDistance(analysis?.images?.[mainType]?.raw?.[forType]?.position_reference_distance);
		setImageExists(analysis?.images?.[mainType]?.raw?.[forType] ? true : false)
	}, [analysis, location?.pathname])

	const history = useHistory();

	const gender = useContext(GenderContext)?.gender || clientGender;
	const { isClient } = useContext(ClientContext)

	return (
		<div className='flex flex-col justify-center gap-4'>
			{!isClient && (
				<div className='flex items-center justify-center'>
					<button
						className="font-bold text-2xl focus:outline-none"
						onClick={function () {
							const disableTopNavigation = forType === HEADS.OCCIPITAL;

							if (!disableTopNavigation) {
								history.push(
									`/patient/${patient_id}/consult/${analysis_id}/global/${HEADS.OCCIPITAL
									}/hair_thinning`
								);
							}
						}}
					>
						<img src={lessThanIcon} className="h-6 w-6" style={{ transform: 'rotate(90deg)' }} alt='up-icon' />
					</button>
				</div>
			)}
			<div className="flex items-center justify-center w-full">
				<button
					className="font-bold text-2xl focus:outline-none"
					onClick={function () {
						const disableLeftNavigation = forType === HEADS.LEFT;

						if (!disableLeftNavigation) {
							history.push(
								isClient ? `/results/${patient_id}/${analysis_id}/closeup/${
									forType === HEADS.RIGHT ? HEADS.CROWN : HEADS.LEFT
								}` :
								`/patient/${patient_id}/consult/${analysis_id}/global/${forType === HEADS.RIGHT ? HEADS.CROWN : HEADS.LEFT
								}/hair_thinning`
							);
						}
					}}
				>
					<img src={lessThanIcon} alt='arrow-left' className="h-6 w-6" style={{
						visibility: (!isClient || closeupAnalysisPresent?.left || forType === HEADS.RIGHT) ? "visible" : 'hidden'
					}} />
				</button>
				<TooltipContext.Provider value={{ showTooltip, handleHideTooltip, handleshowTooltip, allowInputChange, handleEnableInputChange, handleDisableInputChange, reference, handleReferenceChange, distance, handleDistanceChange, handleUpdateRefereneDistance, imageExists }}>

					{
						[HEADS.CROWN, HEADS.FRONT, HEADS.VERTEX].includes(forType) &&
						<div className='relative '>
							<Link to={isClient ? `/results/${patient_id}/${analysis_id}/global/crown` : `/patient/${patient_id}/consult/${analysis_id}/global/crown/hair_thinning`}>
								{
									gender === 'F' || gender === "O" ? <img src={FemaleHeadTop} alt='femaleHead' className="w-36 sm:w-48 px-2" /> : gender === "M" ? <img src={MaleHeadTop} alt='maleHead' className="w-36 sm:w-48 px-2" /> : null
								}
							</Link>
							<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
								<div>
									{/* <Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/vertex/hair_thinning`}> */}
									<ShowDot hideIcon={!closeupAnalysisPresent?.vertex} mainType={mainType} forType="vertex" subType={subType} link={isClient ? `/results/${patient_id}/${analysis_id}/closeup/vertex` : `/patient/${patient_id}/consult/${analysis_id}/closeup/vertex/${graphType || 'hair_thinning'}`} />
									{/* </Link> */}
								</div>
								<div>
									{/* <Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/crown/hair_thinning`}> */}
									<ShowDot hideIcon={!closeupAnalysisPresent?.crown} mainType={mainType} forType="crown" subType={subType} link={isClient ? `/results/${patient_id}/${analysis_id}/closeup/crown` : `/patient/${patient_id}/consult/${analysis_id}/closeup/crown/${graphType || 'hair_thinning'}`} />
									{/* </Link> */}
								</div>
								<div>
									{/* <Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/front/hair_thinning`}> */}
									<ShowDot hideIcon={!closeupAnalysisPresent?.front} mainType={mainType} forType="front" subType={subType} link={isClient ? `/results/${patient_id}/${analysis_id}/closeup/front` : `/patient/${patient_id}/consult/${analysis_id}/closeup/front/${graphType || 'hair_thinning'}`} />
									{/* </Link> */}
								</div>
							</div>
						</div>
					}

					{
						forType === HEADS.LEFT &&
						<div className='relative '>
							<Link to={isClient ? `#` : `/patient/${patient_id}/consult/${analysis_id}/global/left/hair_thinning`}>
								{
									gender === 'F' || gender === "O" ? <img src={FemaleHeadLeft} className="w-46 sm:w-56 px-2" /> : gender === "M" ? <img src={MaleHeadLeft} className="w-36 sm:w-48 px-2" /> : null
								}
							</Link>

							<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
								<div>
									{/* <Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/left/hair_thinning`}> */}
									<ShowDot hideIcon={!closeupAnalysisPresent?.left} mainType={mainType} forType="left" subType={subType} link={isClient ? `/results/${patient_id}/${analysis_id}/closeup/left` : `/patient/${patient_id}/consult/${analysis_id}/closeup/left/${graphType || 'hair_thinning'}`} />
									{/* </Link> */}
								</div>
								<div>
								</div>
								<div>
								</div>
							</div>
						</div>
					}

					{
						forType === HEADS.RIGHT &&
						<div className='relative '>
							<Link to={isClient ? `#` : `/patient/${patient_id}/consult/${analysis_id}/global/right/hair_thinning`}>
								{
									gender === 'F' || gender === "O" ? <img src={FemaleHeadRight} className="w-46 sm:w-56 px-2" /> : gender === "M" ? <img src={MaleHeadRight} className="w-36 sm:w-48 px-2" /> : null
								}
							</Link>

							<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
								<div>
									{/* <Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/right/hair_thinning`}> */}
									<ShowDot hideIcon={!closeupAnalysisPresent?.right} mainType={mainType} forType="right" subType={subType} link={isClient ? `/results/${patient_id}/${analysis_id}/closeup/right` : `/patient/${patient_id}/consult/${analysis_id}/closeup/right/${graphType || 'hair_thinning'}`} />
									{/* </Link> */}
								</div>
								<div>
								</div>
								<div>
								</div>
							</div>
						</div>
					}

					{
						forType === HEADS.OCCIPITAL &&
						<div className='relative '>
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/global/${HEADS.OCCIPITAL}/hair_thinning`}>
								{
									gender === 'F' || gender === "O" ? <img src={FemaleHeadOccipital} alt='femaleHead' className="w-44 px-2" /> : <img src={MaleHeadOccipital} alt='maleHead' className="w-36 sm:w-48 px-2" />
								}
							</Link>

							<div className='absolute top-0 left-1/2 flex flex-col items-center justify-around h-full pb-8' style={{ transform: 'translate(-50%, 0)' }}>
								<div>
								</div>
								<div>
									{/* <Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/right/hair_thinning`}> */}
									<ShowDot mainType={mainType} forType={HEADS.OCCIPITAL} subType={subType} link={`/patient/${patient_id}/consult/${analysis_id}/closeup/${HEADS.OCCIPITAL}/${graphType || 'hair_thinning'}`} />
									{/* </Link> */}
								</div>
								<div>
								</div>
							</div>
						</div>
					}

				</TooltipContext.Provider>
				{(!isClient || closeupAnalysisPresent?.right || forType === 'left') && (
					<button
						className="font-bold text-2xl focus:outline-none"
						onClick={function () {
							const disableRightNavigation = forType === HEADS.RIGHT;

							if (!disableRightNavigation) {
								history.push(
									isClient ? `/results/${patient_id}/${analysis_id}/closeup/${forType === HEADS.LEFT ? HEADS.CROWN : HEADS.RIGHT}` :
									`/patient/${patient_id}/consult/${analysis_id}/global/${forType === HEADS.LEFT ? HEADS.CROWN : HEADS.RIGHT
									}/hair_thinning`
								);
							}
						}}
					>
						<img src={greaterThanIcon} className="h-6 w-6" alt='arrow-right'
							style={{
								visibility: (!isClient || closeupAnalysisPresent?.right || forType === 'left') ? "visible" : "hidden"
							}}
						/>
					</button>
				)}
			</div>
			{!isClient && (
				<div className='flex items-center justify-center'>
					<button
						className="font-bold text-2xl focus:outline-none"
						onClick={function () {
							const disableBottomNavigation = forType !== HEADS.OCCIPITAL;

							if (!disableBottomNavigation) {
								history.push(
									`/patient/${patient_id}/consult/${analysis_id}/global/${HEADS.CROWN
									}/hair_thinning`
								);
							}
						}}
					>
						<img src={lessThanIcon} className="h-6 w-6" style={{ transform: 'rotate(270deg)' }} alt='up-icon' />
					</button>
				</div>
			)}
		</div>
	)
}

const ShowDot = ({ forType, subType, mainType, link, hideIcon }) => {

	const { showTooltip,
		handleHideTooltip,
		handleshowTooltip,
		allowInputChange,
		handleEnableInputChange,
		handleDisableInputChange,
		reference,
		handleReferenceChange,
		distance,
		handleDistanceChange,
		handleUpdateRefereneDistance,
		imageExists
	} = useContext(TooltipContext)

	const [showDropDown, setShowDropDown] = useState(false);
	const [isEnteringCustomReference, setIsEnteringCustomReference] = useState(false);
	const inputRef = useRef()
	const { translate } = useTranslation();
	const handleSelectCustomRef = () => {
		setShowDropDown(false)
		setIsEnteringCustomReference(true)
		setTimeout(() => {
			inputRef.current?.focus()
		}, 100)
	}

	if (hideIcon) return <span className='invisible'></span>


	if (forType === subType && mainType === 'closeup') {
		return (
			<div className='relative'>
				<Link to={link} className="h-3 w-3 sm:h-4 sm:w-4">
					<img src={DotTurquoise} className='h-3 w-3 sm:h-4 sm:w-4 cursor-pointer' onClick={showTooltip ? handleHideTooltip : handleshowTooltip} />
				</Link>
				{/* <img src={DotTurquoise} className='h-3 w-3 cursor-pointer' onClick={handleHideTooltip} /> */}

				{showTooltip && imageExists ? (
					<div className="absolute top-5 left-1/2  flex flex-col items-center mb-6 group-hover:flex" style={{ transform: 'translate(-50%)' }}>
						<div className="w-4 h-4 bg-white" style={{ transform: "rotate(45deg)" }}></div>
						<div className="relative z-10 py-2 px-2 w-max bg-white text-center" style={{ transform: 'translate(0, -8px)' }}>
							<p className='font-bold leading-none text-black mb-1 whitespace-nowrap' style={{ fontSize: '11px' }}>{translate('reference_point')}</p>
							<div className='border border-gray-400 w-28 mb-1 font-medium text-black text-center relative' style={{ fontSize: '11px' }} >
								{isEnteringCustomReference ? (

									<input ref={inputRef} className='border-0 font-medium text-black w-full text-center rounded-none' value={reference} onChange={e => { handleReferenceChange(e.target.value) }} style={{ fontSize: '11px' }} />

								) : (
									<input className='border-0 font-medium text-black w-full text-center rounded-none' disabled value={reference} style={{ fontSize: '11px' }} />

								)}
								{showDropDown && allowInputChange ? (
									<div className='absolute w-28 top-5 z-20 bg-white border border-gray-400' style={{ transform: 'translate(-1px, 0)' }}>
										{referencePoints.map((item, index) => {
											return <div key={index} className='font-medium text-black text-center w-full hover:bg-gray-200 cursor-pointer' onClick={() => { handleReferenceChange(item); setShowDropDown(false); setIsEnteringCustomReference(false) }}>{item}</div>
										})}
										<div className='font-medium text-black text-center w-full hover:bg-gray-200 cursor-pointer' onClick={handleSelectCustomRef}>{translate('custom_point')}</div>
									</div>
								) : ("")}

								{allowInputChange ? (
									<img src={DownArrow} onClick={() => { setShowDropDown(prev => !prev) }} className='absolute z-20 right-1 top-1 h-3 w-3 cursor-pointer' />
								) : ("")}



							</div>
							<p className='font-bold leading-none text-black mb-1 whitespace-nowrap' style={{ fontSize: '11px' }}>{translate('distance')}</p>
							<div className='relative flex justify-center items-center  border border-gray-400 w-28 mb-1'>
								{allowInputChange ? (
									<input type='number' className='border-0 font-medium text-black w-full text-center rounded-none' value={distance} onChange={e => handleDistanceChange(e.target.value)} style={{ fontSize: '11px' }} />

								) : (
									<input className='border-0 font-medium text-black w-full text-center rounded-none' disabled value={distance} style={{ fontSize: '11px' }} />
								)}
								<span className='absolute z-10 right-1 top-0 font-medium text-black' style={{ fontSize: '11px' }}>in</span>

							</div>
							<button className='text-white py-1 px-2 rounded mt-1' onClick={allowInputChange ? handleUpdateRefereneDistance : handleEnableInputChange} style={{ backgroundColor: '#4dbfdb', fontSize: '11px', padding: '0.15rem 0.5rem' }}>{allowInputChange ? translate('confirm') : translate('change')}</button>
						</div>
					</div>
				) : ("")}
			</div>)
	}

	return <Link className="h-3 w-3 sm:h-4 sm:w-4" to={link}><img src={DotGrey} className='h-3 w-3 sm:h-4 sm:w-4 cursor-pointer' onClick={handleHideTooltip} /></Link>
};

export default React.memo(ShowHeadAvatar)