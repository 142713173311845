import React, { useState, useEffect, useRef, useCallback } from 'react';
import { NavLink, useParams, Redirect } from 'react-router-dom';
import PatientNavbar from '../components/PatientNavbar';
import Loading from '../views/Loading';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from "@fullcalendar/interaction";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import { useTranslation } from "../contexts/TranslationContext";

export default function PatientScheduleAppointment() {
  const { id } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [procedures, setProcedures] = useState(null);
  const [procedureId, setProcedureId] = useState(null);
  // const [duration, setDuration] = useState([new Date(), new Date()]);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const calendar = useRef();
  // const [view, setView] = useState("dayGridMonth");
  const [scheduledProcedures, setscheduledProcedures] = useState([]);
  const { translate } = useTranslation();

  // const changeView = (view) => {
  //   const calendarApi = calendar.current.getApi();
  //   calendarApi.changeView(view);
  //   setView(view);
  // };

  // const goToDate = (date) => {
  //   const calendarApi = calendar.current.getApi();
  //   calendarApi.changeView("timeGridWeek", date.date);
  //   setView("timeGridWeek");
  // };

  const scheduleAppointment = newEvent => {
    // setReady(false);
    fetch(`${process.env.REACT_APP_API}/patient/${id}/schedule-appointment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({
        procedureId: newEvent.id,
        startTime: newEvent.start.getTime(),
        endTime: newEvent.end.getTime(),
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setscheduledProcedures(scheduledProcedures.concat(newEvent));
          load();
          setRedirect(false);
        } else {
          setMessage(response.message);
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      })
      .finally(() => setProcedureId(null));
  };

  const load = useCallback(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/unscheduled-treatments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setProcedures(response.procedures);
          setPatientReferrals(response.referral);
          if (response.procedures.length > 0) {
            // setProcedureId(response.procedures[0].id);
          }
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });

    fetch(`${process.env.REACT_APP_API}/clinic/upcoming-appointments`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setscheduledProcedures(
            response.data.map(procedure => {
              return {
                id: procedure.id,
                title: `${procedure.type}, ${procedure.physician}`,
                start: procedure.startTime,
                end: procedure.endTime,
              };
            })
          );
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (procedures) {
      new Draggable(document.getElementById('events'), {
        itemSelector: '.event',
      });
    }
  });

  if (redirect) {
    return <Redirect to={`/patient/schedule/view-schedule/${id}`} />;
  }

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={4} />
        <section className="mt-6">
          {message && <p className="text-center text-red-600">{message}</p>}
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} ${translate('reffered_by')} ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
            <NavLink
              exact
              to={`/patient/schedule/schedule-appointment/${id}`}
              activeClassName="text-text-2"
            >
              {translate('schedule_appointment')}
            </NavLink>
            <div className="mx-8 my-2 w-2 h-2 bg-background-23 rounded-full"></div>
            <NavLink
              exact
              to={`/patient/schedule/view-schedule/${id}`}
              activeClassName="text-text-2"
            >
              {translate('view_schedule')}
            </NavLink>
          </div>
          {procedures && (
            <div className="mt-4 grid gap-4 xl:grid-cols-2">
              <div
                id="events"
                className="bg-background-5 p-4 flex flex-col"
                style={{ height: 'var(--height-1)', overflowY: 'auto' }}
              >
                <h1 className="text-center text-text-2">
                  {translate('unscheduled_treatments')}
                </h1>
                {procedures.length > 0 ? (
                  <>
                    <div className="mt-4 grid grid-cols-7 justify-items-center font-normal font-font-2">
                      <div></div>
                      <h2 className="px-2">{translate('brand')}</h2>
                      <h2 className="px-2"># {translate('tx')}</h2>
                      <h2 className="px-2">{translate('interval')}</h2>
                      <h2 className="px-2">{translate('area')}</h2>
                      <h2 className="px-2 text-text-2">{translate('time')}</h2>
                      <div></div>
                    </div>
                    {procedures.map(procedure => {
                      return (
                        <div
                          key={procedure.id}
                          id={'events'}
                          className="mt-4 grid grid-cols-7 text-center text-text-3 font-normal font-font-2"
                        >
                          <div
                            className="grid grid-cols-6 col-span-6 event"
                            data-event={JSON.stringify({
                              create: false,
                              id: procedure.id,
                              title: procedure.type,
                              duration: procedure.time * 1000 * 60,
                            })}
                          >
                            <h2 className="px-2 py-1 bg-background-6 font-bold">
                              {procedure.type}
                            </h2>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.brand}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.tx}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.interval}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.area}
                            </p>
                            <p className="px-2 py-1 w-full bg-background-7">
                              {procedure.time}
                            </p>
                          </div>
                          <button
                            className="mx-auto focus:outline-none fc-event"
                            onClick={() => {
                              // setProcedureId(procedure.id);
                            }}
                          >
                            <svg
                              className={`h-6 w-6 ${
                                procedure.id === procedureId
                                  ? 'text-accent-1'
                                  : 'text-background-7'
                              }`}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                    <button
                      className="mt-16 mb-8 mx-auto px-8 py-1 bg-background-23 text-text-3 font-font-2 font-bold rounded-lg focus:outline-none"
                      // onClick={scheduleAppointment}
                    >
                      {translate('confirm')}
                    </button>
                  </>
                ) : (
                  <h2 className="mt-8 text-center">
                    {translate('no_unscheduled_treatments_currently_available')}
                  </h2>
                )}
              </div>
              <div
                className="bg-background-5 p-4 flex flex-col"
                style={{ height: 'var(--height-1)', overflowY: 'auto' }}
              >
                <h1 className="text-center text-text-2">{translate('search_appointment')}</h1>
                {/*procedureId && (
                  <div>
                    Click on day for selecting time. To select time, click on
                    start time and drag till end time.
                  </div>
                )*/}

                {ready && (
                  <FullCalendar
                    ref={calendar}
                    initialView="timeGridWeek"
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={scheduledProcedures}
                    // dateClick={goToDate}
                    // selectable={true}
                    // select={function (info) {
                    //   setDuration([info.start, info.end]);
                    // }}
                    selectOverlap={false}
                    allDaySlot={false}
                    slotDuration="00:15:00"
                    slotLabelInterval="01:00"
                    // dayHeaderFormat = {{ weekday: 'short', day: 'numeric'}}
                    dayHeaderContent={function (day) {
                      return dayjs(day.date).format('ddd D');
                    }}
                    eventBackgroundColor="#13C4B3"
                    headerToolbar={{
                      start: 'prev', // will normally be on the left. if RTL, will be on the right
                      center: 'title',
                      end: 'next', // will normally be on the right. if RTL, will be on the left
                    }}
                    droppable={true}
                    height="100%"
                    eventOverlap={false}
                    drop={info => {
                      const calendarApi = calendar.current.getApi();
                      const events = calendarApi.getEvents();
                      const data = JSON.parse(
                        info.draggedEl.getAttribute('data-event')
                      );
                      let unscheduled =
                        events.find(o => Number(o.id) === data.id) ===
                        undefined;
                      if (!unscheduled || Number(procedureId) === data.id) {
                        return;
                      }
                      const newEvent = {
                        id: data.id,
                        title: data.title,
                        start: info.date,
                        end: new Date(
                          dayjs(info.date).add(data.duration, 'ms').valueOf()
                        ),
                      };
                      scheduleAppointment(newEvent);
                      setProcedureId(data.id);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </section>
      </main>
      <Loading message={translate('loading_appointment_information')} ready={ready} />
    </>
  );
}
