import { React, useState, useEffect } from "react";
import PresentationNavbar from "./PresentationNavbar";
import { useTranslation } from "../../contexts/TranslationContext";

const createHairDensityAnalysis = () => {
  const entries = 3;
  let id = 1;
  const areas = ["Front", "Crown", "Vertex", "Back", "Right", "Left"];
  const imgs = [
    "https://ik.imagekit.io/visceailxwt/img1_2-PHDAXWRL.PNG",
    "https://ik.imagekit.io/visceailxwt/img2_N7ht64SknF.PNG",
    "https://ik.imagekit.io/visceailxwt/img3_LLCvMnG8Xe.PNG",
    ];
    const hairAnalysis = [];
    for (let i = 0; i < entries; i++) {
      for (let j = 0; j < areas.length; j++) {
        hairAnalysis.push({
          id: id++,
          area: areas[j],
          date: `${17 + i}-11-2020`,
          img: imgs[(Math.random() * imgs.length).toFixed()],
          All: (Math.random() * 80 + 100).toFixed(0),
          T: (Math.random() * 80 + 100).toFixed(0),
          V: (Math.random() * 80 + 100).toFixed(0),
          TV: (Math.random() * 80 + 100).toFixed(0),
          FU: (Math.random() * 80 + 100).toFixed(0),
          });
          }
          }
          return hairAnalysis;
          };
          
          export default function HairDensityTracking({
            id,
            setMessage,
            setReady,
            setView,
            }) {
              const [groTrackKey, setGroTrackKey] = useState(false);
              const [hairDensityAnalysis, setHairDensityAnalysis] = useState(null);
              const [area, setArea] = useState("Front");
              const [index1, setIndex1] = useState(0);
              const [index2, setIndex2] = useState(0);
              const { translate } = useTranslation();
              
  const currentAnalysis = () => {
    return hairDensityAnalysis.filter((analysis) => {
      return analysis.area === area;
    });
  };

  const difference = (n1, n2) => {
    return (((n2 - n1) * 100) / n1).toFixed(0);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setGroTrackKey(Math.random() < 0.5);
          setHairDensityAnalysis(createHairDensityAnalysis());
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id, setReady, setMessage]);

  return (
    hairDensityAnalysis && (
      <>
        <div
          className="p-6 mt-4 bg-background-5 overflow-hidden"
          style={{ height: "var(--height-2)" }}
        >
          <div className="flex">
            <div className="md:absolute flex items-center">
              {groTrackKey ? (
                <svg
                  className="h-10 w-10 text-blue-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                  />
                </svg>
              ) : (
                <svg
                  className="h-10 w-10 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                  />
                </svg>
              )}
              <p className="ml-2 font-normal text-sm">{translate('grotrack_key')}</p>
            </div>
            <div className="mt-6 flex items-center justify-center mx-auto font-font-2 text-text-8">
              <button
                className={`${
                  area === "Front" && "text-text-7"
                } focus:outline-none mx-2`}
                onClick={() => {
                  setArea("Front");
                }}
              >
                {translate('front')}
              </button>
              <button
                className={`${
                  area === "Crown" && "text-text-7"
                } focus:outline-none mx-2`}
                onClick={() => {
                  setArea("Crown");
                }}
              >
                {translate('crown')}
              </button>
              <button
                className={`${
                  area === "Vertex" && "text-text-7"
                } focus:outline-none mx-2`}
                onClick={() => {
                  setArea("Vertex");
                }}
              >
                {translate('vertex')}
              </button>
              <button
                className={`${
                  area === "Back" && "text-text-7"
                } focus:outline-none mx-2`}
                onClick={() => {
                  setArea("Back");
                }}
              >
                {translate('back')}
              </button>
              <button
                className={`${
                  area === "Left" && "text-text-7"
                } focus:outline-none mx-2`}
                onClick={() => {
                  setArea("Left");
                }}
              >
                {translate('left')}
              </button>
              <button
                className={`${
                  area === "Right" && "text-text-7"
                } focus:outline-none mx-2`}
                onClick={() => {
                  setArea("Right");
                }}
              >
                {translate('right')}
              </button>
            </div>
          </div>
          {currentAnalysis().length > 0 ? (
            <div className="mt-10 flex items-center justify-evenly">
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <button
                    className="focus:outline-none"
                    onClick={() => {
                      setIndex1(Math.max(0, index1 - 1));
                    }}
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 512"
                    >
                      <path
                        fill="#bfc5ca"
                        d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                      />
                    </svg>
                  </button>
                  <h2 className="mx-2">{currentAnalysis()[index1].date}</h2>
                  <button
                    className="focus:outline-none"
                    onClick={() => {
                      setIndex1(
                        Math.min(currentAnalysis().length - 1, index1 + 1)
                      );
                    }}
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 512"
                    >
                      <path
                        fill=" #bfc5ca"
                        d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                      />
                    </svg>
                  </button>
                </div>
                <img
                  className="mt-2 w-56"
                  src={currentAnalysis()[index1].img}
                  alt="Hair Analysis1"
                />
              </div>
              <div className="text-center font-font-2 font-normal text-text-2 text-sm">
                <div className="flex items-center">
                  <div className="mr-2 grid p-2 grid-cols-3 gap-2 border-2 border-text-2 items-baseline">
                    <p>{currentAnalysis()[index1].All}</p>
                    <p className="font-bold text-base text-text-1">{translate('all')}</p>
                    <p>{currentAnalysis()[index2].All}</p>
                  </div>
                  <p
                    className={
                      difference(
                        currentAnalysis()[index1].All,
                        currentAnalysis()[index2].All
                      ) < 0
                        ? "text-button-3"
                        : "text-accent-1"
                    }
                  >
                    {difference(
                      currentAnalysis()[index1].All,
                      currentAnalysis()[index2].All
                    )}
                    %
                    <strong className="ml-1 text-2xl">
                      {difference(
                        currentAnalysis()[index1].All,
                        currentAnalysis()[index2].All
                      ) < 0
                        ? "↓"
                        : "↑"}
                    </strong>
                  </p>
                </div>
                <div className="mt-4 flex items-center">
                  <div className="mr-2 grid p-2 grid-cols-3 gap-2 border-2 border-text-2 items-baseline">
                    <p>{currentAnalysis()[index1].T}</p>
                    <p className="font-bold text-base text-text-1">T</p>
                    <p>{currentAnalysis()[index2].T}</p>
                  </div>
                  <p
                    className={
                      difference(
                        currentAnalysis()[index1].T,
                        currentAnalysis()[index2].T
                      ) < 0
                        ? "text-button-3"
                        : "text-accent-1"
                    }
                  >
                    {difference(
                      currentAnalysis()[index1].T,
                      currentAnalysis()[index2].T
                    )}
                    %
                    <strong className="ml-1 text-2xl">
                      {difference(
                        currentAnalysis()[index1].T,
                        currentAnalysis()[index2].T
                      ) < 0
                        ? "↓"
                        : "↑"}
                    </strong>
                  </p>
                </div>
                <div className="mt-4 flex items-center">
                  <div className="mr-2 grid p-2 grid-cols-3 gap-2 border-2 border-text-2 items-baseline">
                    <p>{currentAnalysis()[index1].V}</p>
                    <p className="font-bold text-base text-text-1">V</p>
                    <p>{currentAnalysis()[index2].V}</p>
                  </div>
                  <p
                    className={
                      difference(
                        currentAnalysis()[index1].V,
                        currentAnalysis()[index2].V
                      ) < 0
                        ? "text-button-3"
                        : "text-accent-1"
                    }
                  >
                    {difference(
                      currentAnalysis()[index1].V,
                      currentAnalysis()[index2].V
                    )}
                    %
                    <strong className="ml-1 text-2xl">
                      {difference(
                        currentAnalysis()[index1].V,
                        currentAnalysis()[index2].V
                      ) < 0
                        ? "↓"
                        : "↑"}
                    </strong>
                  </p>
                </div>
                <div className="mt-4 flex items-center">
                  <div className="mr-2 grid p-2 grid-cols-3 gap-2 border-2 border-text-2 items-baseline">
                    <p>{currentAnalysis()[index1].TV}</p>
                    <p className="font-bold text-base text-text-1">{translate('t_v')}</p>
                    <p>{currentAnalysis()[index2].TV}</p>
                  </div>
                  <p
                    className={
                      difference(
                        currentAnalysis()[index1].TV,
                        currentAnalysis()[index2].TV
                      ) < 0
                        ? "text-button-3"
                        : "text-accent-1"
                    }
                  >
                    {difference(
                      currentAnalysis()[index1].TV,
                      currentAnalysis()[index2].TV
                    )}
                    %
                    <strong className="ml-1 text-2xl">
                      {difference(
                        currentAnalysis()[index1].TV,
                        currentAnalysis()[index2].TV
                      ) < 0
                        ? "↓"
                        : "↑"}
                    </strong>
                  </p>
                </div>
                <div className="mt-4 flex items-center">
                  <div className="mr-2 grid p-2 grid-cols-3 gap-2 border-2 border-text-2 items-baseline">
                    <p>{currentAnalysis()[index1].FU}</p>
                    <p className="font-bold text-base text-text-1">FU</p>
                    <p>{currentAnalysis()[index2].FU}</p>
                  </div>
                  <p
                    className={
                      difference(
                        currentAnalysis()[index1].FU,
                        currentAnalysis()[index2].FU
                      ) < 0
                        ? "text-button-3"
                        : "text-accent-1"
                    }
                  >
                    {difference(
                      currentAnalysis()[index1].FU,
                      currentAnalysis()[index2].FU
                    )}
                    %
                    <strong className="ml-1 text-2xl">
                      {difference(
                        currentAnalysis()[index1].FU,
                        currentAnalysis()[index2].FU
                      ) < 0
                        ? "↓"
                        : "↑"}
                    </strong>
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex items-center">
                  <button
                    className="focus:outline-none"
                    onClick={() => {
                      setIndex2(Math.max(0, index2 - 1));
                    }}
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 512"
                    >
                      <path
                        fill="#bfc5ca"
                        d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                      />
                    </svg>
                  </button>
                  <h2 className="mx-2">{currentAnalysis()[index2].date}</h2>
                  <button
                    className="focus:outline-none"
                    onClick={() => {
                      setIndex2(
                        Math.min(currentAnalysis().length - 1, index2 + 1)
                      );
                    }}
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 512"
                    >
                      <path
                        fill=" #bfc5ca"
                        d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                      />
                    </svg>
                  </button>
                </div>
                <img
                  className="mt-2 w-56"
                  src={currentAnalysis()[index2].img}
                  alt="Hair Analysis2"
                />
              </div>
            </div>
          ) : (
            <h2 className="mt-4 text-center font-font-2">
              {translate('no_analysis_present')}
            </h2>
          )}
        </div>
        <PresentationNavbar tab={4} setView={setView} />
      </>
    )
  );
}
