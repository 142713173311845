import { React, useState, useEffect, useRef } from "react";
import CrossIcon from "../assets/images/CrossGrey.svg";
import AddIcon from "../assets/images/AddIcon.svg";
import Loading from "../views/Loading";
import { useUserContext } from "../contexts/userContext";
import AdminHeader from "../components/AdminHeader";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "../contexts/TranslationContext";

const staffTemplate = {
  name: "",
  phone: "",
  email: "",
}

export default function AdminStaff() {
  const history = useHistory();
  const { translate } = useTranslation();
  const [physician, setPhysician] = useState(null);
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const { isSalonApp, handleSetTrainingDone, handletSetStaff } = useUserContext();

  const [newStaffItem, setNewStaffItem] = useState(staffTemplate);
  const [isAddStaffVisible, setIsAddStaffVisible] = useState(false);

  // Fetch functions -------------------------------------------------------------

  const getStaffMembers = () => {
    setStaffList([])
    fetch(`${process.env.REACT_APP_API}/clinic/staffs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      }
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setStaffList(response.data?.reverse())
          if (!response.data?.length) {
            setIsAddStaffVisible(true)
          }
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      });
  }

  useEffect(() => {
    Promise.all([
      getStaffMembers()
    ]).then(() => {
    }).finally(() => {
      setReady(true);
    });
  }, []);

  // Async handlers -----------------------------------------------------------------------------

  const handleUpdateStaff = (staffItem, staffMemberId) => {
    setReady(false)
    fetch(`${process.env.REACT_APP_API}/clinic/staffs${staffMemberId ? `/${staffMemberId}` : ``}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(staffItem)
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          getStaffMembers()
          Swal.fire(
            'Staff member updated',
            'Staff member details have been updated successfully!',
            'success'
          )
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      }).finally(() => {
        setReady(true);
      });
  }

  const handleAddStaff = (staffItem) => {
    setReady(false)
    fetch(`${process.env.REACT_APP_API}/clinic/staffs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(staffItem)
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          handleResetNewStaff()
          getStaffMembers()
          handleSetTrainingDone(false)
          Swal.fire(
            'Staff member added',
            'New staff member has been added successfully!',
            'success'
          )
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      }).finally(() => {
        setReady(true);
      });
  }

  const handleDeleteStaff = (id) => {
    // Handle delete staff

    // Swal.fire({
    //   title: `Are you sure you want to delete this staff member?`,
    //   text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes'
    // }).then((result) => {
    //   if (result.isConfirmed) {

    //   }
    // })
  }

  // Utility functions --------------------------------------------------------------------

  const handleResetNewStaff = () => {
    setIsAddStaffVisible(false)
    setNewStaffItem(staffTemplate);
  }

  const setUpStaff = () => {
    if (staffList.length) {
      // history.push("/admin/create-password?isOnboarding=true");
      history.push("/admin/training");
      return;
    }
    Swal.fire(
      "At least one staff member required!",
      "Please add atleast one staff member to proceed",
      "warning"
    );
  }

  return (
    <>
      <main
        className={`mx-auto w-full flex flex-col p-5 pb-12 font-font-2 tracking-wide font-normal ${ready ? "" : "hidden"
          }`}
      >
        <AdminHeader hideChatGPT hideHomeLink />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="mx-auto flex px-5 w-full xl:px-24 xl:container" style={{ flexGrow: 1 }}>
          <div className="mt-16" style={{ flexGrow: 1 }}>
            <div className="flex items-center justify-center">
              <h1 className="mr-8 text-2xl text-text-3 font-bold uppercase tracking-wider">
                {translate('set_up')} {isSalonApp ? translate('stylist') : translate('staff')} {translate('users')}
              </h1>
              <img src={AddIcon} alt="add-icon" className="h-6 w-6 cursor-pointer" onClick={() => setIsAddStaffVisible(true)} />
            </div>
            <div className="mt-24">
              {isAddStaffVisible && <StaffItem item={newStaffItem} handleSubmit={handleAddStaff} handleResetNewStaff={handleResetNewStaff} shouldFocusOnRef={true} hideCancel={staffList?.length === 0} />}
              {staffList.map((item, index) => {
                return (
                  <StaffItem item={item} key={index} handleSubmit={handleUpdateStaff} handleDeleteStaff={handleDeleteStaff} />
                )
              })}
            </div>
            <div className="mx-auto my-20 flex justify-center items-center">
              <button
                className="px-6 py-2 mx-2 bg-button-5 tracking-widest rounded-lg text-text-3 font-bold text-sm
                cursor-pointer focus:outline-none uppercase"
                onClick={(event) => {
                  event.preventDefault();
                  setUpStaff();
                }}
              >
                {translate('next')}
              </button>
            </div>
          </div>
        </section>
      </main>
      <Loading
        message={translate('loading_staff_details')}
        ready={ready} />
    </>
  );
}

const StaffItem = ({ item, handleSubmit, handleResetNewStaff, shouldFocusOnRef = false, handleDeleteStaff, hideCancel = false }) => {
  const [isEditMode, setIsEditMode] = useState(shouldFocusOnRef ? true : false);
  const [name, setName] = useState(item.name);
  const [phone, setPhone] = useState(item.phone);
  const [email, setEmail] = useState(item.email);
  const nameRef = useRef(null)
  const { translate } = useTranslation();

  const handleCancelEdit = () => {
    if (handleResetNewStaff) {
      handleResetNewStaff()
      return
    }
    setName(item.name)
    setPhone(item.phone)
    setEmail(item.email)
    setIsEditMode(false)
  }

  const handleAllowEdit = () => {
    setIsEditMode(true)
    setTimeout(() => {
      nameRef?.current?.focus()
    }, 50)
  }

  useEffect(() => {
    if (shouldFocusOnRef) {
      handleAllowEdit()
    }
  }, [])

  return (
    <div className="mb-10">

      {/* <div className="flex items-center">
        {!shouldFocusOnRef && (
          <input readOnly className="placeholder:text-xl px-1 mr-5 text-xl text-text-2 pointer-events-none font-bold capitalize tracking-widest bg-transparent border-0 w-40" disabled={!isEditMode} value={name} onChange={event => setName(event.target.value)} />
        )}
        {isEditMode && !hideCancel ?
          <span onClick={handleCancelEdit} className="px-8 py-1 bg-red-600 rounded-lg uppercase text-sm text-text-3 font-bold cursor-pointer focus:outline-none">CANCEL</span>
          : !hideCancel &&
          <img src={CrossIcon} alt="cross-icon" className="h-4 w-4 cursor-pointer" onClick={() => { handleDeleteStaff(item?.id) }} />}
      </div> */}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit({ name, email, phone }, item?.id)
          setIsEditMode(false)
        }}
        className="grid grid-cols-12 gap-3 xl:gap-5 mt-3 items-center w-full justify-between text-text-2 font-bold capitalize tracking-wider"
      >
          <label className="flex items-center w-full col-span-3">
            <p className=" ml-2 mr-2">{translate('name')}</p>
            <input
              type="text"
              ref={nameRef}
              required
              disabled={!isEditMode}
              className="w-full px-3 placeholder-text-2 py-1 bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </label>
        <label className={`flex items-center w-full ${hideCancel ? `col-span-4` : `col-span-3`}`}>
          <p className=" ml-2 mr-2">{translate('phone')}</p>
          <input
            type="number"
            value={phone}
            required
            disabled={!isEditMode}
            className="w-full px-3 placeholder-text-2 py-1 bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
            onChange={(event) => {
              setPhone(event.target.value)
            }}
          />
        </label>
        <label className={`flex pl-5 items-center w-full ${isEditMode ? `col-span-4` : `col-span-5`}`}>
          <p className="mr-2">{translate('email')}</p>
          <input
            type="email"
            value={email}
            required
            disabled={!isEditMode}
            className="w-full px-3 placeholder-text-2 py-1 bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
        </label>
        <div className="col-span-1">
          {isEditMode ? (
            <button
              type="submit"
              className="px-3 py-1.5 bg-button-5 tracking-widest rounded-lg text-text-3 font-bold text-sm
cursor-pointer focus:outline-none uppercase"
            >
              {translate('confirm_sm')}
            </button>)
            : (
              <div onClick={handleAllowEdit}>
                <button
                  className="px-3 py-1.5 bg-button-5 rounded-lg tracking-widest uppercase text-sm text-text-3 font-bold
              cursor-pointer focus:outline-none"
                >
                  {translate('update')}
                </button>
              </div>
            )}
        </div>
        {!hideCancel && isEditMode && (
          <div className={`col-span-1 pl-3 ${!isEditMode ? 'flex justify-center' : ""}`}>
            <button
            type="button"
            onClick={handleCancelEdit}
              className="px-3 py-1.5 bg-red-600 tracking-widest rounded-lg text-text-3 font-bold text-sm
              cursor-pointer focus:outline-none uppercase"
              >
              {translate('cancel')}
            </button>
          </div>
          )}
      </form>
    </div>
  )
}
