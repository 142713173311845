import React from "react";
import { useUserContext } from "../../contexts/userContext";
import { getLogo } from "../../utlis/themeHelpers";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "../../contexts/TranslationContext";

const PaymentInit = ({ initCheckout, setError, setMessage }) => {
  const { isSalonApp } = useUserContext();
const { translate } = useTranslation();
  const isOnboardingFlowOne = useRouteMatch("/onboarding/:email_url?/:type?/:status?");


  return (
    <div className="flex flex-col mb-20" style={{flexGrow: "1"}}>
      <nav className="flex items-center border-b-2 border-gray-700 py-10 mx-10 px-auto">
        <img
          className="h-20 mx-auto"
          src={getLogo(isSalonApp)}
          alt="GRO Track"
        />
      </nav>

      <div className="text-center mt-14 tracking-wider flex flex-col justify-evenly" style={{flexGrow: "1"}}>
        <PaymentInfo isSalonApp={isSalonApp} isOnboardingFlowOne={isOnboardingFlowOne}/>
        <button
          className="w-max mx-auto bg-button-5 font-bold uppercase tracking-widest text-white hover:underline focus:outline-none focus:underline px-12 py-2 rounded-md"
          onClick={() => {
            if (initCheckout && setError && setMessage) {
              initCheckout("setup");
              setError("");
              setMessage("");
            }
          }}
        >
          {translate('confirm')}
        </button>
      </div>
    </div>
  );
};

const PaymentInfo = ({ isSalonApp, isOnboardingFlowOne }) => {
  const { translate } = useTranslation();

  if (isSalonApp) return (
    <>
      <h1 className="text-3xl text-text-2  mb-6 tracking-wider">{translate('grotrack_system_for_stylists')}</h1>
      <h1 className="text-3xl text-text-2 my-6">$1,500</h1>

      <div className="flex justify-center">
        <ul className="text-left text-text-2 list-disc list-inside">
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_camera')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_software')}
          </li>
        </ul>
      </div>

      <h1 className="text-3xl text-text-2  mb-6">{translate('monthly_service_fee')}</h1>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          {translate('monthly_service_fee_charged')}
          <br /> {translate('cancel_grotrack_services')}
        </p>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          {translate('monthly_service_fee_includes')}
        </p>
      </div>
    </>
  )

  if (!isOnboardingFlowOne) return (
    <>
<h1 className="text-3xl text-text-2  mb-20 tracking-wider">{translate('grotrack_system')}</h1>
      {/* <h1 className="text-3xl text-text-2 my-6">$4,500</h1> */}

      <div className="flex justify-center">
        <ul className="text-left text-text-2 list-disc list-inside">
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_camera')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_software_license')}
          </li>
        </ul>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
        {translate('monthly_service_fee_199')}
        <br />{translate('cancel_grotrack_services_anytime')} 
        <br/>{translate('30_days_prior_billing_cycle')}
        </p>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          {translate('monthly_service_fee_includes_lwcs')}
        </p>
      </div>
    </>
  )

  return (
    <>
      <h1 className="text-3xl text-text-2  mb-6 tracking-wider">{translate('grotrack_system')}</h1>
      <h1 className="text-3xl text-text-2 my-6">$4,500</h1>

      <div className="flex justify-center">
        <ul className="text-left text-text-2 list-disc list-inside">
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_camera')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_software_license')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('12_months_service_included')}
          </li>
        </ul>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          {translate('monthly_service_fee_after_12_months')}
        </p>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          {translate('monthly_service_fee_includes_lwcs')}
        </p>
      </div>
    </>
  )


}

export default PaymentInit;
