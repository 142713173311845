import { React } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../contexts/TranslationContext';

export default function PresentationNavbar(props) {
  const { translate } = useTranslation();
  return (
    <nav
      className="mt-8 grid grid-cols-4 gap-12 font-normal font-font-2 text-center text-text-10"
      style={{ height: '62px' }}
    >
      <NavLink
        to={`/patient/presentation/overview/${props.id}`}
        className="flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <figure
          className={`rounded-full p-1 mx-2 ${
            props.tab === 1 ? 'bg-text-9' : 'bg-text-10'
          }`}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 1 && 'text-text-9 font-bold'}`}>
          {translate('gro_tracker')}
        </p>
      </NavLink>
      <NavLink
        to={`/patient/presentation/gro-tracker/hair-density-tracking/${props.id}`}
        className="flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <figure
          className={`rounded-full p-1 mx-2 ${
            props.tab === 4 ? 'bg-text-9' : 'bg-text-10'
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <rect fill="none" height="24" width="24" />
            <g>
              <path d="M7.5,21H2V9h5.5V21z M14.75,3h-5.5v18h5.5V3z M22,11h-5.5v10H22V11z" />
            </g>
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 4 && 'text-text-9 font-bold'}`}>
          {translate('gro_scale')}
        </p>
      </NavLink>
      <NavLink
        to={`/patient/presentation/hair-data/${props.id}`}
        className="flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <figure
          className={`rounded-full p-1 mx-2 ${
            props.tab === 3 ? 'bg-text-9' : 'bg-text-10'
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            style={{ rotate: '90deg' }}
          >
            <rect transform="rotate(90)" />
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M21 6H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10H3V8h2v4h2V8h2v4h2V8h2v4h2V8h2v4h2V8h2v8z" />
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 3 && 'text-text-9 font-bold'}`}>
          {translate('gro_measure')}
        </p>
      </NavLink>
      <NavLink
        to={`/patient/presentation/hair-images/global-images/${props.id}`}
        className="flex items-center bg-background-5 p-1 border-b-2 border-background-6"
      >
        <figure
          className={`rounded-full p-2 mx-2 ${
            props.tab === 2 ? 'bg-text-9' : 'bg-text-10'
          }`}
        >
          <svg
            className="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
          >
            <path d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z" />
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 2 && 'text-text-9 font-bold'}`}>
          {translate('gro_images')}
        </p>
      </NavLink>
    </nav>
  );
}
