import { React, useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import { useTranslation } from '../contexts/TranslationContext';

export default function GroupSearch() {
  const { id } = useParams();
  const { translate } = useTranslation()

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [race, setRace] = useState('');
  const [prpCheck, setPrpCheck] = useState(false);
  const [exosomeCheck, setExosomeCheck] = useState(false);
  const [transplantCheck, setTransplantCheck] = useState(false);
  const [prp, setPrp] = useState({ brand: '', protocol: '' });
  const [exosome, setExosome] = useState({ brand: '', protocol: '' });
  const [transplant, setTransplant] = useState({ area: '', grafts: '' });
  const [criteria, setCriteria] = useState([]);
  const [groIndex, setGroIndex] = useState(null);
  const [hairThickness, setHairThickness] = useState(null);
  const [hairDensity, setHairDensity] = useState(null);
  const [showHairThickness, setShowHairThickness] = useState(true);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const findMatch = () => {
    setCriteria(['Male', '40-50', 'PRP', 'Selphyl', '3 Months']);
    setGroIndex({
      labels: ['02-17-20', '', '', '', '', '', '08-17-20'],
      datasets: [
        {
          backgroundColor: '#F7B7AFb3',
          data: [0, 25, 35, 45, 55, 50, 86],
        },
      ],
    });
    setHairThickness({
      labels: ['02-17-20', '', '', '', '', '', '08-17-20'],
      datasets: [
        {
          backgroundColor: '#3DE2D2b3',
          data: [0, 0.054, 0.066, 0.071, 0.084, 0.093, 0.098],
        },
      ],
    });
    setHairDensity({
      labels: ['02-17-20', '', '', '', '', '', '08-17-20'],
      datasets: [
        {
          backgroundColor: '#3DE2D2b3',
          data: [0, 15, 19, 18, 21, 24, 26],
        },
      ],
    });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          setMessage(response.message);
        }
      })
      .finally(() => {
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={7} />
        <section className="mt-6">
          {message && <p className="text-center text-red-600">{message}</p>}
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
            <NavLink
              exact
              to={`/patient/search/individual-match/${id}`}
              activeClassName="text-text-2"
            >
              {translate('individual_match')}
            </NavLink>
            <div className="mx-8 my-2 w-2 h-2 bg-background-23 rounded-full"></div>
            <NavLink
              exact
              to={`/patient/search/group-search/${id}`}
              activeClassName="text-text-2"
            >
              {translate('group_search')}
            </NavLink>
          </div>
          <div className="mt-4 grid gap-4 xl:grid-cols-2">
            <div
              className="bg-background-5 py-8 px-20 flex flex-col"
              style={{ height: 'var(--height-1)', overflowY: 'auto' }}
            >
              <h1 className="text-xl text-center text-text-2">
                {translate('search_criteria')}
              </h1>
              <form
                onSubmit={event => {
                  event.preventDefault();
                }}
                className="mt-8 mx-2 flex flex-col font-normal font-font-2 text-text-11"
              >
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <p>{translate('gender')}</p>
                    <select
                      className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                      value={gender}
                      onChange={event => {
                        setGender(event.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option>{translate('male')}</option>
                      <option>{translate('female')}</option>
                    </select>
                  </div>
                  <div className="flex items-center">
                    <p>{translate('age')}</p>
                    <select
                      className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                      value={age}
                      onChange={event => {
                        setAge(event.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option>10-20</option>
                      <option>20-30</option>
                      <option>30-40</option>
                      <option>40-50</option>
                      <option>50-60</option>
                      <option>60-70</option>
                      <option>70-80</option>
                      <option>80-90</option>
                    </select>
                  </div>
                  <div className="flex items-center">
                    <p>{translate('race')}</p>
                    <select
                      className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                      value={race}
                      onChange={event => {
                        setRace(event.target.value);
                      }}
                    >
                      <option value=""></option>
                      <option>{translate('white')}</option>
                      <option>{translate('black')}</option>
                    </select>
                  </div>
                </div>
                <div className="mt-8 pt-8 border-t border-border-1 flex flex-col">
                  <label className="mr-auto flex items-center">
                    <input
                      type="checkbox"
                      className="hidden"
                      onChange={() => {
                        setPrpCheck(!prpCheck);
                        setPrp({ brand: '', protocol: '' });
                      }}
                    />
                    <div
                      className={`w-6 h-6 ${
                        prpCheck ? 'bg-background-15' : 'bg-background-14'
                      }`}
                    ></div>
                    <p className="ml-6">{translate('prp')}</p>
                  </label>
                  <div className="mt-5 grid grid-cols-2 gap-12">
                    <div className="ml-auto flex items-center">
                      <p>{translate('brand')}</p>
                      <select
                        disabled={!prpCheck}
                        className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                        value={prp.brand}
                        onChange={event => {
                          setPrp({ ...prp, brand: event.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option>{translate('selphyl')}</option>
                        <option>{translate('eclipse')}</option>
                      </select>
                    </div>
                    <div className="ml-auto flex items-center">
                      <p>{translate('protocol')}</p>
                      <select
                        disabled={!prpCheck}
                        className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                        value={prp.protocol}
                        onChange={event => {
                          setPrp({ ...prp, protocol: event.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option>{translate('3_months')}</option>
                        <option>{translate('6_months')}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-8 pt-8 border-t border-border-1 flex flex-col">
                  <label className="mr-auto flex items-center">
                    <input
                      type="checkbox"
                      className="hidden"
                      onChange={() => {
                        setExosomeCheck(!exosomeCheck);
                        setExosome({ brand: '', protocol: '' });
                      }}
                    />
                    <div
                      className={`w-6 h-6 ${
                        exosomeCheck ? 'bg-background-15' : 'bg-background-14'
                      }`}
                    ></div>
                    <p className="ml-6">{translate('exosome')}</p>
                  </label>
                  <div className="mt-5 grid grid-cols-2 gap-12">
                    <div className="ml-auto flex items-center">
                      <p>{translate('brand')}</p>
                      <select
                        disabled={!exosomeCheck}
                        className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                        value={exosome.brand}
                        onChange={event => {
                          setExosome({ ...exosome, brand: event.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option>{translate('selphyl')}</option>
                        <option>{translate('eclipse')}</option>
                      </select>
                    </div>
                    <div className="ml-auto flex items-center">
                      <p>{translate('protocol')}</p>
                      <select
                        disabled={!exosomeCheck}
                        className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                        value={exosome.protocol}
                        onChange={event => {
                          setExosome({
                            ...exosome,
                            protocol: event.target.value,
                          });
                        }}
                      >
                        <option value=""></option>
                        <option>{translate('3_months')}</option>
                        <option>{translate('6_months')}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-8 pt-8 border-t border-border-1 flex flex-col">
                  <label className="mr-auto flex items-center">
                    <input
                      type="checkbox"
                      className="hidden"
                      onChange={() => {
                        setTransplantCheck(!transplantCheck);
                        setTransplant({ area: '', grafts: '' });
                      }}
                    />
                    <div
                      className={`w-6 h-6 ${
                        transplantCheck
                          ? 'bg-background-15'
                          : 'bg-background-14'
                      }`}
                    ></div>
                    <p className="ml-6">{translate('transplant')}</p>
                  </label>
                  <div className="mt-5 grid grid-cols-2 gap-12">
                    <div className="ml-auto flex items-center">
                      <p>{translate('area')}</p>
                      <select
                        disabled={!transplantCheck}
                        className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                        value={transplant.area}
                        onChange={event => {
                          setTransplant({
                            ...transplant,
                            area: event.target.value,
                          });
                        }}
                      >
                        <option value=""></option>
                        <option>{translate('front')}</option>
                        <option>{translate('crown')}</option>
                        <option>{translate('vertex')}</option>
                        <option>{translate('back')}</option>
                        <option>{translate('left')}</option>
                        <option>{translate('right')}</option>
                      </select>
                    </div>
                    <div className="ml-auto flex items-center">
                      <p>{translate('grafts')}</p>
                      <select
                        disabled={!transplantCheck}
                        className="ml-4 text-text-14 bg-background-17 focus:outline-none"
                        value={transplant.grafts}
                        onChange={event => {
                          setTransplant({
                            ...transplant,
                            grafts: event.target.value,
                          });
                        }}
                      >
                        <option value=""></option>
                        <option>{translate('3_months')}</option>
                        <option>{translate('6_months')}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  className="mt-16 mx-auto bg-background-15 text-white font-bold focus:outline-none"
                  style={{
                    width: '187px',
                    height: '33px',
                    borderRadius: '6px',
                  }}
                  onClick={findMatch}
                >
                  {translate('search')}
                </button>
              </form>
            </div>
            <div
              className="bg-background-5 py-8 px-32 flex flex-col font-normal font-font-2 text-text-11"
              style={{ height: 'var(--height-1)', overflowY: 'auto' }}
            >
              <h1 className="font-bold font-font-1 text-xl text-center text-text-2">
                {translate('group_attributes')}
              </h1>
              {criteria.length > 0 && (
                <p className="mt-4 text-center">{criteria.join(' / ')}</p>
              )}
              {groIndex && (
                <>
                  <p className="mt-8 mb-4 text-center text-text-7">{translate('gro_index')}</p>
                  <Bar
                    data={groIndex}
                    legend={{
                      display: false,
                    }}
                    options={{
                      maintainAspectRatio: true,
                    }}
                  />
                </>
              )}
              {hairThickness && hairDensity && (
                <>
                  <div className="my-4 text-center text-text-7 flex items-end justify-between">
                    <p className="text-sm text-text-8">
                      {showHairThickness ? '(mm)' : '(/cm2)'}
                    </p>
                    <button
                      className={`${
                        showHairThickness ? 'text-text-7' : 'text-text-8'
                      } focus:outline-none`}
                      onClick={() => {
                        setShowHairThickness(true);
                      }}
                    >
                      {translate('hair_thickness')}
                    </button>
                    <button
                      className={`${
                        !showHairThickness ? 'text-text-7' : 'text-text-8'
                      } focus:outline-none`}
                      onClick={() => {
                        setShowHairThickness(false);
                      }}
                    >
                      {translate('hair_density')}
                    </button>
                  </div>
                  <div className={!showHairThickness && 'hidden'}>
                    <Bar
                      data={hairThickness}
                      legend={{
                        display: false,
                      }}
                      options={{
                        maintainAspectRatio: true,
                      }}
                    />
                  </div>
                  <div className={showHairThickness && 'hidden'}>
                    <Bar
                      data={hairDensity}
                      legend={{
                        display: false,
                      }}
                      options={{
                        maintainAspectRatio: true,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </main>
      <Loading message={translate('loading_treatment_plan')} ready={ready} />
    </>
  );
}
