import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../views/Loading";
import PatientNavbar from "../components/PatientNavbar";
import PatientSidebar from "../components/PatientSidebar";
import LessThanIcon from "../assets/images/lessThanIcon.svg";
import Iframe from 'react-iframe';

import "react-datepicker/dist/react-datepicker.css";
import { useUserContext } from "../contexts/userContext";
import { useTranslation } from "../contexts/TranslationContext";

export default function PatientConsent() {
    const { id } = useParams();
    const [examDrop, setExamDrop] = useState(false);
    const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
    const [healthDropdown, setHealthDropdown] = useState(false);
    const [message, setMessage] = useState("");
    const [ready, setReady] = useState(true);
    const [patientReferrals, setPatientReferrals] = useState(null);
    const [documents, setDocuments] = useState({});
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showSignForm, setShowSignForm] = useState(false);
    const {isSalonApp} = useUserContext()
    const { translate } = useTranslation();

    useEffect(() => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    setPatientReferrals(response);
                } else {
                    setMessage(response.message);
                }
            })
            .catch(error => {
                console.error(error);
                setMessage(translate('error_occurred'));
            })
            .finally(() => {
                setReady(true);
            });
    }, [id]);

    const download = (doc) => {
        window.open(doc, '_blank', 'download');
    }

    const view = (name, link) => {
        setSelectedDocument([name, link]);
    }

    const hide = () => {
        setSelectedDocument(null);
    }

    return (
        <>
            <main
                className={`mx-auto min-h-screen container flex flex-col p-5 font-font-2 ${ready ? "" : "hidden"
                    }`}
            >
                <div className="mx-10 border-b-2 border-gray-700 pb-5">
                    <PatientNavbar id={id} tab={1} />
                </div>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                <section className="flex px-5">
                    <PatientSidebar
                        examDrop={examDrop}
                        setExamDrop={setExamDrop}
                        historyDrop={healthHistoryDrop}
                        setHistoryDrop={setHealthHistoryDrop}
                        healthDrop={healthDropdown}
                        setHealthDrop={setHealthDropdown}
                        id={id}
                    />
                    <div className="m-auto w-full">
                        {patientReferrals && (
                            <div className="mx-auto max-w-5xl mt-12 font-font-2 tracking-wide">
                                {patientReferrals?.referred_by ? (
                                    <h1 className="flex justify-center text-lg text-center text-text-3 font-bold">
                                        {`${patientReferrals.firstName} ${patientReferrals.lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                                    </h1>
                                ) : (
                                    <h1 className="flex justify-center text-lg text-center text-text-3 font-bold">
                                        {`${patientReferrals.firstName} ${patientReferrals.lastName}`}
                                    </h1>
                                )}
                            </div>
                        )}
                        {!selectedDocument ?
                            (
                                <div className="mx-auto max-w-5xl mt-12 font-font-2">
                                    <div className="mt-12 mx-20 mb-24">
                                        <div className="flex justify-center">
                                            <h1 className="text-2xl text-text-2 font-font-2 font-bold text-center tracking-widest">
                                            {isSalonApp ? "Client":  "Patient"} {translate('consent_form')}
                                            </h1>
                                        </div>
                                        <div className="grid grid-cols-2 mt-8 gap-32 mb-64">
                                            <div className="w-full text-center mb-4 bg-white opacity-90">
                                                <h1 className="font-bold border-b-2 text-lg text-lg border-text-1 text-black py-2">{translate('alma_ted_consent_form')}</h1>
                                                <div className="flex justify-between px-12 py-4 items-center">
                                                    <svg onClick={() => view('ALMA TED CONSENT', documents?.user_agreement_pdf)} className="w-7 h-7 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16"> <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" /> <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" /> </svg>
                                                    <svg onClick={() => download(documents?.user_agreement_pdf)} class="w-10 h-6 text-text-1 cursor-pointer" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                                    </svg>
                                                    <svg class="w-10 h-7 text-text-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 24 24">
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path fill="currentColor" d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" />
                                                    </svg>
                                                    <div>
                                                        <div className="w-fit py-2 px-8 text-xs font-normal text-text-3 rounded-md" style={{ background: '#EF5656' }}>{translate('sign')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full text-center mb-4 bg-white opacity-90">
                                                <h1 className="font-bold border-b-2 text-lg border-text-1 text-black py-2">{translate('rpr_title')}</h1>
                                                <div className="flex justify-between px-12 py-4 items-center">
                                                    <svg onClick={() => view('ALMA TED CONSENT', documents?.user_agreement_pdf)} className="w-7 h-7 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 16 16"> <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" /> <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" /> </svg>
                                                    <svg onClick={() => download(documents?.hipaa_agreement_pdf)} class="w-10 h-6 cursor-pointer text-text-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                                    </svg>
                                                    <svg class="w-10 h-7 text-text-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 24 24">
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path fill="currentColor" d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" />
                                                    </svg>
                                                    <div className="w-fit py-2 px-8 text-xs font-normal text-text-3 rounded-md bg-background-20">SIGNED</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : (
                                <div className="w-full p-4">
                                    <div className="flex px-12 py-4 m-auto mb-2 items-center justify-end" style={{ width: '80%' }}>
                                        <div className="flex items-center justify-between" style={{ width: '75%' }}>
                                            <img onClick={hide} className="w-8 h-8 cursor-pointer" src={LessThanIcon} alt="icon" />
                                            <div className="text-2xl text-text-3 mx-6 tracking-wider">{selectedDocument[0]}</div>
                                            <svg class="w-10 h-7 text-text-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                            </svg>
                                            <svg class="w-10 h-8 text-text-2" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 24 24">
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path fill="currentColor" d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" />
                                            </svg>
                                            <div onClick={() => setShowSignForm(!showSignForm)} className="w-fit py-2 px-10 ml-6 text-xs font-normal text-text-3 rounded-md cursor-pointer font-bold tracking-wider" style={{ background: '#EF5656' }}>{showSignForm ? 'CANCEL' : 'SIGN'}</div>
                                        </div>
                                    </div>
                                    <div>
                                        {!showSignForm ? (
                                            <Iframe className="m-auto" url='https://grotrack.online/staging/backend/public/storage/agreement_pdf/0804202305173064cc89ea9f1be.pdf'
                                                width="80%"
                                                height="570px"
                                                display="block"
                                            />
                                        ) : (
                                            <div className="m-auto bg-text-2 flex flex-col justify-center items-center" style={{ width: '80%', height: '570px' }}>
                                                <textarea className="w-8/12 h-40 bg-text-3 focus:outline-none input-shadow" />
                                                <div className="w-8/12 flex mt-4 justify-between h-8">
                                                    <input className="bg-text-3 w-5/12 focus:outline-none input-shadow" />
                                                    <input className="bg-text-3 w-1/4 focus:outline-none input-shadow" />
                                                    <input className="bg-text-3 w-1/4 focus:outline-none input-shadow" />
                                                </div>
                                                <div className="bg-background-23 mt-6 w-fit py-2 px-14 text-xs font-normal text-text-3 rounded-md tracking-wider">
                                                    {translate('confirm')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                    </div>
                </section>
            </main>
            <Loading message={`${translate('loading')} ${isSalonApp ? translate('client'):  translate('patient')} ${translate('details')}`} ready={ready} />
        </>
    );
}