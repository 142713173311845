import { React, useState, useEffect, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
// import dayjs from 'dayjs';
import {IoIosArrowBack,IoIosArrowForward} from 'react-icons/io'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProcedureTypes from '../components/ProcedureTypes';
import ProductTypes from '../components/ProductTypes';
// import TreatmentPlan from './TreatmentPlan';
import {GoPrimitiveDot} from 'react-icons/go'
import ProtocolForm from './ProtocolForm';
import ClinicalNotes from './ClinicalNotes';
import ConsentForm from '../components/ConsentForm'
import ViewProcedure from '../components/ViewProcedure';
import ViewMedication from '../components/ViewMedication';
import ViewProduct from '../components/ViewProduct';
import InfoForm from '../components/InfoForm';
import ProductConsentForm from '../components/ProductConsentForm'
import { useTranslation } from "../contexts/TranslationContext";

export default function TodaysTreatment() {
  const ref = useRef()
  const { id } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [editMode, setEditMode] = useState(false);
  const [treatmentPlan, setTreatmentPlan] = useState(null);
  // const [clinicalNotes, setClinicalNotes] = useState([]);
  // const [editClinicNote, setEditClinicNote] = useState([
  //   clinicalNotesBody => '',
  //   clinicalNotesSignature => '',
  //   clinicalNotesDate => '',
  //   patient_id => id,
  // ]);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const { translate } = useTranslation();

  const [showArrows, setShowArrows] = useState(true)
  const [activeTab,setActiveTab] = useState('Procedures')
  const [openGroPlan,setOpenGroPlan] = useState(false)

  const [openProcedureForm,setOpenProcedureForm] = useState({open:false, id:null})
  const [openNotes,setOpenNotes] = useState({open:false, id:null})
  const [consent,setConsent] = useState({open:false, id:null})

  const [openProductNotes,setOpenProductNotes] = useState({open:false, id:null})
  const [openInfo,setOpenInfo] = useState({open:false, id:null})

  const [filterType,setFilterType] = useState('All')

  //const [showConsent, setShowConsent] = useState(false);
  //const [procedureTemplates, setprocedureTemplates] = useState([]);
  //const [productTemplates, setproductTemplates] = useState([]);
  //const [medicationTemplates, setMedicationTemplates] = useState([]);
  const [hasConsent, sethasConsent] = useState(false);
  //const [modalConsent, setModalConsent] = useState(null);

  const [groCount,SetGroCount] = useState(0)

  useEffect(() => {
    if(activeTab === 'Procedures'){
      setShowArrows(false);
    }else{
      setShowArrows(false);
    }
  },[activeTab])



  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    // other code
    getTreatmentPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openGroPlan,id,filterType]);

  const getTreatmentPlans = () => {
      setReady(false)
    fetch(`${process.env.REACT_APP_API}/patient/${id}/show_treatment_plans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify({status: filterType})
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          console.log(response.treatmentPlans)
          setTreatmentPlan(response.treatmentPlans)
          SetGroCount(
            response.treatmentPlans.procedures.length +
            response.treatmentPlans.medications.length +
            response.treatmentPlans.products.length
          )
          
          setReady(true)
        } 
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }

  // const saveClinicalNotes = () => {
  //   var body = editClinicNote;
  //   body['patient_id'] = id;
  //   setReady(false);
  //   fetch(`${process.env.REACT_APP_API}/clinic/clinical-notes`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //     body: JSON.stringify(body),
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.success) {
  //         if (
  //           dayjs(editClinicNote.clinicalNotesDate).format('MM-DD-YY') ===
  //           dayjs().format('MM-DD-YY')
  //         ) {
  //           var clinicalNotesTemp = clinicalNotes;
  //           clinicalNotesTemp[clinicalNotesTemp.length] = response.clinicNote;
  //           setClinicalNotes(clinicalNotesTemp);
  //         }
  //         setEditClinicNote([
  //           clinicalNotesBody => '',
  //           clinicalNotesSignature => '',
  //           clinicalNotesDate => '',
  //         ]);
  //         setEditMode(false);
  //       } else {
  //         setMessage(response.message);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setMessage(translate('error_occurred'));
  //     })
  //     .finally(() => {
  //       setReady(true);
  //     });
  // };

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API}/patient/${id}/todays_treatment_plan`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.success) {
  //         setFirstName(response.firstName);
  //         setLastName(response.lastName);
  //         setTreatmentPlan(response.treatmentPlans);
  //         setClinicalNotes(response.clinicNotes);
  //       } else {
  //         setMessage(response.message);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setMessage(translate('error_occurred'));
  //     })
  //     .finally(() => {
  //       setReady(true);
  //     });
  // }, [id]);

  // const getTemplates = () => {
  //   fetch(`${process.env.REACT_APP_API}/clinic/templates`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.success) {
  //         setprocedureTemplates(response.procedures);
  //         setMedicationTemplates(response.medications);
  //         setproductTemplates(response.products);
  //       } else {
  //         setMessage(response.message);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setMessage(
  //         'Unable to load Treatment Templates. Please Try Again Later'
  //       );
  //     });
  //   // .finally(() => {
  //   //   setReady(true);
  //   // });
  // };

  async function fetchdraftTreatment() {
    const res = await fetch(
      `${process.env.REACT_APP_API}/patient/${id}/fetchdraftTreatment`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        // body: JSON.stringify({
        //   change,
        // }),
      }
    ).then(response => response.json());

    return res.draftTreatmentPlans;
  }

  const changePriority = async (type, index, change) => {
    if (
      (change === -1 && index > 0) ||
      (change === 1 && index < treatmentPlan[type].length - 1)
    ) {
      const draftTreatmentId = await fetchdraftTreatment();

      fetch(
        `${process.env.REACT_APP_API}/treatment_plan/${draftTreatmentId}/${treatmentPlan[type][index].id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify({
            change,
          }),
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            const treatments = [...treatmentPlan[type]];
            const treatment = treatments[index];
            // treatments[index].priority = treatments[index].priority + change;
            // treatments[index + change].priority = treatments[index + change].priority - change;
            treatments[index] = treatments[index + change];
            treatments[index + change] = treatment;

            setTreatmentPlan({ ...treatmentPlan, [type]: treatments });
            // console.log(treatments)
          } else {
            setMessage(response.message);
          }
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
        });
    }
  };
  const setstate=()=>{
        setReady(!ready);
  }

  const duplicate = (sid, type) => {
    const plan = treatmentPlan[type].filter(plan => plan.id === sid)[0];
    setTreatmentPlan({
      [type]: treatmentPlan[type].push({
        // id: `draft-${Math.floor(Math.random() * 100 + 1)}`,
        ...plan,
      }),
      ...treatmentPlan,
    });
    // saveTreatmentPlan();
    // fetchTreatmentPlan(id);
  };
  const saveTreatment = treatment => {
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/treatment_plan/${id}/${treatment.Tid}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(treatment),
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          fetchTreatmentPlan(id);
        } else {
          setMessage(response.message);
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  const deleteTreatment = (sid, type, index) => {
    if (!sid) {
      let newPlans = [...treatmentPlan[type]];
      newPlans.splice(index, 1);
      setTreatmentPlan({
        ...treatmentPlan,
        [type]: newPlans,
      });
      return;
    }
    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/treatment_plan/${treatmentPlan.id}/${sid}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          fetchTreatmentPlan(id);
        } else {
          const plans = treatmentPlan[type].filter(plan => plan.id !== sid);
          setTreatmentPlan({
            ...treatmentPlan,
            [type]: plans,
          });
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  const fetchTreatmentPlan = (id, isNew) => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          // console.log(response)
          // setFirstName(response.firstName);
          // setLastName(response.lastName);
          if (!isNew && response.treatmentPlans[0]) {
            setTreatmentPlan(response.treatmentPlans[0]);
            sethasConsent(true);
          } else {
            setTreatmentPlan({
              procedures: [],
              medications: [],
              products: [],
              fee: '',
              // consentForm: "",
              // date: "",
              // signature: "",
              clinicalNotesBody: '',
              clinicalNotesSignature: '',
              clinicalNotesDate: '',
            });
          }
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  };


//   const taxAreaOptions = [
//     'Frontal',
//     'Right temporal',
//     'Crown',
//     'Left temporal',
//     'Vertex',
//     'Occipital'
// ]

  const prevSlide = () => {
    ref.current.slickPrev()
  }

  const nextSlide = () => {
    ref.current.slickNext()
  }

  const filters = [
    'All',
    'Completed',
    'Scheduled',
    'Pending'
  ]

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? "" : "hidden"
        }`}
      >
        <PatientNavbar id={id} tab={2} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}

        {openGroPlan ? (
          <section>
            {patientReferrals ? (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
              </h1>
            ) : (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName}`}
              </h1>
            )}
            <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
              <NavLink
                exact
                to={`/patient/treatment-plan/view/${id}`}
                activeClassName="text-text-2"
              >
                {translate('gro_plan')}
              </NavLink>
            </div>

            <div
              style={{
                width: "40%",
                margin: "0 auto",
                marginTop: "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {filters.map((item) => {
                return (
                  <div
                    onClick={() => setFilterType(item)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <GoPrimitiveDot
                      style={
                        filterType === item
                          ? {
                              fontSize: "20px",
                              marginRight: "0.2rem",
                              color: "#13C4B3",
                            }
                          : {
                              fontSize: "20px",
                              marginRight: "0.2rem",
                              color: "white",
                            }
                      }
                    />
                    <div>{item}</div>
                  </div>
                );
              })}
            </div>

            {treatmentPlan ? (
              <div
                className="mt-4 p-4 lg:px-20 flex flex-col"
                // style={{ height: 'var(--height-1)' }}
              >
                <div className="mt-6 px-20 flex justify-between text-lg text-text-2">
                  <h2>
                    {translate('procedures')}&nbsp;&nbsp;
                    <button
                      onClick={() => {
                        // const tp = {
                        //   type: '',
                        //   brand: '',
                        //   tx: '',
                        //   interval: '',
                        //   area: '',
                        //   fee: '',
                        //   Tid: '',
                        // };

                        // setTreatmentPlan({
                        //   procedures: treatmentPlan.procedures.push(tp),
                        //   ...treatmentPlan,
                        // });
                        setOpenGroPlan(!openGroPlan);
                        setActiveTab("Procedures");
                      }}
                    >
                      +
                    </button>
                  </h2>
                  {/* <h2>Status</h2> */}
                </div>

                {treatmentPlan &&
                  treatmentPlan.procedures &&
                  treatmentPlan.procedures.length > 0 && (
                    <>
                      {/* <div className="mt-4 grid grid-cols-12 justify-items-center font-font-2 font-normal text-sm">
                    <h2 className="px-2">Priority</h2>
                    <div></div>
                    <h2 className="px-2">Brand</h2>
                    <h2 className="px-2"># Tx</h2>
                    <h2 className="px-2">Interval</h2>
                    <h2 className="px-2">Area</h2>
                    <h2 className="px-2">Fee</h2>
                    <h2 className="px-2">Edit</h2>
                    <h2 className="px-2">Consent</h2>
                    <h2 className="px-2">Pay</h2>
                    <h2 className="px-2">Schedule</h2>
                    <h2 className="px-2">Complete</h2>
                  </div> */}
                  {treatmentPlan.procedures.map((procedure, index) => {
                    return (
                      <>
                      <ViewProcedure
                        key={index}
                        index={index}
                        openProcedureForm={openProcedureForm}
                        setOpenProcedureForm={setOpenProcedureForm}
                        openNotes={openNotes}
                        ready={ready}
                        setReady={setReady}
                        setstate={setstate}
                        setOpenNotes={setOpenNotes}
                        consent={consent}
                        setConsent={setConsent}
                        getTreatmentPlans={getTreatmentPlans}
                        procedure={procedure}
                        setProcedure={data => {
                          let procedure = treatmentPlan.procedures;
                          procedure[index] = data;
                          setTreatmentPlan({
                            procedures: procedure,
                            ...treatmentPlan,
                          });
                        }}

                        changePriority={changePriority}
                        saveTreatment={saveTreatment}
                        deleteTreatment={deleteTreatment}
                        //setShowConsent={setShowConsent}
                        //templateProcedures={procedureTemplates}
                        duplicateTreatment={duplicate}
                        has_consent={hasConsent}
                        //setModalConsent={setModalConsent}
                      />
                      {
                        openProcedureForm.open && openProcedureForm.id===procedure.id &&
                        <ProtocolForm
                        openGroPlan={openGroPlan}
                        procedure={procedure}
                        setProcedure={data => {
                          let procedure = treatmentPlan.procedures;
                          procedure[index] = data;
                          setTreatmentPlan({
                            procedures: procedure,
                            ...treatmentPlan,
                          });
                        }}
                         />
                      }
    
                      {
                        openNotes.open && openNotes.id===procedure.id &&
                        <ClinicalNotes procedure={procedure} getTreatmentPlans={getTreatmentPlans}  />
                      }

                      {
                        consent.open && consent.id===procedure.id &&
                        <ConsentForm procedure={procedure} getTreatmentPlans={getTreatmentPlans}  setstate={setstate}
                        />
                      }
                      </>
                    );
                  })}
                </>
              )}

              <h2 className="mt-6 px-20 flex justify-between text-lg text-text-2">
                <span>
                  {translate('medications')}&nbsp;&nbsp;
                  <button
                    onClick={() => {
                      const mp = {
                        brand: '',
                        dosage: '',
                        number: '',
                        refill: '',
                        fee: '',
                        Tid: '',
                      };

                      setTreatmentPlan({
                        medications: treatmentPlan.medications.push(mp),
                        ...treatmentPlan,
                      });
                    }}
                  >
                    +
                  </button>
                </span>
              </h2>
              {treatmentPlan && treatmentPlan.medications && treatmentPlan.medications.length > 0 && (
                <>
                  <div className="mt-4 grid grid-cols-12 justify-items-center font-font-2 font-normal text-sm">
                    <h2 className="px-2">{translate('priority')}</h2>
                    <div></div>
                    <h2 className="px-2">{translate('brand')}</h2>
                    <h2 className="px-2">{translate('dosage')}</h2>
                    <h2 className="px-2">#</h2>
                    <h2 className="px-2">{translate('refill')}</h2>
                    <h2 className="px-2">{translate('fee')}</h2>
                    <div></div>
                    <h2 className="px-2">{translate('consent')}</h2>
                    <h2 className="px-2">{translate('pay')}</h2>
                    <h2 className="px-2">{translate('rx')}</h2>
                  </div>
                  {treatmentPlan.medications.map((medication, index) => {
                    return (
                      <ViewMedication
                        key={index}
                        index={index}
                        medication={medication}
                        changePriority={changePriority}
                        saveTreatment={saveTreatment}
                        deleteTreatment={deleteTreatment}
                        //setShowConsent={setShowConsent}
                        has_consent={hasConsent}
                        //templateMedications={medicationTemplates}
                        setMedication={data => {
                          let medication = treatmentPlan.medications;
                          medication[index] = data;
                          setTreatmentPlan({
                            medications: medication,
                            ...treatmentPlan,
                          });
                        }}
                        duplicateTreatment={duplicate}
                        //setModalConsent={setModalConsent}
                      />
                    );
                  })}
                </>
              )}
              <h2 className="mt-6 px-20 flex justify-between text-lg text-text-2">
                <span>
                {translate('products')}&nbsp;&nbsp;
                  <button
                    onClick={() => {
                      const pp = {
                        brand: '',
                        volume: '',
                        number: '',
                        refill: '',
                        fee: '',
                        delivery: '',
                        Tid: '',
                      };
                      setTreatmentPlan({
                        products: treatmentPlan.products.push(pp),
                        ...treatmentPlan,
                      });
                    }}
                  >
                    +
                  </button>
                </span>
              </h2>
              {treatmentPlan && treatmentPlan.products && treatmentPlan.products.length > 0 && (
                <>
                  {/* <div className="mt-4 grid grid-cols-12 justify-items-center font-font-2 font-normal text-sm">
                    <h2 className="px-2">Priority</h2>
                    <div></div>
                    <h2 className="px-2">Brand</h2>
                    <h2 className="px-2">Volume</h2>
                    <h2 className="px-2">#</h2>
                    <h2 className="px-2">Refill</h2>
                    <h2 className="px-2">Fee</h2>
                    <div></div>
                    <h2 className="px-2">Consent</h2>
                    <h2 className="px-2">Pay</h2>
                    <h2 className="px-2">Shipping</h2>
                  </div> */}
                  {treatmentPlan.products.map((product, index) => {
                    return (
                      <>
                      <ViewProduct
                        key={index}
                        index={index}
                        product={product}
                        changePriority={changePriority} 
                        saveTreatment={saveTreatment}
                        deleteTreatment={deleteTreatment}
                        //setShowConsent={setShowConsent}
                        getTreatmentPlans={getTreatmentPlans}
                        consent={consent}
                        setConsent={setConsent}
                        setstate={setstate}
                        //setModalConsent={setModalConsent}
                        //templateProducts={productTemplates}
                        has_consent={hasConsent}
                        duplicateTreatment={duplicate}
                        setProduct={data => {
                          let product = treatmentPlan.products;
                          product[index] = data;
                          setTreatmentPlan({
                            products: product,
                            ...treatmentPlan,
                          });
                        }}

                        openInfo={openInfo}
                        setOpenInfo={setOpenInfo}
                        openProductNotes={openProductNotes}
                        setOpenProductNotes={setOpenProductNotes}
                        // setModalConsent={setModalConsent}
                      />

                      {
                        openInfo.open && openInfo.id===product.id &&
                        <InfoForm product={product}/>
                      }

                      {
                        openProductNotes.open && openProductNotes.id===product.id &&
                        <ClinicalNotes procedure={product} getTreatmentPlans={getTreatmentPlans} />
                      }
                       {
                        consent.open && consent.id===product.id &&
                        <ProductConsentForm procedure={product} getTreatmentPlans={getTreatmentPlans} setstate={setstate} />
                      }
                      </>
                    );
                  })}
                </>
              )}
              {/* <>
              <div className="mx-auto mt-16 text-text-2">
                <h3 className="border-b-2 border-text-1">
                  Total Fees: $
                  {Number(getTotalFee()).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </h3>
              </div>
              <button
                className="mt-10 mx-auto bg-background-15 text-white font-bold focus:outline-none"
                style={{
                  width: '187px',
                  height: '33px',
                  borderRadius: '6px',
                }}
                onClick={() => {
                  addTreatmentPlan();
                }}
              >
                Save
              </button>
              </> */}
              </div>
            ) : (
              <h2 className="mt-8 text-center">{translate('no_treatment_plans_yet')}</h2>
            )}
          </section>
        ) : (
          <section>
            {/* {patientReferrals ? (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
              </h1>
            ) : ( */}
              <h1 className='px-12 mt-8' style={{ 
                textAlign: 'center',
                font: 'normal normal bold 20px/21px Arial',
                letterSpacing: '1.6px',
                color: '#FFFFFF',
                opacity: '0.7',
               }}>
                {`${firstName} ${lastName}`}
              </h1>
            {/* )} */}

            <div
              style={{
                textAlign: "center",
                position: "relative",
                width: "80%",
                margin: "0 auto",
                marginTop: "1rem",
                fontSize: "1.2rem",
              }}
            >
              <div style={{ 
                textAlign: 'center',
                font: 'normal normal bold 25px/15px Arial',
                letterSpacing:' 1.25px',
                color: '#F2F6F7',
                opacity: '0.7',
               }}>GRO solutions</div>
              {/* <div
                onClick={() => setOpenGroPlan(!openGroPlan)}
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    borderTop: "3px solid #F6F7FA",
                    marginBottom: "0.3rem",
                  }}
                ></div>
                <div
                  style={{
                    color: "orange",
                    border: "3px solid #F6F7FA",
                    padding: "0.3rem",
                  }}
                >
                  {groCount}
                </div>
              </div> */}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
                margin: "2rem auto",
                marginBottom: "1rem",
              }}
            >
              <div
                onClick={() => setActiveTab("Procedures")}
                style={
                  activeTab === "Procedures"
                    ? { color: "#7CD8EF", opacity: 0.8, letterSpacing: '0.8px'  }
                    : { cursor: "pointer", opacity: 0.6, color: '#F2F6F7', letterSpacing: '0.8px' }
                }
              >
                {translate('procedures')}
              </div>
              <div
                onClick={() => setActiveTab("Medication")}
                style={
                  activeTab === "Medication"
                    ? { color: "#7CD8EF", opacity: 0.8, letterSpacing: '0.8px'  }
                    : { cursor: "pointer", opacity: 0.6, color: '#F2F6F7', letterSpacing: '0.8px' }
                }
              >
                {translate('medication')}
              </div>
              <div
                onClick={() => setActiveTab("Products")}
                style={
                  activeTab === "Products"
                    ? { color: "#7CD8EF", opacity: 0.8, letterSpacing: '0.8px'  }
                    : { cursor: "pointer", opacity: 0.6, color: '#F2F6F7', letterSpacing: '0.8px'  }
                }
              >
                {translate('products')}
              </div>
              <div
                onClick={() => setActiveTab("Programs")}
                style={
                  activeTab === "Programs"
                    ? {
                        color: "#70F4E7",
                        cursor: "pointer",
                        border: "1px solid #70F4E7",
                        borderRadius: "10px",
                        padding: "5px 20px",
                        letterSpacing: '0.8px'
                      }
                    : {
                        cursor: "pointer",
                        border: "1px solid #F2F6F7",
                        borderRadius: "10px",
                        padding: "5px 20px",
                        opacity: 0.6, 
                        color: '#F2F6F7',
                        letterSpacing: '0.8px'
                      }
                }
              >
                {translate('programs')}
              </div>
            </div>

            <div
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0 auto",
              }}
            >
              {showArrows && (
                <IoIosArrowBack
                  onClick={prevSlide}
                  style={{
                    fontSize: "40px",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                />
              )}

              {activeTab === "Procedures" ? (
                <ProcedureTypes
                  getTreatmentPlans={getTreatmentPlans}
                  setOpenGroPlan={setOpenGroPlan}
                  openGroPlan={openGroPlan}
                  setReady={setReady}
                />
              ) : activeTab === "Medication" ? (
                <div>{translate('medication')}</div>
              ) : activeTab === "Products" ? (
                <ProductTypes
                  getTreatmentPlans={getTreatmentPlans}
                  setOpenGroPlan={setOpenGroPlan}
                  openGroPlan={openGroPlan}
                  showArrows={showArrows}
                  setShowArrows={setShowArrows}
                  setReady={setReady}
                />
              ) : (
                <div>{translate('programs')}</div>
              )}
              {showArrows && (
                <IoIosArrowForward
                  onClick={nextSlide}
                  style={{
                    fontSize: "40px",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                />
              )}
            </div>
          </section>
        )}
      </main>
      <Loading message={translate('loading_todays_treatment_plan')} ready={ready} />
    </>
  );
}
