import React, { forwardRef, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { hairThinningUiData } from "../../data/barGraphData";
import ShopifyProductCollection from "./ShopifyProductCollection";
import ShopifyBuy from '@shopify/buy-button-js';
import { useTranslation } from "../../contexts/TranslationContext";

const zenagenShopifyClient = ShopifyBuy.buildClient({
  domain: 'shop-zenagen.myshopify.com',
  storefrontAccessToken: '734540683c4d0dfb47ebe455d39f7bf1',
});

const zenagenUi = ShopifyBuy.UI.init(zenagenShopifyClient);

const hairmaxShopifyClient = ShopifyBuy.buildClient({
  domain: 'tryhairmax.myshopify.com',
  storefrontAccessToken: '241d1db1ebea919969faf41e49ada412',
});

const hairmaxUi = ShopifyBuy.UI.init(hairmaxShopifyClient);

const ProductRecommendations = forwardRef(({ analysis, clientGender, clinicId }, productsRef) => {
  const { translate } = useTranslation();
  const [hairThinningData, setHairThinningData] = useState({});
  const [maxHairThinningLevel, setMaxHairThinningLevel] = useState(0);
  const { mainType, subType } = useParams();
  const location = useLocation()
  
  useEffect(() => {
    if(!analysis) return;

    const thinningItem = Object.values(hairThinningUiData).find((level) =>
      mainType === "closeup"
        ? level.markerKey ===
        analysis?.images?.[mainType].hair_thinning?.[subType]?.label
        : level.markerKey === analysis?.images?.[mainType].hair_thinning?.label
    );
    setHairThinningData(thinningItem);


    // Get hair thinning levels
    const hairThinningLevels = new Set()
    Object.entries(analysis?.images)?.forEach(([key, imageSet], index) => {
      if(key === "closeup" && !imageSet?.hair_thinning) return;

      const hairThinningDataSet = key === "closeup" ?
        Object.values(imageSet?.hair_thinning)?.map((imgSubtype) => imgSubtype?.label) :
        key === "global" ?
          [imageSet?.hair_thinning?.label] : [];

      hairThinningDataSet?.forEach((level) => {
        hairThinningLevels.add(level)
      });
    });

    // Set hair thinning data 
    const maxThinningItemLevel = Array.from(hairThinningLevels).reduce((acc, level) => {
      const item = Object.values(hairThinningUiData).find((item) => item?.markerKey === level);
      const itemLevel = item?.level;
      if(itemLevel > acc) acc = itemLevel;
      return acc;
    }, 0);
    setMaxHairThinningLevel(maxThinningItemLevel)
  }, [analysis, location, mainType, subType]);

  return (
    <>
      <div className="h-24 w-full" style={{ background: "linear-gradient(#061b2e, white)" }}></div>
      <section
        className="min-w-screen bg-white text-center text-black p-4 pb-0"
        style={{ flexGrow: 1 }}
        ref={productsRef}
      >
        <main className="text-center flex flex-col justify-evenly items-center w-full sm:w-3/4 md:w-3/5 lg:w-1/2 mx-auto">
          {hairThinningData?.romanValue ? (
            <>
              <h1 className="text-3xl font-bold capitalize leading-relaxed">
                {"Class " + hairThinningData?.romanValue + " hair loss"}
              </h1>
              {
                hairThinningData?.tooltipHeading && (
                  <h1 className="text-2xl capitalize font-light leading-relaxed">
                    ({hairThinningData?.tooltipHeading})
                  </h1>

                )
              }
            </>
          ) : hairThinningData && (
            <h1 className="text-3xl font-bold capitalize leading-relaxed">
              {translate('normal_healthy')}
            </h1>
          )}
          {hairThinningData && (
            <p
              className="leading-6 font-bold px-6 opacity-70 text-2xl mt-3"
              style={{
                fontFamily: "Roboto",
                fontSize: "1.35rem",
                lineHeight: "1.4rem",
              }}
            >
              {hairThinningData?.percentage ? (
                <>
                 {translate('ai_lost_hair')}{" "}
                  {hairThinningData?.percentage} {translate('percent_of_your_hair_since_its_peak')}
                </>
              ) : (
                <>
                  {translate('ai_healthy_hair')}
                </>
              )}
              <br />
              <br />
              {translate('suggested_solutions')} {hairThinningData?.percentage ? translate('rejuvenated') : ""} {translate('hair')}:
            </p>
          )}
          {clientGender && <h4 className="capitalize font-light text-2xl w-3/4 border-b border-gray-400 mt-10 py-1">{translate('for')} {clientGender}</h4>}
          <ShopifyProductCollection clinicId={clinicId} clientGender={clientGender} zenagenUi={zenagenUi} hairmaxUi={hairmaxUi} hairThinningLevel={maxHairThinningLevel}/>
        </main>
      </section>
    </>
  );
});

export default ProductRecommendations;
