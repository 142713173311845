import React, { useState } from "react";
import { IoIosChatboxes } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import ChatBox from "./components/ChatBox";

const ChatAssistant = () => {
  const [isChatBoxOpen, setIsChatboxOpen] = useState(false);

  const toggleChatbox = () => {
    setIsChatboxOpen((prev) => !prev);
  };

  return (
    <div className="fixed bottom-10 right-10 z-10 md:right-10">
      <div
        className="flex justify-center items-center bg-white rounded-full z-10 p-4 cursor-pointer"
        style={{ height: "60px", width: "60px" }}
        onClick={toggleChatbox}
      >
        {isChatBoxOpen ? (
          <IoIosClose size={30} />
        ) : (
          <IoIosChatboxes size={30} />
        )}
      </div>
      {isChatBoxOpen ? <ChatBox closeChat={toggleChatbox} /> : null}
    </div>
  );
};

export default ChatAssistant;
