import React,{useState} from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from '../contexts/TranslationContext'

function ProductsForm({item, getTreatmentPlans, matchProduct}) {
    const {id} = useParams()
    const { translate } = useTranslation();
    const obj = {
      "treatment_product_id": item.id,
      "volume": "",
      "supply": "",
      "fee": ""
    }
  const [formData1, setFormData1] = useState(obj)
  const [disabled, setDisabled] = useState(false)

    const handleChange = (e) => {
        const {name,value} = e.target
        setFormData1({
            ...formData1,
            [name]: value
        })
    }

    const submitForm1 = () => {
        console.log(formData1)
        fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan/product`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify(formData1)
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              console.log('Added')
              // setOpenGroPlan(!openGroPlan)
              setDisabled(true)
              getTreatmentPlans()
            } 
          })
          .catch(error => {
            console.error(error);
            //setMessage('Some Error Occured. Please Try Again Later');
          })
          .finally(() => {
            //setReady(true);
          });
      };

  return (
    <div
      style={{
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "rgba(246, 247, 250, 0.9)",
        height: "88.7%",
        width: "42rem",
        boxSizing: "border-box",
        padding: "2rem 1.5rem",
        textAlign: "center",
      }}
    >
      <div style={{ color: "black" }}>
        <div style={{ fontSize: "1.5rem" }}>{item.title}</div>
        <div>{item.sub_title}</div>
      </div>

      <div style={{ width: "66%", marginTop: "50px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "1.3rem 0",
          }}
        >
          <div style={{ textAlign: "right", color: "rgba(21, 20, 20, 0.8)" }}>
            {translate('volume')}
          </div>
          <input
            type="text"
            name="volume"
            value={formData1.volume}
            onChange={handleChange}
            style={{
              width: "12rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "1.3rem 0",
          }}
        >
          <div style={{ textAlign: "right", color: "rgba(21, 20, 20, 0.8)" }}>
            {translate('supply')}
          </div>
          <input
            type="text"
            name="supply"
            value={formData1.supply}
            onChange={handleChange}
            style={{
              width: "12rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexBasis: "45%",
            margin: "1.3rem 0",
          }}
        >
          <div style={{ textAlign: "right", color: "rgba(21, 20, 20, 0.8)" }}>
            {translate('fee')}
          </div>
          <input
            type="text"
            name="fee"
            value={formData1.fee}
            onChange={handleChange}
            style={{
              width: "12rem",
              marginLeft: "1rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "10rem" }}>
        <button
          disabled={disabled}
          onClick={submitForm1}
          style={{
            marginRight: "4rem",
            background: "#3BACA1",
            borderRadius: "5px",
            color: "white",
            padding: "0.2rem 1rem"
          }}
        >
          {disabled ? translate('added_to_gro_plan') : translate('add_to_gro_plan')}
        </button>
        <button
          style={{
            background: "#3BACA1",
            borderRadius: "5px",
            color: "white",
            padding: "0.2rem 1rem",
          }}
        >
          {translate('purchase_now')}
        </button>
      </div>
    </div>
  );
}

export default ProductsForm