import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import ShowHeadAvatar from './ShowHeadAvatar';
import GroIndexGraph from './GroIndexGraph';
import ImageLoader from '../ImageLoader';
import { useTranslation } from '../../contexts/TranslationContext';



const GlobalImageView = ({ analysis, base_analysis,handleTriggerRelaod, setReady }) => {
	const history = useHistory();
	const { translate } = useTranslation();
    const { patient_id, analysis_id, subType, graphType } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	return (
		<div style={{flexGrow: 1}}>
			{/* <div className="text-center font-bold text-base font-sans tracking-wider text-text-15 opacity-80 mt-12">
				Global Thinning Hair Assessment
			</div> */}
			<div className='text-center font-bold sm:text-lg font-sans tracking-wider opacity-80 text-text-15 mt-1 sm:my-2 capitalize mb-6 sm:mb-0'>
				{subType?.toUpperCase()}
			</div>
			{!analysis?.images?.global?.raw?.[subType] && (
				<p className="xs:pt-3 block h-56 xs:h-80 sm:hidden text-center text-gray-400">{translate('no_image_found')}</p>

			)}
			<div className='lg:mx-15 xl:mx-20 grid grid-cols-12 sm:grid-cols-3 gap-4 sm:gap-5 jusitfy-center'>
				<div className='w-full col-span-5 sm:col-span-1 pt-7'>
				{
						base_analysis && subType === 'crown' && analysis?.images?.global?.raw?.[subType]?.status !== 'invalid' && <GroIndexGraph graphType={graphType} hideGrowthIndex={true} analysis={analysis} base_analysis={base_analysis} forType={subType} mainType="global" />
					}
				</div>
				<div className='w-full col-span-7 sm:col-span-1 flex justify-center align-center'>
					{/* {displayImage()} */}
					{/* <img src={Global} className='cursor-pointer' onDoubleClick={() => {history.push(`/patient/${patient_id}/consult/${analysis_id_int}/compare/global`)}}/> */}
					{analysis?.images?.global && <ImageLoader  setReady={setReady} img={analysis?.images?.global?.raw?.[subType]} handleTriggerRelaod={handleTriggerRelaod} mainType="global" secondaryImg={subType === 'crown' ? analysis?.images?.global?.ml?.[subType] : ''} onDoubleClick={() => {history.push(`/patient/${patient_id}/consult/${analysis_id_int}/compare/global/${subType}`)}} />}
				</div>
				<div className='col-span-2 block sm:hidden'></div>
				<div className='w-full col-span-8 sm:col-span-1 flex justify-evenly flex-row'>
					<ShowHeadAvatar handleTriggerRelaod={handleTriggerRelaod} analysis={analysis} forType={subType}  mainType="global" />
				</div>
				<div className='col-span-2 block sm:hidden'></div>
			</div>
		</div>
	)
}

export default GlobalImageView