import React, { useState, useEffect } from 'react';
import Class1 from '../assets/classification_images/class1.png';
import Class2 from '../assets/classification_images/class2.png';
import Class3 from '../assets/classification_images/class3.png';
import Class4 from '../assets/classification_images/class4.png';
import Class5 from '../assets/classification_images/class5.png';
// import RefImage from "../assets/classification_images/reference image.png"
import './HairDensityTypes.css';
import { useTranslation } from '../contexts/TranslationContext';

const classes = [
  {
    classImg: Class1,
    class: 'Class I',
    about: '<10% Hair Loss',
    value: 'Normal',
  },
  {
    classImg: Class2,
    class: 'Class II',
    about: '11-25% Hair Loss',
    value: 'Early Thinning',
  },
  {
    classImg: Class3,
    class: 'Class III',
    about: '26-50% Hair Loss',
    value: 'Visible Thinning',
  },
  {
    classImg: Class4,
    class: 'Class IV',
    about: '51-70% Hair Loss',
    value: 'Severe Thinning',
  },
  {
    classImg: Class5,
    class: 'Class V',
    about: '>71% Hair Loss',
    value: 'Balding',
  },
];

const HairDensityTypes = props => {
  const curId = props.cur;
  const res = props.analysis;
  const area = props.area;
  const areaIndex = props.index;
  const refImg = props.refImg;

  const { translate } = useTranslation();
  const [selected, setSelected] = useState(null);
  const [images, setImages] = useState([]);
  const [src, setSrc] = useState('');
  // const [refImg, setRefImg] = useState(props.refImg);
  const [message, setMessage] = useState('');
  const [classNums, setClassNums] = useState([]);
  const [areaName, setAreaName] = useState('Front');

  useEffect(() => {
    // Fetching closeup images of hair of each date
    let imageArray = res.map(h => h.images.closeup);
    setImages(imageArray);

    if (imageArray[curId][0]) {
      let defaultSrc = imageArray[curId][0].image_path;
      setSrc(defaultSrc);
    }

    // Fetching reference image
    // for (var i = 0; i < imageArray[0].length; i++) {
    //   if (
    //     imageArray[0][i].mainType === 'closeup' &&
    //     imageArray[0][i].subType === 'occipital'
    //   ) {
    //     setRefImg(imageArray[0][i].image_path);
    //     break;
    //   }
    // }
    // let refImgarray = imageArray.map(ar => {
    // if(ar[2]) return ar[2].image_path
    //     return ''
    // })
    // setRefImg(refImgarray[0])

    // Fetching GRO Class values

    let classArray = res.map(r => r.groindex.groClass);
    setClassNums(classArray);
  }, [res, curId]);

  useEffect(() => {
    const handleShow = a => {
      setAreaName(a);

      if (images.length) {
        let res = images[curId].filter(e => e.subType === a.toLowerCase());
        if (res.length) {
          setSrc(res[0].image_path);
        } else {
          setSrc('');
          setMessage(translate('no_image_found'));
        }
      }
    };

    const isDefined = s => {
      let ls = s.toLowerCase();
      if (classNums.length) {
        if (classNums[curId][ls] >= 1 && classNums[curId][ls] <= 5) {
          return classNums[curId][ls];
        } else {
          return null;
        }
      }
    };

    if (isDefined(area)) {
      setSelected(isDefined(area) - 1);
      handleShow(area);
    } else {
      setSelected(null);
      handleShow(area);
    }
  }, [areaIndex, area, curId, classNums, images]);

  useEffect(() => {
    if (selected !== null) {
      setSelected(selected);
    }
  }, [curId, selected]);

  return (
    <div className="choices">
      <div className="card ref">
        <h4 className="type-name">{translate('back')}</h4>

        <div className="preview">
          {refImg ? <img src={refImg} alt="" /> : <p>{translate('no_reference_available')}</p>}
        </div>
        <h3 className="class-num">{translate('reference')}</h3>
        {/* <img src={RefImage} alt="" class="class-img img-responsive" /> */}
        <p className="about ref">
          {translate('dummy_text')}
          <br />
          {translate('dummy_text')}
        </p>
      </div>

      {classes.map((c, i) => (
        <div
          key={`${c} ${i}`}
          className={`card ${selected === i ? 'selected' : ''}`}
        >
          <h4 className="type-name">{selected === i && areaName}</h4>

          <div className="preview">
            {selected === i && src ? (
              <img src={src} alt="" />
            ) : selected === i && src === '' ? (
              <p>{message}</p>
            ) : (
              ' '
            )}
          </div>

          <h3 className="class-num">{c.class}</h3>

          {/* <img src={c.classImg} alt="" class="class-img img-responsive" /> */}
          <p className="about font-normal">
            {c.about}
            <br />
            {c.value}
          </p>
        </div>
      ))}
    </div>
  );
};

export default HairDensityTypes;
