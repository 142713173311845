import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PatientNavbar from '../components/PatientNavbar';
import PresentationNavbar from '../components/PresentationNavbar';
import dayjs from 'dayjs';
import Loading from './Loading';
import { useTranslation } from '../contexts/TranslationContext';

const GroReport = () => {
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [pdfReady, setPdfReady] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [currentId, setCurrentId] = useState('-1');
  const [patientReferrals, setPatientReferrals] = useState(null);
  const [pdf_blob, setPdfBlob] = useState('');
  const { translate } = useTranslation()

  const { id } = useParams();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setHairAnalysis(response.hair_analysis);
          setCurrentId(0);
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      });

  }, [id]);

  useEffect(()=> {
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hairAnalysis,currentId]);

  const fetchReport = () => {
    setPdfReady(false);
    if(hairAnalysis[currentId]){

      fetch(`${process.env.REACT_APP_API}/hair_analysis/${hairAnalysis[currentId].id}/report`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        
          // Create blob link to download
          const url = window.URL.createObjectURL(
            blob,
          );
          setPdfBlob(url);
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute(
          //   'download',
          //   `${firstName} ${lastName} ${dayjs
          //     .unix(hairAnalysis[currentId].created_at)
          //     .format('MM-DD-YY')}.pdf`
          // );

          // // Append to html link element page
          // document.body.appendChild(link);

          // // Start download
          // // link.click();

          // // Clean up and remove the link
          // link.parentNode.removeChild(link);
      }).finally(() => {
        setPdfReady(true);
      });
    }
  };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        } ${
          pdfReady ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="mt-6">
          <div>
            {patientReferrals ? (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
              </h1>
            ) : (
              <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                {`${firstName} ${lastName}`}
              </h1>
            )}
            <div className="flex items-center md:justify-evenly flex-col md:flex-row">
              <div className=" flex items-center">
                <button
                  className="font-bold text-2xl focus:outline-none"
                  onClick={function () {
                    if (currentId > 0) {
                      setCurrentId(currentId - 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill="#bfc5ca"
                      d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                    />
                  </svg>
                </button>
                <p className="mx-4 text-text-8">
                  {hairAnalysis[currentId]
                    ? dayjs
                        .unix(hairAnalysis[currentId].created_at)
                        .format('MM-DD-YY')
                    : ''}
                </p>
                <button
                  className="font-bold text-2xl focus:outline-none"
                  onClick={function () {
                    if (currentId + 1 < hairAnalysis.length) {
                      setCurrentId(currentId + 1);
                    }
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke="#bfc5ca"
                    strokeWidth="1.25"
                  >
                    <path
                      fill=" #bfc5ca"
                      d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div
            className="p-12 mt-4 bg-background-5 overflow-hidden flex justify-items-center"
            style={{ height: 'var(--height-1)' }}
          >
            {hairAnalysis[currentId] ? <iframe title="Gro Report" src={pdf_blob} width="100%" height="100%"></iframe> : ''}
            
           
          </div>
        </section>
        <PresentationNavbar id={id} tab={1} />
      </main>
      <Loading message={translate('loading_gro_report')} ready={ready} />
      {ready && <Loading message={translate('loading_gro_report')} ready={pdfReady} />}
      
    </>
  );
};

export default GroReport;
