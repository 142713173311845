import { useCallback, useRef, useState } from "react";

// const checkAndReplaceIfSimilarWordsFound = (currentText, lastText) => {
//     // "".slice()
//     // const currentText = currentText.toLowerCase().slice(lastText.length, currentText.length);
//     // take last words from current text and compare with last text.

// };

const AUTO_SUBMIT_TIMEOUT = 3000; // 5 seconds
export const useSpeechRecognition = () => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const transcriptRef = useRef("");
  const recognitionRef = useRef(null);

  const startListening = useCallback(() => {
    return new Promise((resolve, reject) => {
      setIsListening(true);
      window.SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      let recognition = new window.SpeechRecognition();
      //   recognition.continuous = true;
      recognition.interimResults = true;
      recognition.start();

      let timeout = null;
      recognition.onerror = (event) => {
        console.log("Error occurred in recognition: " + event.error);

        reject(event.error);
        reset();
      };
      let transcript = "";
      recognition.onresult = (event) => {
        clearTimeout(timeout);
        if (event.results[0].isFinal) {
          const currentTranscript = event.results[0][0].transcript;
          const lastTranscript = transcriptRef.current;
          transcriptRef.current = lastTranscript + " " + currentTranscript;
          setTranscript(transcriptRef.current);

          timeout = setTimeout(() => {
            reset();
          }, AUTO_SUBMIT_TIMEOUT);
          recognition.stop();
          return;
        }
        transcript = event.results[0][0].transcript;
        let latestText = transcriptRef.current + " " + transcript;
        setTranscript(latestText);
        timeout = setTimeout(() => {
          transcriptRef.current = latestText;
          reset();
        }, AUTO_SUBMIT_TIMEOUT);
      };
      recognitionRef.current = recognition;
      recognition.onend = () => {
        if (!recognitionRef.current) {
          recognition.stop();
          let text = transcriptRef.current;
          resolve(text);

          transcriptRef.current = "";
        } else {
          console.log("Speech recognition ended. Restarting...");
          // Restart recognition after a short delay to prevent infinite loops
          setTimeout(() => recognition.start(), 100);
        }
      };
    });
  }, []);

  const reset = () => {
    setTranscript("");
    setIsListening(false);
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
  };

  return {
    isListening,
    transcript,
    startListening,
    reset,
  };
};
