import React, { createContext, useContext, useEffect, useState } from "react";
import PatientNavbar from "../components/PatientNavbar";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import Loading from "./Loading";
import lessThanIcon from "../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../assets/images/greaterThanIcon.svg";
import GlobalImageView from "../components/OnePageConsultComponents/GlobalImageView";
import dayjs from "dayjs";
import CloseupImageView from "../components/OnePageConsultComponents/CloseupImageView";
import GlobalImageCompare from "../components/OnePageConsultComponents/GlobalImageCompare";
import CloseupImageCompare from "../components/OnePageConsultComponents/CloseuplmageCompare";
import GroReportView from "../components/OnePageConsultComponents/GroReportView";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useUserContext } from "../contexts/userContext";
import ChatAssistant from "../components/ChatAssistant";
import { useTranslation } from "../contexts/TranslationContext";

const changeAnalysis = (
  history,
  direction,
  currentIndex,
  analyses,
  graphType
) => {
  const url = window.location.pathname;
  const patientId = url.match(/\/patient\/(\d+)\//)[1];
  const consultId = url.match(/\/consult\/(\d+)\//)[1];
  const subTypeMatch =
    url.match(/\/closeup\/([^/]+)/) || url.match(/\/global\/([^/]+)/);
  const subType = subTypeMatch ? subTypeMatch[1] : null;
  const isComparison = url.includes("/compare/");
  const isGroReport = url.endsWith("/gro_reprot");
  const isHairGrowth = url.endsWith("/hair_growth");
  let newIndex;

  if (direction === "prev" && currentIndex > 0) {
    newIndex = currentIndex - 1;
  } else if (direction === "next" && currentIndex < analyses.length - 1) {
    newIndex = currentIndex + 1;
  } else {
    return;
  }

  if (isGroReport) {
    history.push(`/patient/${patientId}/consult/${newIndex}/gro_reprot`);
  } else if (url.includes("/global/")) {
    if (isComparison) {
      history.push(
        `/patient/${patientId}/consult/${newIndex}/compare/global/${subType}`
      );
    } else {
      history.push(
        `/patient/${patientId}/consult/${newIndex}/global/${subType}/hair_thinning`
      );
    }
  } else if (url.includes("/closeup/")) {
    if (isComparison) {
      history.push(
        `/patient/${patientId}/consult/${newIndex}/compare/closeup/${subType}`
      );
    } else {
      history.push(
        `/patient/${patientId}/consult/${newIndex}/closeup/${subType}/${
          isHairGrowth ? "hair_growth" : "hair_thinning"
        }`
      );
    }
  }
};

export const ChangeAnalysisContext = createContext({
  changeAnalysis: changeAnalysis,
});

export const GenderContext = createContext();

const OnePageConsult = () => {
  const history = useHistory();
  const location = useLocation();
  const { staffId, isAdmin } = useUserContext();
  const { translate } = useTranslation();

  const globalMatch = useRouteMatch(
    "/patient/:patient_id/consult/:analysis_id/global/:subType"
  );
  const closeupMatch = useRouteMatch(
    "/patient/:patient_id/consult/:analysis_id/closeup/:subType"
  );

  const { patient_id, analysis_id, graphType } = useParams();
  const data = useParams();
  const analysis_id_int = parseInt(analysis_id);

  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patientReferrals, setPatientReferrals] = useState(null);
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [gender, setGender] = useState("M");
  const [reloader, setRelaoder] = useState(false);

  const handleTriggerRelaod = () => {
    setRelaoder((prev) => !prev);
  };

  const { changeAnalysis } = useContext(ChangeAnalysisContext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${patient_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.patient.gender) {
          if (response.patient?.staffID !== staffId && !isAdmin) {
            history.replace("/staff-login");
            return;
          }
          setGender(response.patient.gender);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
    fetch(
      `${process.env.REACT_APP_API}/hair_analysis/all/v2?patient_id=${patient_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setHairAnalysis(response.hair_analysis);
          //   setCurrentId(0);
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      });
  }, [patient_id, reloader]);

  useEffect(() => {}, [location]);

  return (
    <>
      <ChatAssistant />
      <main
        className={`mx-auto px-4 w-full md:px-14 xl:container flex flex-col ${
          ready ? "" : "hidden"
        }`}
      >
        <PatientNavbar id={patient_id} tab={6} />
        {message && <p className="my-8 text-center text-red-600">{message}</p>}
        <section
          className="mb-10 flex flex-col px-2 pb-2 sm:pb-8 pt-2 bg-background-5 relative"
          style={{ minHeight: "var(--height-1)" }}
        >
          <div className="mt-2 sm:mt-8 flex flex-col" style={{ flexGrow: 1 }}>
            {/* {patientReferrals ? (
						<h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
							{`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
						</h1>
						) : ( */}
            <div>
              <h1
                className="px-12 flex  justify-center mb-1 sm:mb-3 text-center font-bold text-text-9 text-opacity-85 text-lg leading-5 tracking-widest opacity-80"
                style={{ letterSpacing: "1.6px" }}
              >
                {`${firstName?.replace(
                  firstName[0],
                  firstName[0]?.toUpperCase()
                )} ${lastName?.replace(
                  lastName[0],
                  lastName[0]?.toUpperCase()
                )}`}
              </h1>
              {/* )} */}
              {location?.pathname?.indexOf("compare") < 0 &&
              location?.pathname?.indexOf("gro_reprot") < 0 ? (
                <div className="flex items-center md:justify-evenly flex-col md:flex-row">
                  <div className=" flex items-center">
                    <button
                      className="font-bold text-2xl focus:outline-none"
                      onClick={function () {
                        changeAnalysis(
                          history,
                          "prev",
                          analysis_id_int,
                          hairAnalysis,
                          graphType
                        );
                      }}
                    >
                      <img src={lessThanIcon} className="h-8 w-8" />
                    </button>
                    <p className="mx-4 text-text-10">
                      {hairAnalysis[analysis_id_int]
                        ? dayjs
                            .unix(hairAnalysis[analysis_id_int].created_at)
                            .format("MM-DD-YY")
                        : ""}
                    </p>
                    <button
                      className="font-bold text-2xl focus:outline-none"
                      onClick={function () {
                        changeAnalysis(
                          history,
                          "next",
                          analysis_id_int,
                          hairAnalysis,
                          graphType
                        );
                      }}
                    >
                      <img src={greaterThanIcon} className="h-8 w-10" />
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="flex flex-col mt-6" style={{ flexGrow: 1 }}>
              <ChangeAnalysisContext.Provider
                value={{ changeAnalysis: changeAnalysis }}
              >
                <GenderContext.Provider value={{ gender: gender }}>
                  <Switch>
                    <Route
                      path="/patient/:patient_id/consult/:analysis_id/global/:subType/:graphType"
                      exact
                    >
                      <GlobalImageView
                        handleTriggerRelaod={handleTriggerRelaod}
                        analysis={hairAnalysis[analysis_id_int]}
                        base_analysis={hairAnalysis[0]}
                        setReady={setReady}
                      />
                    </Route>
                    <Route
                      path="/patient/:patient_id/consult/:analysis_id/closeup/:subType/:graphType"
                      exact
                    >
                      <CloseupImageView
                        handleTriggerRelaod={handleTriggerRelaod}
                        analysis={hairAnalysis[analysis_id_int]}
                        base_analysis={hairAnalysis[0]}
                        setReady={setReady}
                      />
                    </Route>
                    <Route
                      path="/patient/:patient_id/consult/:analysis_id/compare/global/:subType"
                      exact
                    >
                      <GlobalImageCompare
                        analyses={hairAnalysis}
                        setReady={setReady}
                      />
                    </Route>
                    <Route
                      path="/patient/:patient_id/consult/:analysis_id/compare/closeup/:subType"
                      exact
                    >
                      <CloseupImageCompare
                        analyses={hairAnalysis}
                        setReady={setReady}
                      />
                    </Route>
                    <Route
                      path="/patient/:patient_id/consult/:analysis_id/gro_reprot"
                      exact
                    >
                      <GroReportView
                        analyses={hairAnalysis}
                        setReady={setReady}
                        firstName={firstName}
                        lastName={lastName}
                      />
                    </Route>
                  </Switch>
                </GenderContext.Provider>
              </ChangeAnalysisContext.Provider>
            </div>
            {(globalMatch || closeupMatch) && (
              <div className="flex justify-center mt-6 sm:hidden">
                <div className="w-1/3 hidden sm:block"></div>
                <div className="w-full sm:w-1/3 text-center">
                  <Link
                    to={`/patient/${patient_id}/consult/${analysis_id_int}/gro_reprot`}
                    className="w-full block bg-transparent border border-solid py-2 lg:py-3 sm:px-0 lg:px-4 font-bold text-xs sm:text-base lg:text-lg font-sans tracking-wider leading-5 uppercase"
                    style={{
                      color: "#D0D4D7",
                      borderColor: "rgba(246,247,250,0.4)",
                    }}
                  >
                    {translate('gro_reports')}
                  </Link>
                </div>
                <div className="w-1/3 hidden sm:block"></div>
              </div>
            )}
          </div>
        </section>
      </main>
      <Loading message={translate('loading')} ready={ready} />
    </>
  );
};

export default OnePageConsult;
