import { useState } from "react";
import GroRating from "../../assets/images/GRORatingsLogo.svg";
import { AiOutlinePlus } from "react-icons/ai";

import BackIcon from '../../assets/images/lessThanIcon.svg'
import { useTranslation } from "../../contexts/TranslationContext";

export const MarketPlaceDetails = ({ item, setSelectedItem, parent }) => {
    const [tab, setTab] = useState('TECHNOLOGY');
    const [protocol, setProtocol] = useState(item?.protocol);
    const { translate } = useTranslation();
    const handleBackPress = () => {
        setSelectedItem(null);
    }

    return (
        <div>
            <div className="flex text-text-2 items-center justify-center mb-8">
                <div className="cursor-pointer" onClick={handleBackPress}><img className='w-8 h-8' src={BackIcon} alt='back' /></div>
                <div className="text-lg tracking-widest ml-6">{parent} / {item?.title}</div>
                <div><AiOutlinePlus className="w-6 h-6 ml-6" /></div>
                <div className="relative w-2">
                    <div className="absolute left-2/3 text-center w-96 text-black py-4 rounded-xl shadow-md z-50" style={{ bottom: '-70px', background: '#EDF0F7'}}>
                        <div className="tracking-wide">{translate('add_alma_ted_to_my_gro_solutions')}</div>
                        <div className="w-28 py-2 px-2 mt-2 text-xs font-normal text-text-3 rounded-md bg-background-23 mx-auto">{translate('confirm')}</div>
                    </div>
                </div>
            </div>
            <div className="flex gap-4">
                <div className="bg-text-3 w-72 text-center text-black" style={{ opacity: '90%' }}>
                    <div className="pb-3 pt-4 font-font-1">
                        <h3 className="text-lg tracking-wider">{item?.title}</h3>
                        <h4 className="font-normal text-sm tracking-wide">{item?.sub_title}</h4>
                    </div>
                    <div className="flex flex-col" style={{ height: '450px' }}>
                        <img src="https://grotrack.online/staging/backend/public/treatment_study_material/alma_banner.png" className="w-full h-36" alt="banner" />
                        <div className="h-16 overflow-hidden px-6 mt-12 text-sm tracking-wide font-normal text-text-6">
                            {item?.description}
                        </div>
                        <img src={GroRating} className="w-24 h-16 mt-6 mx-auto" alt="gro-rating" />
                        <hr className="border-b-2 border-b-accent-7 mx-6" />
                        <div className="w-28 mx-auto text-lg border-4 mt-3 border-gray-400" style={{ borderRadius: '20px' }}>
                            {item?.price ? item?.price?.split(".")[0] : 'N/A'}
                        </div>
                        <h5 className="text-xs tracking-wide text-text-1 mt-3 font-normal mb-4">{translate('gro_verified_cases')}s</h5>
                    </div>
                </div>
                <div className="flex-grow tracking-widest">
                    <div className="bg-text-3">
                        <div className="flex mx-12 py-5 justify-between text-black">
                            <div className={`cursor-pointer py-1 ${tab === 'TECHNOLOGY' ? 'border-b-4 border-gray-500' : ''}`} onClick={() => setTab('TECHNOLOGY')}>{translate('technology')}</div>
                            <div className={`cursor-pointer py-1 ${tab === 'INGREDIENTS' ? 'border-b-4 border-gray-500' : ''}`} onClick={() => setTab('INGREDIENTS')}>{translate('ingredients')}</div>
                            <div className={`cursor-pointer py-1 ${tab === 'PROTOCOL' ? 'border-b-4 border-gray-500' : ''}`} onClick={() => setTab('PROTOCOL')}>{translate('protocol')}</div>
                            <div className={`cursor-pointer py-1 ${tab === 'STUDIES' ? 'border-b-4 border-gray-500' : ''}`} onClick={() => setTab('STUDIES')}>{translate('studies')}</div>
                            <div className={`cursor-pointer py-1 ${tab === 'FEES' ? 'border-b-4 border-gray-500' : ''}`} onClick={() => setTab('FEES')}>{translate('fees')}</div>
                        </div>
                    </div>
                    <div className="bg-background-10 text-center text-black w-full" style={{ height: '450px' }}>
                        {tab === 'TECHNOLOGY' && <img className="w-full h-full" src={item?.study_image} alt="technology-tab" />}
                        {tab === 'PROTOCOL' && (
                            <div className="mx-auto">
                                <div className="pb-6 pt-10">{translate('recommended_protocol')}</div>
                                <div className="flex items-center justify-center mb-4 tracking-wide" style={{ opacity: '80%' }}>
                                    <div className="w-40 text-right">{translate('number_of_treatments')}</div>
                                    <div className="w-40 bg-background-4 h-8 ml-4 py-1" style={{ opacity: '60%' }}>{protocol?.no_of_treatments}</div>
                                    <div className="w-40 text-right">{translate('power_level')}</div>
                                    <div className="w-40 bg-background-4 h-8 ml-6 py-1" style={{ opacity: '60%' }}>{protocol?.power_level}</div>
                                </div>
                                <div className="flex items-center justify-center mb-6 tracking-wide" style={{ opacity: '80%' }}>
                                    <div className="w-40 text-right">{translate('frequency')}</div>
                                    <div className="w-40 bg-background-4 h-8 ml-4 py-1" style={{ opacity: '60%' }}>{protocol?.frequency}</div>
                                    <div className="w-40 text-right">{translate('setting')}</div>
                                    <div className="w-40 bg-background-4 h-8 ml-6 py-1" style={{ opacity: '60%' }}>{protocol?.settings}</div>
                                </div>
                                <div className="flex items-center justify-center mb-6 tracking-wide" style={{ opacity: '80%' }}>
                                    <div className="w-40 text-right">{translate('exclusions')}</div>
                                    <div className="w-40 bg-background-4 h-8 ml-4 py-1" style={{ opacity: '60%' }}>{protocol?.exclusions}</div>
                                    <div className="w-40 text-right">{translate('treatment_areas')}</div>
                                    <div className="w-40 bg-background-4 h-8 ml-6 py-1" style={{ opacity: '60%' }}>{protocol?.treatment_areas}</div>
                                </div>
                                <div className="flex items-center justify-center mb-6 tracking-wide" style={{ opacity: '80%' }}>
                                    <div className="w-40 text-right" />
                                    <div className="w-40 bg-background-4 h-8 ml-4 py-1" style={{ opacity: '60%' }} />
                                    <div className="w-40" />
                                    <div className="w-40 ml-6 py-1" />
                                </div>
                            </div>
                        )}
                        {tab === 'FEES' && (
                            <div className="mx-auto">
                                <div className="pb-6 pt-10">{translate('recommended_fees')}</div>
                                <div className="flex gap-4 justify-center tracking-wide mb-2" style={{ opacity: '70%' }}>
                                    <div className="w-40"></div>
                                    <div className="w-40">{translate('number_of_treatments_hash')}</div>
                                    <div className="w-40">{translate('fee')}</div>
                                </div>
                                {item?.fees?.map((fee) => {
                                    return (
                                        <div className="flex items-center justify-center mb-6 tracking-wide" style={{ opacity: '70%' }}>
                                            <div className="w-40 text-right">{fee?.name}</div>
                                            <div className="w-40 bg-background-4 h-8 ml-4 py-1" style={{ opacity: '60%' }}>{fee?.no_of_treatments}</div>
                                            <div className="w-40 bg-background-4 h-8 ml-6 py-1" style={{ opacity: '60%' }}>${fee?.fee}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}