import React, { useEffect } from 'react'
import { getChatBotLogo, getLogo } from '../utlis/themeHelpers'
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../contexts/userContext';

const AdminHeader = ({ hideChatGPT = false, userName = "", hideHomeLink = false, header = false }) => {
  const { isSalonApp, user, trainingDone } = useUserContext();

  return (
    <nav className={`flex relative items-center border-b-2 border-gray-700 ${(user || userName) ? `pt-1 pb-6 sm:pt-6 sm:pb-11` : `pt-4 pb-9 sm:pt-9 sm:pb-14`}  sm:mx-10`}>
      {hideHomeLink ? (
        <img className="h-12 sm:h-16 absolute left-0" src={getLogo(isSalonApp)} alt="GRO Track" />
      ) : (
        <NavLink to={`/`} className="absolute left-0">
        <img className="h-12 sm:h-16" src={getLogo(isSalonApp)} alt="GRO Track" />
      </NavLink>
        )}

      <h1 className="mx-auto mt-4 text-3xl text-text-3 font-bold uppercase tracking-wider leading-7">
        {header ? header : user ? (user?.name) : userName}
      </h1>
      {!hideChatGPT && (
        <img className="h-20 absolute right-0" src={getChatBotLogo()} alt="GRO Track" />
      )}
    </nav>
  )
}

export default AdminHeader