import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/GROTrackTurquoise.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";
import { useUserContext } from "../contexts/userContext";
import { getLogo } from "../utlis/themeHelpers";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

export default function AdminProfile() {
  const { isSalonApp } = useUserContext();
  const { translate } = useTranslation();
  const [physician, setPhysician] = useState(null);
  const [editPhysician, setEditPhysician] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [editProfile, setEditProfile] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [profileEditMode, setProfileEditMode] = useState(false);
  const [profileErrors, setProfileErrors] = useState({});
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);

  const isPhysicianValid = () => {
    return true;
  };

  const savePhysician = () => {
    if (isPhysicianValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/admin/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editPhysician),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setPhysician(response.adminProfile);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  const saveProfile = () => {
    setReady(false);
    if (editProfile.password === editProfile.confirm_password) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/clinic/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editProfile),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setUsername(editProfile.name);
            setEmail(editProfile.email);
            setProfileErrors({});
            setProfileEditMode(false);
          } else {
            setProfileErrors(response.error);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPhysician(response.adminProfile);
          setEditPhysician({
            ...response.adminProfile,
            address: { ...response.adminProfile.address },
          });
          setUsername(response.adminProfile.clinic.name);
          setEmail(response.adminProfile.clinic.email);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-5 pb-12 font-font-2 tracking-wide font-normal ${
          ready ? "" : "hidden"
        }`}
      >
        <AdminHeader physician={physician} />

        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex px-5">
          <AdminSidebar />
          {physician && (
            <div className="mx-auto pt-5">
              <form
                className="flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  savePhysician();
                }}
              >
                <div className="mt-4 flex justify-between items-center">
                  <p className="mx-auto text-text-3 text-lg font-bold">
                    {isSalonApp ? translate('salon_information') : translate('practice_information')}
                  </p>
                  {/* {editMode && <button
                    className=" px-8 py-1 bg-background-23 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    SAVE
                  </button>} */}
                </div>
                <div className="mx-auto mt-10 flex flex-col items-end text-sm font-semibold">
                  <label className="flex items-center">
                    <p className="ml-3 mr-3">
                      {isSalonApp ? translate('salon') : translate('practice')} {translate('name')}
                    </p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={
                        editMode
                          ? editPhysician.practiceName
                          : physician.practiceName
                      }
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          practiceName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-4 flex items-center">
                    <p className="ml-3 mr-3">
                      {isSalonApp ? translate('salon_owner') : translate('physician')} {translate('name')}
                    </p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={
                        editMode
                          ? editPhysician.physicianName
                          : physician.physicianName
                      }
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          physicianName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-5 flex items-center">
                    <p className="ml-3 mr-3">{translate('address')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={
                        editMode
                          ? editPhysician.address.street
                          : physician.address.street
                      }
                      disabled={!editMode}
                      placeholder={translate('street')}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          address: {
                            ...editPhysician.address,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                    value={
                      editMode
                        ? editPhysician.address.suite
                        : physician.address.suite
                    }
                    disabled={!editMode}
                    placeholder={translate('suite')}
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          suite: event.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                    value={
                      editMode
                        ? editPhysician.address.city
                        : physician.address.city
                    }
                    disabled={!editMode}
                    placeholder={translate('city')}
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-5 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                        value={
                          editMode
                            ? editPhysician.address.state
                            : physician.address.state
                        }
                        disabled={!editMode}
                        placeholder={translate('state')}
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                        value={
                          editMode
                            ? editPhysician.address.zip
                            : physician.address.zip
                        }
                        disabled={!editMode}
                        placeholder={translate('zip')}
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-5 flex items-center">
                    <p className="ml-3 mr-3">{translate('phone')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editMode ? editPhysician.phone : physician.phone}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-5 flex items-center">
                    <p className="ml-3 mr-3">{translate('email')}</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editMode ? editPhysician.email : physician.email}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-5 flex items-center">
                    <p className="ml-3 mr-3">{translate('website')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={
                        editMode ? editPhysician.website : physician.website
                      }
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          website: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <div className="mt-5 w-full flex justify-center">
                    {editMode && (
                      <button
                        type="submit"
                        style={{ paddingTop: "2px", paddingBottom: "2px" }}
                        className=" px-8 py-1 mx-2 bg-button-5 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none"
                      >
                        {translate('save')}
                      </button>
                    )}
                    <button
                      type="button"
                      className=" px-8 mx-2 py-1 bg-button-5 rounded-lg text-text-3 font-bold
              cursor-pointer focus:outline-none"
                      style={{ paddingTop: "2px", paddingBottom: "2px" }}
                      onClick={() => {
                        setEditMode(!editMode);
                      }}
                    >
                      {editMode ? "Cancel" : "Update"}
                    </button>
                  </div>
                </div>
              </form>
              <form
                className="mt-10 flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveProfile();
                }}
              >
                <div className="mt-4 flex items-center relative">
                  <p className="mx-auto text-text-3 text-lg font-bold">
                    {translate('user_name_password')}
                  </p>
                  <div className="absolute right-0">
                    {profileEditMode && (
                      <button
                        type="submit"
                        className=" px-8 mr-2 bg-button-5 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none "
                        style={{ paddingTop: "2px", paddingBottom: "2px" }}
                        disabled={!profileEditMode}
                      >
                        {translate('save')}
                      </button>
                    )}

                    <button
                      type="button"
                      className=" px-8 bg-button-5 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none"
                      style={{ paddingTop: "2px", paddingBottom: "2px" }}
                      onClick={() => {
                        setEditProfile({
                          name: username,
                          email: email,
                          password: "",
                          confirm_password: "",
                        });
                        setProfileEditMode(!profileEditMode);
                      }}
                    >
                      {profileEditMode ? translate('cancel') : translate('reset')}
                    </button>
                  </div>
                </div>
                <div className="mx-auto mt-4 flex flex-col items-end bg-background-9 px-32 py-8 text-sm font-semibold">
                  <label className="flex items-center">
                    <p className="ml-2">
                      {isSalonApp ? translate('salon') : translate('clinic')} {translate('name')}
                    </p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={profileEditMode ? editProfile.name : username}
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          name: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.name && (
                    <span className="text-red-500">{profileErrors.name}</span>
                  )}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">{translate('email')}</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={profileEditMode ? editProfile.email : email}
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.email && (
                    <span className="text-red-500">{profileErrors.email}</span>
                  )}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">{translate('password')}</p>
                    <input
                      type="password"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      autoComplete="off"
                      value={profileEditMode ? editProfile.password : ""}
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          password: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.password && (
                    <span className="text-red-500">
                      {profileErrors.password}
                    </span>
                  )}
                  <label className="mt-4 flex items-center">
                    <p className="ml-2">{translate('confirm_password')}</p>
                    <input
                      type="password"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      autoComplete="off"
                      value={
                        profileEditMode ? editProfile.confirm_password : ""
                      }
                      required
                      disabled={!profileEditMode}
                      onChange={(event) => {
                        setEditProfile({
                          ...editProfile,
                          confirm_password: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {profileErrors.confirm_password && (
                    <span className="text-red-500">
                      {profileErrors.confirm_password}
                    </span>
                  )}
                </div>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading
        message={`${translate('loading')} ${isSalonApp ? translate('salon') : translate('physician')} ${translate('details')}`}
        ready={ready}
      />
    </>
  );
}
