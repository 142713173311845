import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../views/Loading";
import PatientNavbar from "../components/PatientNavbar";
import PatientSidebar from "../components/PatientSidebar";
import { useUserContext } from "../contexts/userContext";
import { useTranslation } from "../contexts/TranslationContext";

export default function MedicationHealthHistory() {
  const [ready, setReady] = useState(false);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(true);
  const [examDrop, setExamDrop] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [healthDropdown, setHealthDropdown] = useState(true);
  const [medicationInfo, setMedicationInfo] = useState(null);
  const [editMedicationInfo, setEditMedicationInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const { id } = useParams();
  const [patientReferrals, setPatientReferrals] = useState(null);
  const { isSalonApp } = useUserContext();
  const { translate } = useTranslation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatientReferrals(response.referral);
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const handleClickChange = (event) => {
    const name = event.target.name;
    if (editMedicationInfo[name] === 1) {
      setEditMedicationInfo({ ...editMedicationInfo, [name]: 0 });
    }
    if (editMedicationInfo[name] === 0) {
      setEditMedicationInfo({ ...editMedicationInfo, [name]: 1 });
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEditMedicationInfo({
      ...editMedicationInfo,
      [name]: value,
    });
  };

  const isMedicationInfoValid = () => {
    return true;
  };
  const isHealthHistoryOpen = () => {
    return true;
  };

  const saveMedicationInfo = () => {
    if (isMedicationInfoValid()) {
      setReady(false);
      fetch(
        `${process.env.REACT_APP_API}/patient/${id}/health_history_medication`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(editMedicationInfo),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setMedicationInfo(response.data);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/health-info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/health_history_medication`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setMedicationInfo(response.data);
          setEditMedicationInfo({
            ...response.data,
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? "" : "hidden"
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            id={id}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            isHealthHistoryOpen={isHealthHistoryOpen}
            isHealthOpen={isHealthHistoryOpen}
          />
          {medicationInfo && (
            <div className="mx-auto max-w-5xl">
              {patientReferrals === null ? (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${firstName} ${lastName}`}
                </h1>
              ) : (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                </h1>
              )}
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  saveMedicationInfo();
                }}
                className="lg:mx-16 flex flex-col"
              >
                <div className="mt-4 flex justify-between items-center">
                  <button
                    type="button"
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? "Cancel" : "Edit"}
                  </button>
                  <p className="mx-4">{translate('medication')}</p>
                  <button
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    {translate('save')}
                  </button>
                </div>
                <section className="mt-8">
                  <p className="text-xl text-text-2">{translate('medication_responses')}</p>
                  <div className="grid mt-8 grid-cols-5 items-center gap-6">
                    <p>{translate('hormone_replacement')}</p>
                    <label className="flex justify-end">
                      <input
                        onClick={(e) => {
                          if (editMode) {
                            handleClickChange(e);
                          }
                        }}
                        type="radio"
                        style={{
                          accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                        }}
                        readOnly
                        name="hormone_replacement_selected"
                        value=""
                        checked={
                          editMode
                            ? editMedicationInfo.hormone_replacement_selected ===
                              1
                            : medicationInfo.hormone_replacement_selected === 1
                        }
                      />
                    </label>
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("when")}
                      name="hormone_replacement_when"
                      disabled={
                        !editMode ||
                        editMedicationInfo.hormone_replacement_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.hormone_replacement_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.hormone_replacement_when
                          : medicationInfo.hormone_replacement_when
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("how_long")}
                      name="hormone_replacement_how_long"
                      disabled={
                        !editMode ||
                        editMedicationInfo.hormone_replacement_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.hormone_replacement_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.hormone_replacement_how_long
                          : medicationInfo.hormone_replacement_how_long
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("response")}
                      name="hormone_replacement_response"
                      disabled={
                        !editMode ||
                        editMedicationInfo.hormone_replacement_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.hormone_replacement_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.hormone_replacement_response
                          : medicationInfo.hormone_replacement_response
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <p>{translate('iron')}</p>
                    <label className="flex justify-end">
                      <input
                        type="radio"
                        style={{
                          accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                        }}
                        readOnly
                        name="iron_selected"
                        value=""
                        checked={
                          editMode
                            ? editMedicationInfo.iron_selected === 1
                            : medicationInfo.iron_selected === 1
                        }
                        onClick={(e) => {
                          if (editMode) {
                            handleClickChange(e);
                          }
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("when")}
                      name="iron_when"
                      disabled={
                        !editMode || editMedicationInfo.iron_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.iron_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.iron_when
                          : medicationInfo.iron_when
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("how_long")}
                      name="iron_how_long"
                      disabled={
                        !editMode || editMedicationInfo.iron_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.iron_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.iron_how_long
                          : medicationInfo.iron_how_long
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate('response')}
                      name="iron_response"
                      disabled={
                        !editMode || editMedicationInfo.iron_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.iron_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.iron_response
                          : medicationInfo.iron_response
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <p>{translate('thyroid')}</p>
                    <label className="flex justify-end">
                      <input
                        type="radio"
                        style={{
                          accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                        }}
                        readOnly
                        name="thyroid_selected"
                        value=""
                        checked={
                          editMode
                            ? editMedicationInfo.thyroid_selected === 1
                            : medicationInfo.thyroid_selected === 1
                        }
                        onClick={(e) => {
                          if (editMode) {
                            handleClickChange(e);
                          }
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("when")}
                      disabled={
                        !editMode || editMedicationInfo.thyroid_selected === 0
                      }
                      name="thyroid_when"
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.thyroid_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.thyroid_when
                          : medicationInfo.thyroid_when
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("how_long")}
                      name="thyroid_how_long"
                      disabled={
                        !editMode || editMedicationInfo.thyroid_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.thyroid_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.thyroid_how_long
                          : medicationInfo.thyroid_how_long
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("response")}
                      name="thyroid_response"
                      disabled={
                        !editMode || editMedicationInfo.thyroid_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.thyroid_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.thyroid_response
                          : medicationInfo.thyroid_response
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <p>Estrogen</p>
                    <label className="flex justify-end">
                      <input
                        type="radio"
                        style={{
                          accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                        }}
                        readOnly
                        name="estrogen_selected"
                        value=""
                        checked={
                          editMode
                            ? editMedicationInfo.estrogen_selected === 1
                            : medicationInfo.estrogen_selected === 1
                        }
                        onClick={(e) => {
                          if (editMode) {
                            handleClickChange(e);
                          }
                        }}
                      />
                    </label>
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("when")}
                      name="estrogen_when"
                      disabled={
                        !editMode || editMedicationInfo.estrogen_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.estrogen_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.estrogen_when
                          : medicationInfo.estrogen_when
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("how_long")}
                      name="estrogen_how_long"
                      disabled={
                        !editMode || editMedicationInfo.estrogen_selected === 0
                      }
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.estrogen_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.estrogen_how_long
                          : medicationInfo.estrogen_how_long
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                    <input
                      type="text"
                      className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                      placeholder={translate("response")}
                      disabled={
                        !editMode || editMedicationInfo.estrogen_selected === 0
                      }
                      name="estrogen_response"
                      value={
                        editMedicationInfo &&
                        editMedicationInfo.estrogen_selected === 0
                          ? ""
                          : editMode
                          ? editMedicationInfo.estrogen_response
                          : medicationInfo.estrogen_response
                      }
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="mt-6 border-t-2 border-background-2 pt-4">
                    <p className="text-text-2 text-xl mt-2">
                      {translate('fda_approved_hair_loss_drugs_responses')}
                    </p>
                    <div className="grid mt-8 grid-cols-5 items-center gap-6">
                      <p>{translate('finasteride')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                          style={{
                            accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                          }}
                          readOnly
                          name="finasteride_selected"
                          value=""
                          checked={
                            editMode
                              ? editMedicationInfo.finasteride_selected === 1
                              : medicationInfo.finasteride_selected === 1
                          }
                          onClick={(e) => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="finasteride_when"
                        disabled={
                          !editMode ||
                          editMedicationInfo.finasteride_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.finasteride_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.finasteride_when
                            : medicationInfo.finasteride_when
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="finasteride_how_long"
                        disabled={
                          !editMode ||
                          editMedicationInfo.finasteride_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.finasteride_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.finasteride_how_long
                            : medicationInfo.finasteride_how_long
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="finasteride_response"
                        disabled={
                          !editMode ||
                          editMedicationInfo.finasteride_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.finasteride_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.finasteride_response
                            : medicationInfo.finasteride_response
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <p>{translate('minoxidil')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                          style={{
                            accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                          }}
                          readOnly
                          name="minoxidil_selected"
                          value=""
                          checked={
                            editMode
                              ? editMedicationInfo.minoxidil_selected === 1
                              : medicationInfo.minoxidil_selected === 1
                          }
                          onClick={(e) => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="minoxidil_when"
                        disabled={
                          !editMode ||
                          editMedicationInfo.minoxidil_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.minoxidil_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.minoxidil_when
                            : medicationInfo.minoxidil_when
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="minoxidil_how_long"
                        disabled={
                          !editMode ||
                          editMedicationInfo.minoxidil_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.minoxidil_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.minoxidil_how_long
                            : medicationInfo.minoxidil_how_long
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="minoxidil_response"
                        disabled={
                          !editMode ||
                          editMedicationInfo.minoxidil_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.minoxidil_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.minoxidil_response
                            : medicationInfo.minoxidil_response
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <p>{translate('others')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                          style={{
                            accentColor: isSalonApp ? "#F4831F" : "1e9ab9",
                          }}
                          readOnly
                          name="other_selected"
                          value=""
                          checked={
                            editMode
                              ? editMedicationInfo.other_selected === 1
                              : medicationInfo.other_selected === 1
                          }
                          onClick={(e) => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="other_when"
                        disabled={
                          !editMode || editMedicationInfo.other_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.other_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.other_when
                            : medicationInfo.other_when
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        disabled={
                          !editMode || editMedicationInfo.other_selected === 0
                        }
                        name="other_how_long"
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.other_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.other_how_long
                            : medicationInfo.other_how_long
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="other_response"
                        disabled={
                          !editMode || editMedicationInfo.other_selected === 0
                        }
                        value={
                          editMedicationInfo &&
                          editMedicationInfo.other_selected === 0
                            ? ""
                            : editMode
                            ? editMedicationInfo.other_response
                            : medicationInfo.other_response
                        }
                        onChange={(e) => handleInputChange(e)}
                      />
                    </div>
                  </div>
                </section>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={`${isSalonApp ? translate('loading') + " Client Medication History":  translate('loading_patient_medication_history')}`} ready={ready} />
    </>
  );
}
