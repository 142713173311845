import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Loading from "./Loading";
import EmbeddedYouTubeVideo from "../components/EmbeddedYoutubeVideo";
import moment from "moment";
import { AiOutlineUp } from "react-icons/ai";
import BackIcon from "../assets/images/lessThanIcon.svg";
// import SettingsIcon from "../assets/images/GearIcon.png";
import { useUserContext } from "../contexts/userContext";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

const AdminTrainingVideo = () => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);
  const [physician, setPhysician] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const { isSalonApp, trainingDone } = useUserContext();
  const { translate } = useTranslation();
  const { video_title } = useParams();

  useEffect(() => {
    let chapter = chapters.find((chapter) => {
      if (chapter.slug === video_title) {
        return true;
      }
      return false;
    });
    setSelectedChapter(chapter);
  }, [video_title]);

  const chapters = [
    {
      id: 1,
      title: translate('how_to_use_grotrack_camera'),
      slug: "how_to_use_grotrack_camera",
      // video_id: "t6R2aeoRsug",
      video_id: "GcVYJMHP62M",
    },
    {
      id: 2,
      title: translate('how_to_use_grotrack_software'),
      slug: "how_to_use_grotrack_software",
      video_id: "t6R2aeoRsug",
    },
  ];

  const getAdminProfile = () => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPhysician(response.adminProfile);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  useEffect(() => {
    getAdminProfile();
  }, []);

  const [chaptersVideo, setChaptersVideo] = useState([]);
  const [selectedVideoChapter, setSelectedVideoChapter] = useState(0);

  const handleChapterSelect = (index) => {
    setSelectedVideoChapter(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&id=${selectedChapter.video_id}&key=AIzaSyC9x65GqYjdhee-1XlveD4mx40tgjbPgwk`
      )
        .then((response) => response.json())
        .then((data) => {
          const description = data.items[0].snippet.description;
          const chapters = description
            .split("\n")
            .filter((line) => line.match(/^\d{1,2}:\d{2}/))
            .map((line) => {
              const [time, title] = line
                .match(/^(\d{1,2}:\d{2})\s*(.*)$/)
                .slice(1);
              return {
                startOffset: moment.duration(time, "mm:ss").asSeconds() / 60,
                title: title.trim(),
              };
            });
          setChaptersVideo(chapters);
        });
    };
    if (selectedChapter?.video_id) {
      fetchData();
    }
  }, [selectedChapter]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-5 font-font-2 tracking-wider ${ready ? "" : "hidden"
          }`}
      >
        <AdminHeader header='GROTRACK ONLINE TRAINING COURSE' physician={physician} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex">
          <aside className="w-56 mt-24 pt-4 hidden lg:flex flex-col border-background-2 font-font-2 font-bold tracking-wide pl-10 pr-5">
            <ul className="mt-4 grid text-text-2">
              {chapters.map((chapter, index) => {
                return (
                  <li className="border-b border-text-1 py-5 tracking-wider">
                    <div>{translate('chapter')} {index + 1} :</div>
                    <NavLink
                      className="text-sm"
                      exact
                      to={`/admin/training/${chapter.slug}`}
                      activeClassName="text-text-3"
                    >
                      {chapter.title}
                      {selectedChapter?.id === chapter.id && (
                        <ShowVideoChapterList
                          chaptersVideo={chaptersVideo}
                          selectedVideoChapter={selectedVideoChapter}
                          handleChapterSelect={handleChapterSelect}
                          selectedChapter={selectedChapter}
                          chapter={chapter}
                        />
                      )}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </aside>
          <div
            className="w-full p-5 flex flex-col tracking-wider"
            style={{ minHeight: "calc(100vh - 138px)" }}
          >
            <div className="flex relative mx-5">
              <div className="w-full">
                <NavLink className='absolute z-50 mt-5' exact to={`/admin/training`}>
                  <div className={`w-fit px-4 text-sm text-text-3 py-2 text-center rounded-md cursor-pointer ${trainingDone ? 'bg-background-23' : 'bg-background-24'} tracking-widest`} >
                    {trainingDone ? translate("certified") : translate('get_certified')}
                  </div>
                </NavLink>
                <div className="w-full absolute flex justify-center">
                  <div className="flex">
                    <NavLink exact to={`/admin/training`}>
                      <img className='mt-4 w-10 h-10 mr-8 cursor-pointer' src={BackIcon} alt='back' />
                    </NavLink>
                    <div>
                      <h1 className="text-center text-xl text-white mt-5 text-text-2 tracking-wider">
                        {selectedChapter && selectedChapter.title}
                      </h1>
                      <h2 className="text-center text-white mb-10 mt-1 text-text-2 tracking-wider">
                        {"(" + chaptersVideo[selectedVideoChapter]?.title + ")"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="absolute pt-8 h-full items-center"
                style={{ left: "75%" }}
              >
                <div className="m-0 mb-2 text-sm text-background-22 tracking-widest">
                  <div style={{ marginTop: "-4px" }}>{translate('user_guide')}</div>
                </div>
                <div className="flex justify-between items-end mx-1">
                  <svg
                    className="h-6 text-text-2 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />{" "}
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />{" "}
                  </svg>
                  <svg
                    class="h-5 mb-1 mr-1 text-text-2 cursor-pointer"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 18"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="px-5 mt-32">
              {selectedChapter &&
                chaptersVideo[selectedVideoChapter]?.startOffset >= 0 && (
                  <EmbeddedYouTubeVideo
                    videoId={selectedChapter.video_id}
                    key={selectedChapter.id}
                    start={chaptersVideo[selectedVideoChapter]?.startOffset}
                    end={
                      chaptersVideo[selectedVideoChapter + 1]?.startOffset - 1
                    }
                  />
                )}
            </div>
          </div>
        </section>
      </main>
      <Loading message={translate('loading_details')} ready={ready} />
    </>
  );
};

const ShowVideoChapterList = ({
  chaptersVideo,
  selectedVideoChapter,
  handleChapterSelect,
  selectedChapter,
  chapter,
}) => {
  const [show, setShow] = useState(false);
  if (!show) {
    return (
      <div className="text-center ">
        <button
          className={`w-full py-2 text-left text-text-2 flex justify-center font-bold`}
          onClick={(e) => {
            e.preventDefault();
            setShow((prev) => !prev);
          }}
        >
          <svg
            class="w-4 h-5 text-text-1"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
            />
          </svg>
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="text-center ">
        <button
          className={`w-full py-2 text-left text-text-2 flex justify-center`}
          onClick={(e) => {
            e.preventDefault();
            setShow((prev) => !prev);
          }}
        >
          <AiOutlineUp />
        </button>
      </div>
      <div className="h-96 overflow-auto font-font-2 text-sm hide-scrollbar">
        <ul>
          {chaptersVideo &&
            chaptersVideo.map((chap, index) => (
              <li>
                <button
                  key={index}
                  className={`w-full py-2 text-left text-gray-500 ${selectedVideoChapter === index &&
                    chapter.id === selectedChapter.id
                    ? "text-text-3"
                    : ""
                    }`}
                  onClick={() => handleChapterSelect(index)}
                >
                  {`${index + 1}) `}{chap.title}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default AdminTrainingVideo;
