import React,{useState,useEffect} from 'react'
import styles from './ClinicalNotes.module.css'
import { useParams } from 'react-router-dom';
// import Loading from '../views/Loading';
// import PatientNavbar from '../components/PatientNavbar';
import dayjs from 'dayjs';
import { useTranslation } from '../contexts/TranslationContext';

function ClinicalNotes({procedure, getTreatmentPlans}) {

  const [showEdit,setShowEdit] = useState(true)
  const { translate } = useTranslation()
  const treatment_id = procedure.id
  const clinical_notes = procedure.clinical_notes

  const { id } = useParams();

  let obj = [
    clinicalNotesBody => '',
    clinicalNotesSignature => '',
    clinicalNotesDate => '',
    patient_id => id,
  ]

  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [editMode, setEditMode] = useState(false);
  // const [treatmentPlan, setTreatmentPlan] = useState(null);
  const [clinicalNotes, setClinicalNotes] = useState([]);
  const [editClinicNote, setEditClinicNote] = useState(obj);
  const [message, setMessage] = useState('');
  // const [ready, setReady] = useState(false);
  // const [patientReferrals, setPatientReferrals] = useState(null);
  const [inputDate,setInputDate] = useState('text')
  // const [openNote,setOpenNote] = useState(false)
  // const [noteData,setNoteData] = useState(null)

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.success) {
  //         // setPatientReferrals(response.referral);
  //       } else {
  //         setMessage(response.message);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setMessage('Some Error Occured. Please Try Again Later');
  //     })
  //     .finally(() => {
  //       // setReady(true);
  //     });
  // }, [id]);

  const saveClinicalNotes = () => {
    var body = editClinicNote;
    body['patient_id'] = id;
    // setReady(false);
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan/${treatment_id}/clinical-notes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          if (
            dayjs(editClinicNote.clinicalNotesDate).format('MM-DD-YY') ===
            dayjs().format('MM-DD-YY')
          ) {
            var clinicalNotesTemp = clinicalNotes;
            clinicalNotesTemp[clinicalNotesTemp.length] = response.clinicNote;
            setClinicalNotes(clinicalNotesTemp);
          }
          setEditClinicNote([
            clinicalNotesBody => '',
            clinicalNotesSignature => '',
            clinicalNotesDate => '',
          ]);
          setEditMode(false);
          fetchDetails()
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        // setReady(true);
      });
  };

  const fetchDetails = () => {
    getTreatmentPlans()
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/todays_treatment_plan`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          // setFirstName(response.firstName);
          // setLastName(response.lastName);
          // setTreatmentPlan(response.treatmentPlans);
          setClinicalNotes(response.clinicNotes);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        // setReady(true);
      });
  }, [id]);

  const handleNote = (note) => {
    setEditClinicNote(note)
    setShowEdit(false)
    // if(noteData && note.id === noteData.id){
    //   setOpenNote(!openNote)
    //   setNoteData(null)
    // }
    // else{
    // setOpenNote(!openNote)
    // setNoteData(note)
    // }
  }

  const addNewNote = () => {
    setShowEdit(true)
    setEditClinicNote(obj)
  }

  return (
    <div className={styles.container}>
        <>
      <main
      style={{
        width:'80%',
        margin:'auto'
      }}
        // className={`mx-auto container flex flex-col${
        //   ready ? '' : 'hidden'
        // }`}
      >
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}

        <section>
          <div
            className="mt-5 bg-background-5 p-4 flex flex-col"
            // style={{
            //   padding:'8vh 3vw'
            // }}
            style={{
              minHeight:'25rem'
            }}
          >
            <div 
            // className="flex flex-col lg:flex-row mt-4 py-12"
            style={{
              display:'flex',
              justifyContent:'space-around',
              padding:'2rem 0'
            }}
            >

              <div 
              // className="w-3/5 pr-8"
              style={{
                width:'30%'
              }}
              >
                {/* {treatmentPlan && treatmentPlan.length > 0 ? (
                  <div>
                    <h2 className="text-lg text-text-2 text-center mb-12">
                      {dayjs().format('MMMM, D, YYYY')}
                    </h2>
                    {treatmentPlan.map(procedure => {
                      return (
                        <div
                          key={procedure.id}
                          className="mt-4 flex items-center"
                        >
                          <div className="grid flex-grow grid-cols-9 text-center text-text-3 w-3/5 font-font-2 font-normal">
                            <h2 className="px-2 py-1 bg-background-6 font-bold">
                              {procedure.type}
                            </h2>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.brand}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.bloodV}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.plasmaV}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.plateletC}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.injectionDepth}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.area}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.interval}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.tx}
                            </p>
                          </div>
                          <div className="flex">
                            <button className="ml-4 focus:outline-none">
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill={
                                    procedure.has_completed
                                      ? '#13c3b2'
                                      : '#bec4ca'
                                  }
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                />
                              </svg>
                            </button>
                            <button className="ml-4 focus:outline-none">
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                              >
                                <path
                                  fill={
                                    procedure.paymentCompleted
                                      ? '#13c3b2'
                                      : '#bec4ca'
                                  }
                                  d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
                                />
                              </svg>
                            </button>
                            <button className="ml-4 focus:outline-none">
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#13c3b2"
                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <h2 className="mt-8 text-center">
                    No Treatment Plans for today
                  </h2>
                )} */}
                {clinical_notes && clinical_notes.length !== 0 ? (
                    clinical_notes.map((note, key) => {
                      return (
                        <>
                            <div key={clinical_notes.id} onClick={()=>handleNote(note)} 
                            style={{width:'fit-content',margin:'auto',cursor:'pointer',textAlign:'center'}}>
                              {/* Date:{' '} */}
                              {dayjs(note.clinicalNotesDate).format('MM-D-YYYY')}
                              {/* <>
                                {
                                  noteData && noteData.id===note.id &&
                                  <div style={{marginBottom:'1rem'}}>
                                    <div style={{textAlign:'left'}}>{note.clinicalNotesBody}</div>
                                    <div style={{textAlign:'left'}}>
                                      <div>Signature: {note.clinicalNotesSignature}</div>
                                      <div>Date: {dayjs(note.clinicalNotesDate).format('MM-D-YYYY')}</div>
                                    </div>
                                  </div>
                                }
                              </> */}
                            </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="text-center mb-3">{translate('no_notes_for_today')}</div>
                  )}
              </div>

                {/* <div className="mb-4">
                  <div className="text-center mb-3">
                    <h2 className="text-xl text-text-2">
                      Clinical Notes ({dayjs().format('MM-D-YY')})
                    </h2>
                  </div>

                   {clinicalNotes.length !== 0 ? (
                    clinicalNotes.map((note, key) => {
                      return (
                        <div>
                          <p>
                            {key + 1}){' ' + note.clinicalNotesBody}
                          </p>

                          <div className="flex flex-col lg:flex-row mb-3">
                            <div className="w-1/2">
                              Signature: {note.clinicalNotesSignature}
                            </div>
                            <div className="w-1/2 text-right">
                              Date:{' '}
                              {dayjs(note.clinicalNotesDate).format('MM-D-YY')}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center mb-3">No Notes for today</div>
                  )}
                </div> */}



                <div style={{
                  width:'60%'
                }}>
                    <>
                    <div 
                    style={{
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'space-between',
                      marginBottom:'2rem'
                    }}
                    >
                      <button
                        className="focus:outline-none bg-background-23 text-text-3 p-1 px-2 rounded-md"
                        styke={{
                          color:'#FDF8F8'
                        }}
                        onClick={() =>
                          setEditClinicNote({
                            ...editClinicNote,
                            clinicalNotesBody: 'This is a template',
                          })
                        }
                      >
                        {translate('template')}
                      </button>
                        <div 
                        style={{
                          color:'#F2F6F7',
                          // fontSize:'3vh',
                          opacity:'0.7'
                        }}
                        >
                          {translate('clinical_notes')} ({dayjs().format('MM-D-YY')})
                        </div>
                      {/* <h2 className="text-xl mr-2 text-text-2">Add New Note</h2> */}
                      
                      {
                        showEdit ?
                      <div>
                        {editMode ? (
                          <button
                            className="focus:outline-none"
                            onClick={saveClinicalNotes}
                          >
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="#13c3b2"
                                d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
                              />
                            </svg>
                          </button>
                        ) : (
                          <button
                            className="focus:outline-none"
                            onClick={() => setEditMode(true)}
                          >
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="#13c3b2"
                                d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                      :
                      <div style={{cursor:'pointer',color:'#13c3b2'}} onClick={addNewNote}>{translate('add')}</div>
                      } 
                    </div>
                    <textarea
                      className="w-full resize-none focus:outline-none bg-background-5 text-text-3"
                      name="clinical-notes"
                      id="clinical-notes"
                      disabled={!editMode}
                      value={
                        editClinicNote.clinicalNotesBody
                          ? editClinicNote.clinicalNotesBody
                          : ''
                      }
                      onChange={event =>
                        setEditClinicNote({
                          ...editClinicNote,
                          clinicalNotesBody: event.target.value,
                        })
                      }
                      cols="30"
                      rows="9"
                      style={{
                        backgroundImage:
                          'repeating-linear-gradient(#1a2d3ea9, #1a2d3ea9 30px, 30px,  rgb(154 169 182 / 66%) 32px, #1a2d3ea9 31px)',
                        backgroundAttachment: 'local',
                        fontSize: '1.3rem',
                        padding: '4px',
                      }}
                    ></textarea>
                    <div className="grid grid-cols-2 gap-20 mt-8">
                      <input
                        value={
                          editClinicNote.clinicalNotesSignature
                            ? editClinicNote.clinicalNotesSignature
                            : ''
                        }
                        disabled={!editMode}
                        onChange={event =>
                          setEditClinicNote({
                            ...editClinicNote,
                            clinicalNotesSignature: event.target.value,
                          })
                        }
                        style={{
                          color:'#F6F7FA',
                          opacity:'0.4'
                        }}
                        className="px-2 py-1 w-full bg-background-6 font-bold text-text-3 focus:outline-none"
                        placeholder={translate('signature') + ":"}
                        type="text"
                      />
                      <input
                        placeholder={`${translate('date')}:`}
                        type={inputDate}
                        onClick={()=>setInputDate('date')} 
                        value={
                          editClinicNote.clinicalNotesDate
                            ? editClinicNote.clinicalNotesDate
                            : ''
                        }
                        disabled={!editMode}
                        onChange={event =>{
                          setEditClinicNote({
                            ...editClinicNote,
                            clinicalNotesDate: event.target.value,
                          })
                        }}
                        style={{
                          color:'#F6F7FA',
                          opacity:'0.4'
                        }}
                        className="px-2 py-1 w-full bg-background-6 font-bold focus:outline-none"
                      />
                    </div>
                    </>
                </div>

              </div>
            </div>
        </section>
      </main>
    </>
    </div>
  )
}

export default ClinicalNotes