import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import SettingsIcon from "../assets/images/GearIcon.png"
import DownIcon from "../assets/images/downArrow.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useUserContext } from "../contexts/userContext";
import { useTranslation } from "../contexts/TranslationContext";

const sidebarNestedRoutes = {
  cogIconLinks: ['/admin/profile',
  '/admin/payment',
  '/admin/staff',
  '/admin/plan',
  '/admin/agreements'
],
  groSolutionLinks : [
  '/admin/procedure',
  '/admin/medication',
  '/admin/products',
  '/admin/schedule',
]
}


const email = localStorage.getItem('email');
export default function AdminSidebar() {
  const location = useLocation()
  const {isSalonApp} = useUserContext()
  const {shouldShowCogLinks, shouldShowGroSolutionLinks} = getSubLinkDisplayState(location)
const { translate } = useTranslation()
  const [showExtraLinks, setShowExtrLinks] = useState(shouldShowCogLinks)
  const [showGROSubLinks, setShowGROSubLinks] = useState(shouldShowGroSolutionLinks)


  return (
    <aside className="min-h-full w-52 hidden lg:flex flex-col border-r-2 border-gray-700 text-lg font-font-2 font-bold tracking-widest px-5">
      <ul className="mt-16 grid gap-3 text-text-2">


        <li className="flex items-center whitespace-nowrap">
          <NavLink exact to="/admin/solution" activeClassName="text-accent-3">
            {translate('gro_solution')}
          </NavLink>

        <img src={DownIcon} className="h-5 w-5 ml-3 cursor-pointer" onClick={() => setShowGROSubLinks(prev => !prev)}/>
        </li>

        {!!showGROSubLinks && (
           <div className="grid gap-2 text-sm font-semibold">
           <li>
             <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/procedure" activeClassName="text-text-3">
               {translate('procedures')}
             </NavLink>
           </li>
           <li>
             <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/medication" activeClassName="text-text-3">
               {translate('medication')}
             </NavLink>
           </li>
           <li>
             <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/products" activeClassName="text-text-3">
               {translate('products')}
             </NavLink>
           </li>
           <li>
             <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/schedule" activeClassName="text-text-3">
               {translate('schedule')}
             </NavLink>
           </li>
         </div>
        )}

        <li>
          <NavLink exact to="/admin/market" activeClassName="text-text-3">
            {translate('gro_market')}
          </NavLink>
        </li>

        <li>
          <NavLink exact to="/admin/training" activeClassName="text-text-3">
            {translate('training')}
          </NavLink>
        </li>


        <li>
          <NavLink exact to="/admin/support" activeClassName="text-text-3">
            {translate('support')}
          </NavLink>
        </li>


        <li>
          <NavLink exact to="/admin/documents" activeClassName="text-text-3">
            {translate('documents')}
          </NavLink>
        </li>

        <li className="flex flex-col mt-2">
          <img src={SettingsIcon} className="h-6 w-6 mb-3 transition cursor-pointer" onClick={() => setShowExtrLinks(prev => !prev)} style={{transform: showExtraLinks ? 'rotate(120deg)' : 'rotate(0deg)'}}/>
          {showExtraLinks ? (
            <div className="flex items-center">
            <div className="border-l-2 w-4 border-gray-600" style={{height: '90%'}}>
            </div>
            <div className="grid gap-2 text-sm font-semibold">
              <li>
                <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/profile" activeClassName="text-text-3">
                {isSalonApp ? "Salon Info" : "Practise Info"} 
                </NavLink>
              </li>
              <li>
                <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/staff" activeClassName="text-text-3">
                  {isSalonApp ? translate('stylist') : translate('staff')}
                </NavLink>
              </li>
              <li>
                <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/payment" activeClassName="text-text-3">
                  {translate('payment')}
                </NavLink>
              </li>
              <li>
                <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/plan" activeClassName="text-text-3">
                  {translate('gro_plan')}
                </NavLink>
              </li>
               <li>
                <NavLink className="text-text-1 hover:text-text-3" exact to="/admin/agreements" activeClassName="text-text-3">
                  {translate('agreements')}
                </NavLink>
              </li>
            </div>
          </div>
            ) : ("")}
        </li>



        {/* older links */}


        {/* <li>
          <NavLink exact to="/admin/profile" activeClassName="text-accent-1">
            Profile
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink exact to="/admin/schedule" activeClassName="text-accent-1">
            Schedule
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/procedure" activeClassName="text-accent-1">
            Procedure
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/medication" activeClassName="text-accent-1">
            Medication
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/products" activeClassName="text-accent-1">
            Products
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/admin/payment" activeClassName="text-accent-1">
            Payment
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/documents" activeClassName="text-accent-1">
            Documents
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/admin/agreements" activeClassName="text-accent-1">
            Agreements
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/admin/support" activeClassName="text-accent-1">
            Support
          </NavLink>
        </li> */}

        {/* <li>
          <NavLink to="/admin/training" activeClassName="text-accent-1">
            Training
          </NavLink>
        </li> */}

        {/* <li>
          <NavLink to="/admin/notifications" activeClassName="text-accent-1">
            Notifications
          </NavLink>
        </li> */}

        <li>
          <NavLink to="/admin/support-tickets" activeClassName="text-accent-1">
            {translate('support_tickets')}
          </NavLink>
        </li>

        {/* {email && ['admin@gmail.com'].includes(email) && 
          <li>
            <NavLink to="/admin/control-panel" activeClassName="text-accent-1">
              Control Panel
            </NavLink>
          </li>
        } */}
      </ul>
    </aside>
  );
}

const getSubLinkDisplayState = (location) => {

  let shouldShowCogLinks = sidebarNestedRoutes.cogIconLinks.find(item => location.pathname.indexOf(item) > -1);
  let shouldShowGroSolutionLinks = sidebarNestedRoutes.groSolutionLinks.find(item => location.pathname.indexOf(item) > -1);

  return {
    shouldShowCogLinks : shouldShowCogLinks ? true : false,
    shouldShowGroSolutionLinks : shouldShowGroSolutionLinks ? true : false
  }
}