import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useUserContext } from './userContext'

const AuthContext = createContext({})

export const useAuthContext = () => {
    return useContext(AuthContext)
}

export const AuthContextProvider = ({ children }) => {

    // Contexts and states ---------------------------------------------------------------------------


    const history = useHistory()
    const {user, staff, isSalonApp} = useUserContext()

    const isUserAgreementRoute = useRouteMatch("/onboarding/user-agreement")
    const isHipaaAgreementRoute = useRouteMatch("/onboarding/hipaa-agreement")



    // UseEffect ---------------------------------------------------------------------------

    
    useEffect(() => {
        if (!user) return;
        if (!isSalonApp) {
            if (typeof user?.user_agreement_pdf === 'object') {
                return history.replace("/onboarding/user-agreement")
            }
            if (typeof user?.hipaa_agreement_pdf === 'object') {
                return history.replace("/onboarding/hipaa-agreement")
            }
            if (!user?.phone?.length) {
                return history.replace("/onboarding/md_details")
            }
        }
        if (staff && !staff?.length) {
            return history.replace(`/onboarding/staff-setup`)
        }
    }, [user, staff, isSalonApp]);


    // JSX ---------------------------------------------------------------------------

    return (
        <AuthContext.Provider>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext