import { React, useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import dayjs from 'dayjs';
import { useTranslation } from "../contexts/TranslationContext";

export default function HistoryTreatment() {
  const { id } = useParams();
  const { translate } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [result, setResult] = useState([]);
  const [editClinicNote, setEditClinicNote] = useState([
    clinicalNotesBody => '',
    clinicalNotesSignature => '',
    clinicalNotesDate => '',
    patient_id => id,
  ]);
  const [editMode, setEditMode] = useState(false);
  const [current, setCurrent] = useState(0);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const saveClinicalNotes = () => {
    var body = editClinicNote;
    body['patient_id'] = id;
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/clinical-notes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          fetchHistory();
          setEditClinicNote([
            clinicalNotesBody => '',
            clinicalNotesSignature => '',
            clinicalNotesDate => '',
          ]);
          setEditMode(false);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  };

  const fetchHistory = () => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setResult(response.result);
          setCurrent(response.result.length - 1);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  };

  useEffect(fetchHistory, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={2} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section>
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
            <NavLink
              exact
              to={`/patient/treatment/today/${id}`}
              activeClassName="text-text-2"
            >
              {translate('todays_treatment_plan')} ({dayjs().format('MM-DD-YY')})
            </NavLink>
            <div className="mx-8 my-2 w-2 h-2 bg-background-23 rounded-full"></div>
            <NavLink
              exact
              to={`/patient/treatment/history/${id}`}
              activeClassName="text-text-2"
            >
              {translate('treatment_history')}
            </NavLink>
          </div>
          {result.length > 0 ? (
            <div
              className="mt-8 bg-background-5 p-4 flex flex-col"
              style={{ height: 'var(--height-1)', overflowY: 'auto' }}
            >
              <div className="flex flex-col lg:flex-row mt-4 py-12">
                <div className="w-3/5 pr-8">
                  <div className="flex items-center justify-center mb-12">
                    <button
                      className="focus:outline-none"
                      onClick={() => {
                        setCurrent(Math.max(0, current - 1));
                      }}
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 512"
                      >
                        <path
                          fill="#bfc5ca"
                          d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                        />
                      </svg>
                    </button>
                    <h2 className="mx-4 text-lg text-text-2 text-center">
                      {dayjs(result[current].date).format('MMMM, D, YYYY')}
                    </h2>
                    <button
                      className="focus:outline-none"
                      onClick={() => {
                        setCurrent(Math.min(result.length - 1, current + 1));
                      }}
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 512"
                      >
                        <path
                          fill=" #bfc5ca"
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                  </div>
                  {result[current]['plans'] &&
                  result[current]['plans'].length > 0 ? (
                    result[current]['plans'].map(procedure => {
                      // return ((plan.procedures && plan.procedures.length > 0)? plan.procedures.map((procedure) => {
                      return (
                        <div
                          key={procedure.id}
                          className="mt-4 flex items-center"
                        >
                          <div className="grid flex-grow grid-cols-9 text-center text-text-3 w-3/5 font-font-2 font-normal">
                            <h2 className="px-2 py-1 bg-background-6 font-bold">
                              {procedure.type}
                            </h2>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.brand}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.bloodV}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.plasmaV}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.plateletC}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.injectionDepth}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.area}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.interval}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.tx}
                            </p>
                          </div>
                          <div className="flex">
                            <button className="ml-4 focus:outline-none">
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill={
                                    procedure.has_completed
                                      ? '#13c3b2'
                                      : '#bec4ca'
                                  }
                                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                                />
                              </svg>
                            </button>
                            <button className="ml-4 focus:outline-none">
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                              >
                                <path
                                  fill={
                                    procedure.paymentCompleted
                                      ? '#13c3b2'
                                      : '#bec4ca'
                                  }
                                  d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
                                />
                              </svg>
                            </button>
                            <button className="ml-4 focus:outline-none">
                              <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#13c3b2"
                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      );
                      // }) : '');
                    })
                  ) : (
                    <h2 className="mt-8 text-center">
                      {translate('no_treatment_plans_for_date')}
                    </h2>
                  )}
                </div>
                <div className="w-2/5">
                  <div className="text-center mb-3">
                    <h2 className="text-xl text-text-2">
                      {translate('clinical_notes')} (
                      {dayjs(result[current].date).format('MM-D-YY')})
                    </h2>
                  </div>

                  {result[current]['notes'] &&
                  result[current]['notes'].length > 0 ? (
                    result[current]['notes'].map((note, key) => {
                      return (
                        <div key={'notes' + note.id}>
                          <p>
                            {key + 1}){' ' + note.clinicalNotesBody}
                          </p>

                          <div className="flex flex-col lg:flex-row mb-3">
                            <div className="w-1/2">
                              {translate('signature')}: {note.clinicalNotesSignature}
                            </div>
                            <div className="w-1/2 text-right">
                              {translate('date')}:{' '}
                              {dayjs(note.clinicalNotesDate).format('MM-D-YY')}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center mb-3">
                      {translate('no_notes_for_date')}
                    </div>
                  )}
                  <div className="flex items-center justify-between text-center">
                    <button
                      className="focus:outline-none bg-background-23 text-text-3 p-1 px-2 rounded-md"
                      onClick={() =>
                        setEditClinicNote({
                          ...editClinicNote,
                          clinicalNotesBody: 'This is a template',
                        })
                      }
                    >
                      {translate('template')}
                    </button>
                    <h2 className="text-xl mr-2 text-text-2">{translate('add_new_note')}</h2>
                    {editMode ? (
                      <button
                        className="focus:outline-none"
                        onClick={saveClinicalNotes}
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="#13c3b2"
                            d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
                          />
                        </svg>
                      </button>
                    ) : (
                      <button
                        className="focus:outline-none"
                        onClick={() => setEditMode(true)}
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="#13c3b2"
                            d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div
                    class="flex items-center text-white text-sm font-bold px-4 py-3 my-3"
                    style={{ backgroundColor: '#6E82A0' }}
                    role="alert"
                  >
                    <svg
                      class="fill-current w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                    </svg>
                    <p>
                      {translate('note_edit_warning')}
                    </p>
                  </div>
                  {/* Will need these styles for lines after each row
                      .notes {
                            background-attachment: local;
                            background-image:
                                linear-gradient(to right, white 10px, transparent 10px),
                                linear-gradient(to left, white 10px, transparent 10px),
                                repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
                            line-height: 31px;
                            padding: 8px 10px;
                          } */}
                  <textarea
                    className="w-full resize-none focus:outline-none bg-background-5 text-text-3"
                    name="clinical-notes"
                    id="clinical-notes"
                    disabled={!editMode}
                    value={
                      editClinicNote.clinicalNotesBody
                        ? editClinicNote.clinicalNotesBody
                        : ''
                    }
                    onChange={event =>
                      setEditClinicNote({
                        ...editClinicNote,
                        clinicalNotesBody: event.target.value,
                      })
                    }
                    cols="30"
                    rows="10"
                    style={{
                      backgroundImage:
                        'repeating-linear-gradient(#1a2d3ea9, #1a2d3ea9 30px, 30px,  rgb(154 169 182 / 66%) 32px, #1a2d3ea9 31px)',
                      backgroundAttachment: 'local',
                      fontSize: '1.3rem',
                      padding: '4px',
                    }}
                  ></textarea>
                  <div className="grid grid-cols-2 gap-20 mt-12">
                    <input
                      value={
                        editClinicNote.clinicalNotesSignature
                          ? editClinicNote.clinicalNotesSignature
                          : ''
                      }
                      disabled={!editMode}
                      onChange={event =>
                        setEditClinicNote({
                          ...editClinicNote,
                          clinicalNotesSignature: event.target.value,
                        })
                      }
                      className="px-2 py-1 w-full bg-background-6 font-bold text-text-3 focus:outline-none"
                      placeholder={translate('signature') + ":"}
                      type="text"
                    />
                    <input
                      type="date"
                      value={
                        editClinicNote.clinicalNotesDate
                          ? editClinicNote.clinicalNotesDate
                          : ''
                      }
                      disabled={!editMode}
                      onChange={event =>
                        setEditClinicNote({
                          ...editClinicNote,
                          clinicalNotesDate: event.target.value,
                        })
                      }
                      placeholder={translate('date') + ":"}
                      className="px-2 py-1 w-full bg-background-6 font-bold text-text-3 focus:outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="mt-8 bg-background-5 p-4 flex flex-col"
              style={{ height: 'var(--height-1)', overflowY: 'auto' }}
            >
              <h2 className="mt-8 text-center">
                {translate('no_treatment_plans_notes_yet')}
              </h2>
            </div>
          )}
        </section>
      </main>
      <Loading message={translate('loading_treatment_plan_history')} ready={ready} />
    </>
  );
}
