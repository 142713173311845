import React, { memo, useState } from 'react'

const CustomProduct = (props) => {
    const { product } = props;

    const [showDescription, setShowDescription] = useState(false)

    const handleToggleDescription = () => {
        setShowDescription(prev => !prev)
    }

    return (
        <div style={{
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            display: "flex",
            flexDirection: 'column', 
            gap: '15px',
            marginBottom: '2.5rem'
        }}>
            <img 
                style={{
                    width: '100%',
                    height: 'auto'
                }} 
                src={product?.img} 
                alt={product?.title} 
            />
            <h3 style={{
                textAlign: 'center',
                color: '#4a4a4a',
                fontWeight: 'bold',
                fontSize: '25px',
                lineHeight: '1.2'
            }}>
                {product?.title}
            </h3>
            <p style={{
                textAlign: 'center',
                fontSize: '17px',
                fontWeight: 'bold',
                color: '#4a4a4a',
                fontStyle: 'italic'
            }}>
                {product?.price}
            </p>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <a 
                    href={product?.link} 
                    target='_blank' 
                    style={{
                        color: "white",
                        padding: "8px 22px",
                        fontSize: "18px",
                        backgroundColor: "#f4831f",
                        lineHeight: "1.2",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "8px",
                        margin: "0px",
                        fontWeight: "400",
                        "&:hover": {
                          "background-color": "#f4831f"
                        }
                    }}
                    rel="noreferrer"
                >
                    Buy now
                </a>
                <div style={{
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '18px',
                    fontWeight: '400',
                }}>
                    More Info
                    <svg
                        onClick={handleToggleDescription}
                        style={{
                            cursor: 'pointer',
                            transform: showDescription ? 'rotate(0deg)' : 'rotate(180deg)'
                        }}
                        fill="#ffffff"
                        height="25px"
                        width="25px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/10000/svg"
                        viewBox="-300.3 -300.3 930.60 930.60"
                        stroke="#ffffff"
                        stroke-width="33"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                            <rect x="-300.3" y="-300.3" width="930.60" height="930.60" rx="465.3" fill="#f4831f" strokewidth="0" />
                        </g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="2.64" />
                        <g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /> </g>
                    </svg>
                </div>
            </div>
            {showDescription && (
                <div style={{
                    backgroundColor: '#f4831f',
                    fontSize: '18px',
                    padding: '32px 16px',
                    color: '#efefef',       
                    textAlign: 'center',
                    fontWeight: '400',
                    whiteSpace: "pre-line"
                }}>
                    {product?.description}
                </div>
            )}
        </div>
    )
}

export default memo(CustomProduct)