import React from 'react'
import { useUserContext } from '../../contexts/userContext'
import { getLogo } from '../../utlis/themeHelpers'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StripeCheckout = ({ message, onboardingBaseUrl, setError, setMessage }) => {

    const { isSalonApp } = useUserContext();
    const history = useHistory()

    return (
        <div className="my-auto flex flex-col">
            <img className="h-20 mx-auto mb-12" src={getLogo(isSalonApp)} alt="GRO Track" />
            {message && <p className="text-center text-red-600">{message}</p>}
            <p className="mt-4 text-center">
                <button
                    className="h-20 w-20 rounded-full bg-button-5 text-text-3 cursor-pointer focus:outline-none"
                    onClick={() => {
                        history.push(`${onboardingBaseUrl}`)
                        // setActiveScreen("signup");
                        setError("");
                        setMessage("");
                    }}
                >
                    Back
                </button>
            </p>
        </div>
    )
}

export default StripeCheckout