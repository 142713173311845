import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "./Loading";
import Logo from "../assets/images/logo.png";
import Iframe from "react-iframe";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

const AdminAgreements = () => {
  const { translate } = useTranslation();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);
  const [physician, setPhysician] = useState(null);
  const [pdfType, setPdfType] = useState("userAgreement");
  const [pdfs, setPdfs] = useState({
    userAgreement: null,
    hipaaAgreement: null,
  });

  const getAdminProfile = () => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPhysician(response.adminProfile);
          getDocuments();
        } else {
          setMessage(response.message);
        }
        // setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage("Some Error Occured. Please Try Again Later");
        setReady(true);
      });
  };

  const getDocuments = () => {
    // setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/documents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPdfs({
            userAgreement: response.user_agreement_pdf,
            hipaaAgreement: response.hipaa_agreement_pdf,
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  useEffect(() => {
    getAdminProfile();
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}
      >
        <AdminHeader physician={physician} />

        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}

        <section className="flex">
          <AdminSidebar />
          <div
            className="w-full p-4 flex flex-col items-center justify-center"
            style={{ minHeight: "calc(100vh - 138px)" }}
          >
            <div className="mb-4 flex justify-center">
              <button
                className={`${
                  pdfType === "userAgreement"
                    ? `bg-background-23 text-white`
                    : `bg-transparent text-accent-1 border border-accent-1`
                }  mx-2  focus:outline-none  px-16 py-3 rounded text-xl`}
                onClick={(e) => setPdfType("userAgreement")}
              >
                {translate('user_agreement')}
              </button>
              <button
                className={`${
                  pdfType !== "userAgreement"
                    ? `bg-background-23 text-white`
                    : `bg-transparent text-accent-1 border border-accent-1`
                } mx-2  focus:outline-none  px-16 py-3 rounded text-xl`}
                onClick={(e) => setPdfType("hipaaAgreement")}
              >
                {translate('hipaa_agreement')}
              </button>
            </div>

            <Iframe
              url={pdfs[pdfType]}
              width="80%"
              height="650px"
              display="block"
            />
          </div>
        </section>
      </main>
      <Loading message={translate('loading_details')} ready={ready} />
    </>
  );
};

export default AdminAgreements;
