import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientSidebar from '../components/PatientSidebar';
import ViewExamData from '../components/ViewExamData';
import dayjs from 'dayjs';
import { useTranslation } from '../contexts/TranslationContext';

export default function BloodWorkExam() {
  function renderExamData(value) {
    return (
      <>
        {value && value.length > 0 && (
          <>
            {value.map((data, index) => {
              return (
                <ViewExamData
                  key={index}
                  editBloodworkExam={editBloodworkExam}
                  setEditBloodworkExam={setEditBloodworkExam}
                  index={index}
                  id={data.id}
                  data={data}
                  editMode={editMode}
                />
              );
            })}
          </>
        )}
      </>
    );
  }

  const initialData = {
    date: dayjs(new Date()).format('YYYY-MM-DD'),
    cbc: '',
    platelet_count: '',
    estorgen: '',
    testistron: '',
    thyroid: '',
    iron: '',
  };

  const isBloodworkExamValid = () => {
    return true;
  };

  const isExamOpen = () => {
    return true;
  };

  const [editMode, setEditMode] = useState(false);
  const [examDrop, setExamDrop] = useState(true);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthDropdown, setHealthDropdown] = useState(false);
  const [ready, setReady] = useState(false);
  const { id } = useParams();
  const [bloodworkExam, setBloodworkExam] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editBloodworkExam, setEditBloodworkExam] = useState(null);
  const [dispayBloodworkExam, setDisplayBloodworkExam] = useState(null);
  const [unsavedData, setUnsavedData] = useState(null);
  const [message, setMessage] = useState('');

  const [patientReferrals, setPatientReferrals] = useState(null);
  const { translate } = useTranslation()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const addNewData = () => {
    if (isBloodworkExamValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/${id}/blood_work_test_exam`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(editBloodworkExam),
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            setBloodworkExam(response);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/blood_work_test_exam`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setBloodworkExam(response);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    let OldValueData =
      bloodworkExam &&
      bloodworkExam.data.map(data => {
        return {
          date: data?.date,
          cbc: data?.cbc,
          platelet_count: data?.platelet_count,
          estorgen: data?.estorgen,
          testistron: data?.testistron,
          thyroid: data?.thyroid,
          iron: data?.iron,
        };
      });
    setDisplayBloodworkExam(OldValueData);
    setEditBloodworkExam({ blood_work_test: OldValueData });
  }, [bloodworkExam]);

  useEffect(() => {
    let OldValueData =
      bloodworkExam &&
      bloodworkExam.data.map(data => {
        return {
          date: data?.date,
          cbc: data?.cbc,
          platelet_count: data?.platelet_count,
          estorgen: data?.estorgen,
          testistron: data?.testistron,
          thyroid: data?.thyroid,
          iron: data?.iron,
        };
      });
    setUnsavedData(OldValueData);
  }, [bloodworkExam]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            isExamOpen={isExamOpen}
            id={id}
          />
          {bloodworkExam && (
            <>
              <div style={{ width: '65rem' }} className="mx-auto">
                {patientReferrals === null ? (
                  <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                    {`${firstName} ${lastName}`}
                  </h1>
                ) : (
                  <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                    {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                  </h1>
                )}
                <form
                  onSubmit={event => {
                    event.preventDefault();
                    addNewData();
                  }}
                  className="lg:mx-16 flex flex-col"
                >
                  <div className="mt-4 flex justify-between items-center">
                    <button
                      type="button"
                      className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                      onClick={() => {
                        setEditMode(!editMode);
                      }}
                    >
                      {editMode ? 'Cancel' : 'Edit'}
                    </button>
                    <p className="mx-4">{translate('exam')}</p>
                    <button
                      className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                      disabled={!editMode}
                    >
                      {translate('save')}
                    </button>
                  </div>
                  <section className="mt-8">
                    <div className="flex items-center">
                      <p className="text-xl mr-4 text-text-2">
                        {translate('blood_work_and_tests')}
                      </p>
                      <button
                        className="text-3xl text-text-2"
                        onClick={e => {
                          e.preventDefault();
                          let newBloodWork = initialData;
                          setDisplayBloodworkExam([
                            ...dispayBloodworkExam,
                            newBloodWork,
                          ]);
                          let newEditArray =
                            editBloodworkExam['blood_work_test'];
                          setEditBloodworkExam({
                            blood_work_test: newEditArray.concat(newBloodWork),
                          });
                        }}
                        disabled={!editMode}
                      >
                        +
                      </button>
                    </div>
                    <div className="mt-6 border-t-2 border-background-2 pt-4">
                      <div className="flex items-center w-full">
                        <div
                          className="flex flex-col justify-between"
                          style={{ height: '380px' }}
                        >
                          <div></div>
                          <p className="mt-8">{translate('cbc')}</p>
                          <p className="text-sm">{translate('platelet_count')}</p>
                          <p>{translate('estrogen')}</p>
                          <p>{translate('testosterone')}</p>
                          <p>{translate('thyroid')}</p>
                          <p className="mb-2">{translate('iron')}</p>
                        </div>
                        <div
                          className="p-2 bg-background-1 overflow-hidden"
                          style={{ height: '450px' }}
                        >
                          <div className="grid grid-flow-col gap-2 p-4 overflow-x-auto h-full w-full">
                            {editMode
                              ? renderExamData(dispayBloodworkExam)
                              : renderExamData(unsavedData)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </div>
            </>
          )}
        </section>
      </main>
      <Loading message={translate('loading_patient_blood_work_and_tests')} ready={ready} />
    </>
  );
}
