import { React, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Loading from '../views/Loading';
import { useUserContext } from '../contexts/userContext';
import { getLogo } from '../utlis/themeHelpers';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from '../contexts/TranslationContext';

export const AdminSignup = () => {

    const history = useHistory();
    const location = useLocation();
    const { translate } = useTranslation()

    const { isSalonApp, handleAdminLogin } = useUserContext()

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [ready, setReady] = useState(true);

    const isOnboardingMode = new URLSearchParams(location.search).get("isOnboarding") === 'true';

    const save = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/auth-secondary`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem("access_token")}`
            },
            body: JSON.stringify({
                password: password,
                password_confirmation: confirmPassword
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    handleAdminLogin(true)
                    setTimeout(() => {history.push(isOnboardingMode ?  "/admin/training" : "/")}, 500);
                } else {
                    setMessage(response.message);
                }
            })
            .catch(error => {
                console.error(error);
                setMessage(translate('error_occurred'));
            }).finally(() => {
                setReady(true);
            });
    };

    return (
        <>
            <main className={`flex flex-col p-4 font-font-2 ${ready ? '' : 'hidden'}`}>
                <NavLink to="/" className="mx-auto mt-16">
                    <img className='h-14' src={getLogo(isSalonApp)} alt="GRO Track" />
                </NavLink>
                <div className="flex flex-col my-auto">
                    <h1 className='text-center text-xl mb-8'>{translate('add_admin_area_password')}</h1>
                    {message && <p className="text-center text-red-600">{message}</p>}
                    <form
                        className="mt-4 flex flex-col items-center"
                        onSubmit={event => {
                            event.preventDefault();
                            save();
                        }}
                    >
                        <input
                            type="password"
                            className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                            placeholder={translate('password')}
                            value={password}
                            required
                            minLength="8"
                            onChange={event => {
                                setPassword(event.target.value);
                            }}
                        />
                        <input
                            type="password"
                            className="mt-8 px-2 py-1 bg-background-2 text-text-2 text-center rounded-lg focus:outline-none"
                            placeholder={translate('confirm_password')}
                            value={confirmPassword}
                            required
                            minLength="8"
                            onChange={event => {
                                setConfirmPassword(event.target.value);
                            }}
                        />
                        <input
                            type="submit"
                            className="mt-8 h-20 w-20 text-lg rounded-full bg-background-23 text-text-3 cursor-pointer focus:outline-none"
                            value={translate('save')}
                        />
                    </form>
                </div>
            </main>
            <Loading
                message={'Saving password'}
                ready={ready}
            />
            <footer className="px-4 pt-4 pb-8 mx-12 border-t-2 border-accent-2 text-center">
                {translate('gro_technologies')}
            </footer>
        </>
    );
}
