import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientSidebar from '../components/PatientSidebar';
import dayjs from 'dayjs';
import ViewExamData from '../components/ViewExamData';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from '../contexts/TranslationContext';

export default function HairLossClassExam() {
  function renderExamData(value) {
    return (
      <>
        {value && value.length > 0 && (
          <>
            {value.map((data, index) => {
              return (
                <ViewExamData
                  key={index}
                  editHairLossClass={editHairLossClass}
                  setEditHairLossClass={setEditHairLossClass}
                  index={index}
                  id={data.id}
                  data={data}
                  editMode={editMode}
                />
              );
            })}
          </>
        )}
      </>
    );
  }
  const isExamOpen = () => {
    return true;
  };
  const { translate } = useTranslation()

  const [editMode, setEditMode] = useState(false);
  const [examDrop, setExamDrop] = useState(true);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthDropdown, setHealthDropdown] = useState(false);
  const [ready, setReady] = useState(false);
  const { id } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [hairLossClass, setHairLossClass] = useState(null);
  const [displayHairLossClass, setDisplayHairLossClass] = useState(null);
  const [editHairLossClass, setEditHairLossClass] = useState(null);
  const [unsavedData, setUnsavedData] = useState(null);
  const [message, setMessage] = useState('');
  const [patientReferrals, setPatientReferrals] = useState(null);
  const {isSalonApp} = useUserContext()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const initialData = {
    date: dayjs(new Date()).format('YYYY-MM-DD'),
    gro_scale: '',
    ludwig: '',
    savin: '',
    norwood: '',
  };

  const isHairLossClassValid = () => {
    return true;
  };

  const addNewData = () => {
    if (isHairLossClassValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/${id}/hair_loss_class_exam`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        body: JSON.stringify(editHairLossClass),
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            setHairLossClass(response);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/hair_loss_class_exam`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setHairLossClass(response);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    let CopyFetchData =
      hairLossClass &&
      hairLossClass.data.map(data => {
        return {
          date: data?.date,
          gro_scale: data?.gro_scale,
          ludwig: data?.ludwig,
          savin: data?.savin,
          norwood: data?.norwood,
        };
      });
    setDisplayHairLossClass(CopyFetchData);
    setEditHairLossClass({ hair_loss_class: CopyFetchData });
  }, [hairLossClass]);

  useEffect(() => {
    let OriginalData =
      hairLossClass &&
      hairLossClass.data.map((data, index) => {
        return {
          date: data?.date,
          gro_scale: data?.gro_scale,
          ludwig: data?.ludwig,
          savin: data?.savin,
          norwood: data?.norwood,
        };
      });
    setUnsavedData(OriginalData);
  }, [hairLossClass]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            id={id}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            isExamOpen={isExamOpen}
          />
          {hairLossClass && (
            <>
              <div style={{ width: '60rem' }} className="mx-auto">
                {patientReferrals === null ? (
                  <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                    {`${firstName} ${lastName}`}
                  </h1>
                ) : (
                  <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                    {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                  </h1>
                )}
                <form
                  onSubmit={event => {
                    event.preventDefault();
                    addNewData();
                  }}
                  className="lg:mx-16 flex flex-col"
                >
                  <div className="mt-4 flex justify-between items-center">
                    <button
                      type="button"
                      className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                      onClick={() => {
                        setEditMode(!editMode);
                      }}
                    >
                      {editMode ? translate('cancel') : translate('edit')}
                    </button>
                    <p className="mx-4">{translate('exam')}</p>
                    <button
                      className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                      disabled={!editMode}
                    >
                      {translate('save')}
                    </button>
                  </div>
                  <section className="mt-8">
                    <div className="flex items-center">
                      <p className="text-xl mr-4 text-text-2">
                        {translate('alopecia_classifications')}
                      </p>
                      <button
                        onClick={e => {
                          e.preventDefault();
                          let newHairLoss = initialData;
                          setDisplayHairLossClass([
                            ...displayHairLossClass,
                            newHairLoss,
                          ]);
                          let newEditArray =
                            editHairLossClass['hair_loss_class'];
                          setEditHairLossClass({
                            hair_loss_class: newEditArray.concat(newHairLoss),
                          });
                        }}
                        className="text-3xl text-text-2"
                        disabled={!editMode}
                      >
                        +
                      </button>
                    </div>
                    <div className="mt-6 border-t-2 border-background-2">
                      <div className="flex items-center w-full">
                        <div
                          style={{ height: '290px' }}
                          className="grid grid-rows-5 gap-2"
                        >
                          <div></div>
                          <p>{translate('groScale')}</p>
                          <p>{translate('ludwig')}</p>
                          <p>{translate('savin')}</p>
                          <p>{translate('norwood')}</p>
                        </div>
                        <div
                          className="p-2 bg-background-1 overflow-hidden"
                          style={{ height: '370px' }}
                        >
                          <div className="grid grid-flow-col gap-2 p-4 overflow-x-auto h-full w-full">
                            {editMode
                              ? renderExamData(displayHairLossClass)
                              : renderExamData(unsavedData)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </div>
            </>
          )}
        </section>
      </main>
      <Loading message={`${translate('loading')} ${isSalonApp ? translate('client'):  translate('patient')} ${translate('hair_loss_class')}`} ready={ready} />
    </>
  );
}
