import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosAdd } from "react-icons/io";
//import Loading from "../views/Loading";
// import ProcedureForm from '../components/ProcedureForm';
import ProductsForm from "./ProductsForm";
import ShowCases from "./ShowCases";
import { useTranslation } from "../contexts/TranslationContext";

function ProductTypes({
  openGroPlan,
  setOpenGroPlan,
  getTreatmentPlans,
  showArrows,
  setShowArrows,
  setReady,
}) {
  const ref = useRef();
  // const [message, setMessage] = useState("");
  // const [ready, setReady] = useState(false);
 const { translate } = useTranslation()
  const [productPdf, setProductPdf] = useState({ isOpen: false, data: null });
  const [productTypes, setProductTypes] = useState(null);
  const [productList, setProductList] = useState(productTypes);
  const [productForm, setProductForm] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(3);

  const [adminProducts, setAdminProducts] = useState(null);
  const [matchProduct, setMatchProduct] = useState(null);

  const [gender, setGender] = useState("All");
  const [age, setAge] = useState("All");

  const [openCases, setOpenCases] = useState(false);

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
  };

  useEffect(() => {
    let min = 0;
    let max = 200;
    if (age === "All") {
      min = 0;
      max = 200;
    } else if (age === "under 20") {
      min = 0;
      max = 20;
    } else if (age === "over 70") {
      min = 71;
      max = 200;
    } else {
      min = age.split("-")[0];
      max = age.split("-")[1];
    }

    setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/clinic/templates?gender=${
        gender === "Male" ? "M" : gender === "Female" ? "F" : gender
      }&min_age=${min}&max_age=${max}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          console.log(response);
          setProductTypes(response.treatment_product);
          setProductList([]);
          setAdminProducts(response.products);
        }
      })
      .catch((error) => {
        console.error(error);
        //setMessage("Some Error Occured. Please Try Again Later");
      })
      .finally(() => {
        setReady(true);
      });
  }, [gender, age]);

  const removeProduct = (obj) => {
    if (showArrows) {
      setShowArrows(!showArrows);
    }
    setShowArrows(!showArrows);
    if (productForm) {
      setProductForm(!productForm);
      setProductPdf({ isOpen: true, data: obj });
      return;
    }
    if (openCases) {
      setOpenCases(!openCases);
      setProductPdf({ isOpen: true, data: obj });
      return;
    }
    if (
      productList.length === 1 &&
      productList.map((item) => item.title === obj.title)
    ) {
      setProductList(productTypes);
      setProductPdf({ isOpen: false, data: null });
      setSlidesToShow(3);
      setShowArrows(!showArrows);
    } else {
      let newProductTypes = productTypes.filter(
        (item) => item.title === obj.title
      );
      setProductList(newProductTypes);
      setProductPdf({ isOpen: true, data: obj });
      setSlidesToShow(1);
    }
  };
  const openProduct = (obj) => {
    if (showArrows) {
      setShowArrows(!showArrows);
    }
    if (productPdf.isOpen) {
      setProductPdf({ isOpen: false, data: null });
      setProductForm(!productForm);
      return;
    }
    if (openCases) {
      setOpenCases(!openCases);
      setProductForm(!productForm);
      return;
    }
    if (
      productList.length === 1 &&
      productList.map((item) => item.title === obj.title)
    ) {
      setProductList(productTypes);
      setProductForm(!productForm);
      setSlidesToShow(3);
      setShowArrows(!showArrows);
    } else {
      let newProductTypes = productTypes.filter(
        (item) => item.title === obj.title
      );
      setProductList(newProductTypes);
      setProductForm(!productForm);
      setSlidesToShow(1);
    }

    const product = adminProducts.filter((item) => item.name === obj.title);
    setMatchProduct(product[0]);
  };

  const cases = (obj) => {
    if (showArrows) {
      setShowArrows(!showArrows);
    }
    if (productPdf.isOpen) {
      setProductPdf({ isOpen: false, data: null });
      setOpenCases(!openCases);
      return;
    }
    if (productForm) {
      setProductForm(!productForm);
      setOpenCases(!openCases);
      return;
    }
    if (
      productList.length === 1 &&
      productList.map((item) => item.title === obj.title)
    ) {
      setProductList(productTypes);
      setOpenCases(!openCases);
      setSlidesToShow(3);
      setShowArrows(!showArrows);
    } else {
      let newProductTypes = productTypes.filter(
        (item) => item.title === obj.title
      );
      setProductList(newProductTypes);
      setOpenCases(!openCases);
      setSlidesToShow(1);
    }
  };

  return (
    <>
      {/* <Loading message={"Loading Today's Treatment Plan"} ready={ready} /> */}

      <div style={{ width: "90%", margin: "0 auto" }}>
        
        {
          productList && 
          <div className="text-center">
            {translate('no_product_found')}
          </div>
        }
         
        <Slider ref={ref} {...settings}>
          {productList &&
            productList.map((item) => {
              return (
                <>
                  <div
                    style={{
                      margin: "0 2.4rem",
                      width: "16rem",
                      padding: "2rem 0",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        padding: "1rem 1rem",
                        color: "black",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "3rem",
                          height: "3rem",
                          border: "1px solid black",
                          borderRadius: "100%",
                          background:
                            item.cases > 100 && item.result > 20
                              ? "#00CCCC"
                              : "#BE5504",
                          fontSize: "0.5rem",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          top: "-1.5rem",
                          left: "-1.5rem",
                        }}
                      >
                        {item.result > 100 && item.cases > 20 ? (
                          <>
                            <div
                              style={{ fontSize: "1rem", lineHeight: "0.8rem" }}
                            >
                              GRO
                            </div>
                            <div>{translate('rated')}</div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{ fontSize: "1rem", lineHeight: "0.8rem" }}
                            >
                              GRO
                            </div>
                            <div>{translate('verified')}</div>
                          </>
                        )}
                      </div>

                      <div
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        {item.title}
                      </div>

                      <div>{item.sub_title}</div>

                      <img
                        onClick={() => removeProduct(item)}
                        src={item.image}
                        alt={""}
                        style={{
                          margin: "0.5rem 0",
                          cursor: "pointer",
                          width: "100%",
                          height: "10rem",
                          objectFit: "cover",
                        }}
                      />

                      <div
                        style={{
                          fontSize: "0.9rem",
                          opacity: "0.7",
                          padding: "0 1rem",
                        }}
                      >
                        {item.description}
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          padding: "0.5rem 0",
                          fontSize: "1.2rem",
                          fontWeight: "normal",
                          margin: "0.5rem 0",
                        }}
                      >
                        { translate('gro_score')}:{item.gro_score === 0 ? "NA" : item.gro_score }
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item.result !== 0 && (
                          <div style={{ margin: "1rem" }}>
                            <div style={{ opacity: "0.7" }}>{translate('results')}</div>
                            <div
                              style={{
                                background: "#B2FFFF",
                                width: "fit-content",
                                margin: "0 auto",
                                border: "1px solid black",
                                padding: "0.5rem",
                              }}
                            >
                              {item.result}
                            </div>
                          </div>
                        )}
                        <div
                          style={{ margin: "1rem", cursor: "pointer" }}
                          onClick={() => cases(item)}
                        >
                          <div style={{ opacity: "0.7", textTransform:"capitalize" }}>{translate('cases')}</div>
                          <div
                            style={{
                              background: "#00CCCC",
                              width: "fit-content",
                              margin: "0 auto",
                              border: "1px solid black",
                              padding: "0.5rem",
                            }}
                          >
                            {item.cases}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.9rem",
                        }}
                      >
                        <div style={{ textAlign: "center", width: "50%" }}>
                          <div style={{ textDecoration: "underline" }}>
                            {translate('gender')}
                          </div>
                          <select
                            id="gender"
                            value={gender}
                            style={{
                              outline: "none",
                              border: "none",
                              padding: "0.1rem",
                              marginBottom: "0.3rem",
                              backgroundColor: "rgba(255, 255, 255, 0)",
                              width: `${12 * gender.length + 10}px`,
                            }}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="All" selected>
                              {translate('all')}
                            </option>
                            <option value="Male">{translate('male')}</option>
                            <option value="Female">{translate('female')}</option>
                          </select>
                          {/* <div style={{ fontWeight: "normal" }}>{gender}</div> */}
                        </div>
                        <div style={{ textAlign: "center", width: "50%" }}>
                          <div style={{ textDecoration: "underline" }}>{translate(age)}</div>
                          <select
                            id="age"
                            value={age}
                            style={{
                              outline: "none",
                              border: "none",
                              padding: "0.1rem",
                              marginBottom: "0.3rem",
                              backgroundColor: "rgba(255, 255, 255, 0)",
                              width: `${11 * age.length + 10}px`,
                            }}
                            onChange={(e) => setAge(e.target.value)}
                          >
                            <option value="All" selected>
                              {translate('all')}
                            </option>
                            <option value="under 20">under 20</option>
                            <option value="21-20">21-20</option>
                            <option value="31-40">31-40</option>
                            <option value="41-50">41-50</option>
                            <option value="51-60">51-60</option>
                            <option value="61-70">61-70</option>
                            <option value="over 70">over 70</option>
                          </select>
                          {/* <div style={{ fontWeight: "normal" }}>{age}</div> */}
                        </div>
                      </div>

                      <IoIosAdd
                        onClick={() => openProduct(item)}
                        style={{
                          fontSize: "40px",
                          cursor: "pointer",
                          color: "#E8E8E8",
                          position: "absolute",
                          bottom: "-2.5rem",
                          left: "0",
                          right: "0",
                          margin: "0 auto",
                        }}
                      />
                    </div>
                  </div>

                  {productPdf.isOpen && (
                    <div
                      style={{
                        position: "absolute",
                        right: "2.4rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        backgroundColor: "#E8E8E8",
                        height: "88.7%",
                        width: "40rem",
                        boxSizing: "border-box",
                        padding: "0 1rem",
                      }}
                    >
                      <img
                        alt=""
                        src={productPdf.data.study_image}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}

                  {productForm && (
                    <ProductsForm
                      getTreatmentPlans={getTreatmentPlans}
                      item={item}
                      matchProduct={matchProduct}
                    />
                  )}

                  {openCases && <ShowCases item={item} />}
                </>
              );
            })}
        </Slider>
      </div>
    </>
  );
}

export default ProductTypes;
