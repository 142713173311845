import React, { useContext, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import CrossGrey from "../../assets/images/CrossGrey.svg";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import ImageLoader from '../ImageLoader';
import { ChangeAnalysisContext } from '../../views/OnePageConsult';
import dayjs from 'dayjs';
import { calculateGroIndexScore } from '../../utlis/calculations';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from '../../contexts/TranslationContext';

const subtypes = [
	"front",
	"crown",
	"vertex",
	"occipital",
	"left",
	"right"
]


const CloseupImageCompare = ({ analyses, setReady }) => {
	const history = useHistory();

    const { patient_id, analysis_id, subType } = useParams();
	const analysis_id_int = parseInt(analysis_id);
	const { translate } = useTranslation();

	const location = useLocation();
	const {state} = location;
	
	const [showMaskAssessment, setShowMaskAssessment] = useState(state?.showMaskAssessment)

	const { changeAnalysis } = useContext(ChangeAnalysisContext);

	const shouldShowTwoImages = window.innerWidth <= 640;
	const shouldShowOneImage = window.innerWidth <= 480;

	const shouldShowCarouselImage = (index) => {
		if(shouldShowOneImage) return (index === analysis_id_int);
		if(shouldShowTwoImages) return (index === analysis_id_int - 1 || index === analysis_id_int);
		return (index === analysis_id_int - 1 || index === analysis_id_int || index === analysis_id_int + 1);
	}
	
	const getStartingColumn = (index) => {
			if(shouldShowOneImage) return 1
			if(shouldShowTwoImages) return 7
			return 2
	}

	return (
		<div style={{flexGrow: 1}}>
			<div className='absolute top-5 right-5'>
				<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/${subType}/${showMaskAssessment ? 'hair_thinning' : 'hair_growth'}`}>
					<img src={CrossGrey} className='h-5 w-5' />
				</Link>
			</div>
			<div class="flex justify-center mb-3">
				<div className='relative px-14 text-center font-bold text-lg font-sans tracking-wider text-text-15'>
				<button
					className="text-2xl focus:outline-none absolute left-5 top-0"
					style={{transform: 'translate(0%, -4%)'}}
					onClick={function () {
						// changeAnalysis(history, 'next', analysis_id_int, analyses);
					}}
				>
					<img src={lessThanIcon} className='w-8 h-8' />
				</button>
				 {translate('hair_growth_tracking')}
				</div>
			
			</div>
			<div className='flex justify-center'>
				{subtypes.map((item, index) => {
					return <div key={index} className={`mt-1 text-center mx-2 font-normal text-base font-sans tracking-wider ${subType === item ? `opacity-80` : `opacity-40`} text-text-15 capitalize`}>
						<Link to={`/patient/${patient_id}/consult/${analysis_id}/compare/closeup/${item}`}>
							{item.replace(item[0], item[0].toUpperCase())}
						</Link>
					</div>
				})}
			</div>
			<div className='flex items-center justify-center relative'>
				<button
					className="text-2xl focus:outline-none left-0 top-1/2 absolute z-10"
					onClick={function () {
						changeAnalysis(history, 'prev', analysis_id_int, analyses);
					}}
					style={{transform: "translate(-10px, -40px)"}}
				>
					<img src={lessThanIcon} className='w-14 h-14' />
				</button>
				<div className='lg:mx-15 xl:mx-20 grid grid-cols-12 sm:grid-cols-3 jusitfy-center w-full'>
					
					{analyses.map((analysis, index) => {
						if(shouldShowCarouselImage(index)){
							const secondaryImage = analysis?.images?.closeup?.ml?.[subType];
							let overlayImage = null;
							if(!secondaryImage){
								overlayImage = null;
							} else if (secondaryImage?.length) {
								overlayImage = secondaryImage?.find(img => showMaskAssessment ? img?.paint === 7 : img?.paint === 3);
							} else {
								overlayImage = analysis?.images?.closeup?.ml?.[subType]
							}
							return (
								<div
									className={`w-full flex justify-center align-center col-span-12 xs:col-span-6 sm:col-span-1 flex-col`}
									style={(analysis_id_int === 0 && index === 0) ? {gridColumnStart: getStartingColumn()} : {}}
								>
										{analysis?.images?.closeup.raw && <div className='mb-10'><ImageLoader setReady={setReady} bordered={true} narrowImages={true} img={analysis?.images?.closeup?.raw?.[subType]} isCompareRoute={true} secondaryImg={overlayImage} showFlagIcon={true} showMaskAssessment={showMaskAssessment}/></div>}
										<p className='text-center font-bold text-xl leading-5 tracking-wider' style={{ color: '#707D87' }}>
											{dayjs
											.unix(analysis.created_at)
											.format("MM-DD-YY")}: 
											{ analyses?.[0].id !== analysis.id && analysis?.images?.closeup?.hair_growth?.[subType]?.percentage >= 0  ?
												<span className='text-center font-bold text-2xl leading-5 tracking-tighter opacity-80' style={{ color: '#F6F7FA' }}> 
													&nbsp;{calculateGroIndexScore(analysis?.images?.closeup?.hair_growth?.[subType]?.percentage, 'closeup')}
												</span>
											: ' N/A'}
										</p>
								</div>
							);
						} 
					})}
				</div>
				<button
					className="text-2xl focus:outline-none right-0 top-1/2 absolute z-10"
					style={{transform: "translate(10px, -40px)"}}
					onClick={function () {
						changeAnalysis(history, 'next', analysis_id_int, analyses);
					}}
				>
					<img src={greaterThanIcon} className='w-14 h-14' />
				</button>
			</div>
		</div>
	)
}

export default CloseupImageCompare