import { React } from "react";
import { useTranslation } from "../../contexts/TranslationContext";

export default function PresentationNavbar(props) {
  const { translate } = useTranslation();
  return (
    <nav
      className="mt-8 grid grid-cols-4 gap-12 font-normal font-font-2 text-center text-text-10"
      style={{ height: "62px" }}
    >
      <button
        className="flex items-center bg-background-5 p-2 border-b-2 border-background-6 focus:outline-none"
        onClick={() => props.setView("overview")}
      >
        <figure
          className={`rounded-full px-1 py-2 mx-2 ${
            props.tab === 1 ? "bg-text-9" : "bg-text-10"
          }`}
        >
          <svg
            className="h-4 w-6"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="2 2 20 20"
          >
            <g>
              <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
            </g>
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 1 && "text-text-9 font-bold"}`}>
          {translate('overview')}
        </p>
      </button>
      <button
        className="flex items-center bg-background-5 p-1 border-b-2 border-background-6 focus:outline-none"
        onClick={() => props.setView("global-images")}
      >
        <figure
          className={`rounded-full p-2 mx-2 ${
            props.tab === 2 ? "bg-text-9" : "bg-text-10"
          }`}
        >
          <svg
            className="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
          >
            <path d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z" />
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 2 && "text-text-9 font-bold"}`}>
          {translate('hair_images')}
        </p>
      </button>
      <button
        className="flex items-center bg-background-5 p-2 border-b-2 border-background-6 focus:outline-none"
        onClick={() => props.setView("hair-data")}
      >
        <figure
          className={`rounded-full p-1 mx-2 ${
            props.tab === 3 ? "bg-text-9" : "bg-text-10"
          }`}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-50 -40 350 350"
          >
            <path d="M106.68 259.73c-5.046-.449-10.406-1.742-16.601-4.005-9.523-3.479-16.26-7.765-23.86-15.178-10.406-10.15-16.158-20.134-20.6-35.753-.866-3.046-1.75-5.445-2.154-5.849-.63-.63-1.031-.667-4.955-.465-7.844.405-28.953 3.303-44.191 6.066-8.318 1.509-11.21 1.894-11.563 1.541-.391-.391-4.431-17.57-4.431-18.842 0-1.073 33.867-6.739 50.572-8.46 5.555-.572 20.234-1.217 27.971-1.229 3.98-.006 4.668.075 4.859.573.122.319.54 3.295.927 6.615 2.108 18.046 7.191 30.442 16.433 40.07 8.764 9.131 19.962 13.981 35.151 15.225 14.503 1.188 29.02-2.369 39.163-9.595 3.871-2.758 9.041-8.108 11.708-12.116 3.859-5.8 6.984-12.989 8.809-20.268 1.165-4.643 1.515-6.684 2.315-13.494.379-3.222.798-6.143.932-6.492.229-.596.861-.621 10.856-.42 11.779.236 20.004.842 32.153 2.367 14.75 1.852 40.372 6.44 40.372 7.23 0 1.525-4.055 18.483-4.535 18.962-.322.322-3.051-.099-13.986-2.157-8.431-1.587-21.048-3.425-31.75-4.625-10.336-1.159-14.701-1.339-15.242-.628-.185.243-.687 1.711-1.116 3.263-2.442 8.826-5.728 17.524-8.515 22.539-4.873 8.767-14.672 18.969-24.084 25.075-7.984 5.179-19.787 9.19-29.601 10.059-4.552.403-20.466.396-25.037-.011zm5.932-38.999c-12.142-2.194-21.869-10.164-27.899-22.858-3.171-6.676-3.871-9.942-4.485-20.926-.88-15.735 1.38-33.901 6.961-55.94 3.583-14.15 8.726-29.812 11.123-33.87l2.075-3.552c.485-.843 1.533-2.484 2.329-3.647s2.698-4.369 4.227-7.125 3.845-6.426 5.147-8.157 3.167-4.7 4.145-6.598c1.169-2.269 2.777-4.615 4.695-6.851 3.619-4.218 6.154-7.43 7.401-9.376.526-.82 2.33-2.96 4.008-4.754s3.695-4.22 4.481-5.39c1.364-2.032 4.02-4.826 7.841-8.248.97-.869 2.536-2.467 3.48-3.55 3.891-4.467 12.105-12.505 13.277-12.992.679-.282 1.87-.981 2.646-1.553 3.747-2.761 9.337-5.411 12.793-6.065l1.151-.218-.496.962c-2.431 4.72-10.299 24.075-13.083 32.183-14.299 41.647-18.755 80.997-13.401 118.36.306 2.134 1.441 8.246 2.523 13.582 2.928 14.445 2.902 14.286 3.142 19.226.461 9.49-2.128 17.563-7.992 24.921-5.062 6.352-14.427 11.325-23.773 12.625-3.642.506-8.928.427-12.316-.185z" />
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 3 && "text-text-9 font-bold"}`}>
          {translate('hair_data')}
        </p>
      </button>
      <button
        className="flex items-center bg-background-5 p-2 border-b-2 border-background-6 focus:outline-none"
        onClick={() => props.setView("hair-density-tracking")}
      >
        <figure
          className={`rounded-full p-1 mx-2 ${
            props.tab === 4 ? "bg-text-9" : "bg-text-10"
          }`}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
          </svg>
        </figure>
        <p className={`mx-auto ${props.tab === 4 && "text-text-9 font-bold"}`}>
          {translate('grotracker')}
        </p>
      </button>
    </nav>
  );
}
