import GroTrackLogo from "../assets/images/GROTrackTurquoise.png"
import GroSalonLogo from "../assets/images/GROSalon.png"
import DotTorqoise from "../assets/images/DotTurquoise.svg"
import DotOrange from "../assets/images/DotOrange.png"
import ChatBotLogo from "../assets/images/chatgpt-logo.png"
import ChatGptLogoBlack from "../assets/images/chatgpt-logo-black.png"
import GROTrackTurquoiseSmall from "../assets/images/GROTrackTurquoiseSmall.png";


export const getLogo = (isSalopApp, returnSmallerLogo = false) => {
    if(isSalopApp) return GroSalonLogo;
    if(returnSmallerLogo) return GROTrackTurquoiseSmall;
    return GroTrackLogo;
}

export const getDot = (isSalonApp) => {
    if(isSalonApp) return DotOrange;
    return DotTorqoise;
}

export const getChatBotLogo = (type) => {
    if(type === 'black') return ChatGptLogoBlack;
    return ChatBotLogo;
}

