import moment from "moment";
import { React, useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Loading from "../views/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUserContext } from "../contexts/userContext";
import { getLogo } from "../utlis/themeHelpers";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

export default function PatientNew() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [staffId, setStaffId] = useState('')
  const { isSalonApp, staff, isAdmin, staffId: loggedInStaffId } = useUserContext();
  const { translate } = useTranslation();

  useEffect(() => {
    if (dob) {
      setAge(moment().diff(moment(dob, "DD MMM YYYY"), "years"));
    }
  }, [dob]);

  const isPatientValid = () => {
    return true;
  };

  const registerPatient = () => {
    if (isPatientValid()) {
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/patient/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          gender: gender,
          dob: moment(dob).format("YYYY-MM-DD"),
          age: age,
          phone: phone.trim(),
          email: email.trim().toLowerCase(),
          staff_id: isAdmin ? parseInt(staffId) : loggedInStaffId
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setRedirect(response.patientID);
          } else {
            setMessage(response.message);
            setReady(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  if (redirect) {
    return <Redirect to={`/patient/account/profile/${redirect}`} />;
  }

  return (
    <>
      <main
        className={`container mx-auto flex flex-col p-4 text-text-2 ${ready ? "" : "hidden"
          }`}
      >
        <AdminHeader physician={{}} />

        <h1 className="mt-16 text-center text-xl">
           {isSalonApp ? translate('create_new_client') : translate('create_new_patient')}
        </h1>
        {message && <p className="mt-4 text-center text-red-600">{message}</p>}
        <form
          className="mx-auto mt-16 flex flex-col items-end"
          onSubmit={(event) => {
            event.preventDefault();
            registerPatient();
          }}
        >
          <label className="flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">{translate('first_name')}</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={firstName}
              required
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </label>
          <label className="flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">{translate('last_name')}</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={lastName}
              required
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </label>
          <div className="ml-6 self-start flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">{translate('gender')}</p>
            <div className="ml-6 flex flex-col md:flex-row">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="gender"
                  required
                  value="M"
                  style={{ accentColor: isSalonApp ? "#F4831F" : "1e9ab9" }}
                  checked={gender === "M"}
                  onChange={() => {
                    setGender("M");
                  }}
                />
                <p className="ml-4">{translate('male')}</p>
              </label>
              <label className="md:ml-20 flex items-center">
                <input
                  type="radio"
                  name="gender"
                  required
                  value="F"
                  style={{ accentColor: isSalonApp ? "#F4831F" : "1e9ab9" }}
                  checked={gender === "F"}
                  onChange={() => {
                    setGender("F");
                  }}
                />
                <p className="ml-4">{translate('female')}</p>
              </label>
            </div>
          </div>
          <div className="mt-2 flex flex-col items-end md:flex-row">
            <label className="flex items-center">
              <p className="ml-2">{translate('dob')}</p>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                className="ml-6 w-40 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                selected={dob}
                onChange={(date) => setDOB(date)}
              />
            </label>
            <label className="mt-4 md:mt-0 md:ml-5 flex items-center">
              <p className="ml-5">{translate('age')}</p>
              <input
                type="text"
                className="ml-6 w-40 md:w-32 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
                value={age}
                disabled
              // onChange={(event) => {
              //   setAge(event.target.value);
              // }}
              />
            </label>
          </div>
          <label className="mt-2 flex items-center">
            <strong className="mt-2 text-3xl">*</strong>
            <p className="ml-2">{translate('phone')}</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={phone}
              required
              onChange={(event) => {
                setPhone(event.target.value);
              }}
            />
          </label>
          <label className="mt-2 flex items-center">
            <p className="ml-2">{translate('email')}</p>
            <input
              type="text"
              className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-2 text-text-2 text-center rounded-sm focus:outline-none"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </label>
          {
            isAdmin && (
              <label className="mt-2 flex items-center">
                <strong className="mt-2 text-3xl">*</strong>
                <p className="ml-2">{translate('staff')}</p>
                <select required id="staff" value={staffId} onChange={(e) => setStaffId(e.target.value)} class="ml-6 text-base rounded-sm block w-40 md:w-96 py-2 px-2 bg-background-2 border-gray-600 placeholder-gray-400 text-text-2 focus:outline-none focus:border-blue-500">
                  <option selected value="">{translate('choose_a_staff_member')}</option>
                  {staff?.map((staff) => (
                    <option value={staff?.id} key={staff?.id}>{staff?.name}</option>
                  ))}
                </select>
              </label>
            )
          }
          <input
            className="mt-8 px-8 py-1 bg-background-23 rounded-lg text-text-3 cursor-pointer uppercase focus:outline-none"
            type="submit"
            value={translate('create')}
          />
        </form>
      </main>
      <Loading
        message={`${isSalonApp ? translate('registering_clinic') : translate('registering_patient')}`}
        ready={ready}
      />
    </>
  );
}
