import React from 'react'
import DotGrey from "../../assets/images/DotGrey.svg";
import { useTranslation } from '../../contexts/TranslationContext';

const BankForm = ({saveBank, editBankMode, editBank, setEditBank, bank, setEditBankMode}) => {
  const { translate } = useTranslation();
  return (
    <form
                className="mt-14 flex flex-col"
                onSubmit={(event) => {
                  event.preventDefault();
                  saveBank();
                }}
              >
                <div className=" pl-10 flex justify-center items-center">
                  <p className="text-lg text-text-2 uppercase tracking-widest">{translate('payment_method')}</p>
                </div>
                <div className="mx-auto mt-5 flex flex-col items-end text-text-2 text-sm">
                      <label className="mt-5 flex items-center">
                        <p className="ml-2 mr-3">{translate('account_holder_name')}</p>
                        <input
                          type="text"
                          className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
                          value={
                            editBankMode ? editBank.cardNumber : bank.cardNumber
                          }
                          disabled={!editBankMode}
                          onChange={(event) => {
                            setEditBank({
                              ...editBank,
                              cardNumber: event.target.value,
                            });
                          }}
                        />
                      </label>
                      <div className="mt-5 flex flex-col items-end md:flex-row">
                        <label className="flex items-center">
                          <p className="ml-2 mr-3">{translate('routing_number')}</p>
                          <input
                            type="text"
                            className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
                            value={editBankMode ? editBank.expires : bank.expires}
                            disabled
                          />
                        </label>
                      </div>
                      <div className="mt-5 flex flex-col items-end md:flex-row">
                        <label className="flex items-center">
                          <p className="ml-2 mr-3">{translate('account_number')}</p>
                          <input
                            type="text"
                            className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
                            value={editBankMode ? editBank.expires : bank.expires}
                            disabled
                          />
                        </label>
                      </div>
                      <div className="mt-5 flex flex-col items-end md:flex-row">
                        <label className="flex items-center">
                          <p className="ml-2 mr-3">{translate('account_number')}</p>
                          <input
                            type="text"
                            className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
                            value={editBankMode ? editBank.expires : bank.expires}
                            disabled
                          />
                        </label>
                      </div>
                    <div className="flex mt-5 pl-14 justify-center w-full items-center">
                    <img src={DotGrey} className="h-3 w-3 mr-3"/>
                    <p className="text-text-2">{translate('same_as_practice_address')}</p>
                    </div>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('billing_address')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                      value={
                        editBankMode
                          ? editBank.billingAddress.street
                          : bank.billingAddress.street
                      }
                      disabled={!editBankMode}
                      placeholder={translate('street')}
                      required
                      onChange={(event) => {
                        setEditBank({
                          ...editBank,
                          billingAddress: {
                            ...editBank.billingAddress,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-3 py-1  placeholder-text-2 bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                    value={
                      editBankMode
                        ? editBank.billingAddress.city
                        : bank.billingAddress.city
                    }
                    disabled={!editBankMode}
                    placeholder={translate('city')}
                    required
                    onChange={(event) => {
                      setEditBank({
                        ...editBank,
                        billingAddress: {
                          ...editBank.billingAddress,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-5 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-3 py-1 font-bold placeholder-text-2  bg-background-22 text-text-2 rounded-xs focus:outline-none"
                        value={
                          editBankMode
                            ? editBank.billingAddress.state
                            : bank.billingAddress.state
                        }
                        disabled={!editBankMode}
                        placeholder={translate('state')}
                        required
                        onChange={(event) => {
                          setEditBank({
                            ...editBank,
                            billingAddress: {
                              ...editBank.billingAddress,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-3 placeholder-text-2 py-1 bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                        value={
                          editBankMode
                            ? editBank.billingAddress.zip
                            : bank.billingAddress.zip
                        }
                        disabled={!editBankMode}
                        placeholder={translate('zip')}
                        required
                        onChange={(event) => {
                          setEditBank({
                            ...editBank,
                            billingAddress: {
                              ...editBank.billingAddress,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('phone')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 placeholder-text-2 px-3 py-1 bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                      value={editBankMode ? editBank.phone : bank.phone}
                      disabled={!editBankMode}
                      required
                      onChange={(event) => {
                        setEditBank({
                          ...editBank,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('email')}</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2 bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                      value={editBankMode ? editBank.email : bank.email}
                      disabled={!editBankMode}
                      required
                      onChange={(event) => {
                        setEditBank({
                          ...editBank,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <div className="w-full flex justify-center mt-8">
                    
                   {editBankMode && (<button
                    type="submit"
                    className="px-8 py-1 mx-2 bg-button-5 rounded-lg text-text-3 font-bold text-sm
              cursor-pointer focus:outline-none"
                  >
                    {translate('save')}
                  </button>)}
                  <button
                    type="button"
                    className="px-8 mx-2 py-1 bg-button-5 rounded-lg text-text-3 font-bold text-sm
              cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditBankMode(!editBankMode);
                    }}
                  >
                    {editBankMode ? translate('cancel') : translate('update')}
                  </button>
                  </div>
                </div>
              </form>
  )
}

export default BankForm